import { MuiThemeProvider } from '@material-ui/core/styles'
import DarkTheme from '../../../utility/muiTableDark'

import React, { useState, useEffect, useCallback, Fragment, useRef } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Card, ListGroup, Table, Form, FormControl, Button, Modal, OverlayTrigger, PopoverTitle, PopoverContent, FormCheck, FormLabel, InputGroup } from 'react-bootstrap'
import { makeStyles } from '@material-ui/styles'
import { handleErrors } from '../../../api/base'
import { formatNegative, zeroIfNull } from '../../../utility/numberFormat'
import { Popover, FormGroup } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import MUIDataTable from 'mui-datatables'
import CreateIcon from '@material-ui/icons/Create'
import { setUserCanManageAPI, getReferralCodeAPI, setUserManagerAPI } from '../../../api/user/discordmanager'
import { utcStringToLocal, utcStringToLocalDate } from '../../../utility/timeconvert'
// import DatePicker from '../datepicker/datepicker'
import  DatePicker  from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {copyTextToClipboard} from '../../../utility/commands'
import { getOwnerManagerPayments, getOwnerManagers, getOwnerManagersAffiliates } from '../../../api/user/discordmanager'
import { PieChart } from 'react-minimal-pie-chart'



const useStyles = makeStyles({
    fullCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    leftFlex: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center'
    }
})

const FREQ = {
    Daily: 0,
    Weekly: 1,
    Monthly: 2,
    Quarterly: 3,
    SemiAnnualy: 4,
    Yearly: 5,
    LifeTime: 6
}

const GetFreqString = (freqNum) => {
    switch (Number(freqNum)){
        case FREQ.Daily:
            return 'Day'
        case FREQ.Weekly:
            return 'Week'
        case FREQ.Monthly:
            return 'Month'
        case FREQ.Quarterly:
            return '3 Months'
        case FREQ.SemiAnnualy:
            return '6 Months'
        case FREQ.Yearly:
            return 'Year'
        case FREQ.LifeTime:
            return 'LifeTime'
        default:
            return ''
    }
}


const getMonthRange = (date) => {
    date = new Date(date)
    return {
        firstDay: new Date(date.getFullYear(), date.getMonth(), 1),
        lastDay: new Date(date.getFullYear(), date.getMonth() + 1, 0),
        maxLast: new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999)
    }
}

export const ManagerPayments = ({props, notify}) => {
    const dmState = useSelector(state => state.discordManager)
    const user = useSelector(state => state.user)

    const [graphData, setGraphData] = useState([])
    const [chartOneData, setChartOneData] = useState([])
    const [chartTwoData, setChartTwoData] = useState([])
    const [hovered, setHovered] = useState(null)
    const [managerID, setManagerID] = useState('')
    const [managers, setManagers] = useState([])
    const [tableData, setTableData] = useState([])

    let startDate = new Date()
    startDate.setMonth(startDate.getMonth() - 1)
    let lastMonth = getMonthRange(startDate)

    const [start, setStart] = useState(lastMonth.firstDay)
    const [end, setEnd] = useState(lastMonth.lastDay)

    useEffect(() => {
        loadManagers()
    }, [])

    const loadManagers = () => {
        if (!user || !user.IdToken){
            return
        }
        return getOwnerManagersAffiliates(user.UserID, user.IdToken, user.AccessToken)
            .then(handleErrors)
            .then(results => {
                if (!results){
                    notify('Error', 'Error Loading Payments')
                    return
                }
                setManagers(results)
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
            })
    }

    const loadPayments = () => {
        if (!user || !user.IdToken || !managerID){
            return
        }
        return getOwnerManagerPayments(user.UserID, user.IdToken, user.AccessToken, managerID)
            .then(handleErrors)
            .then(results => {
                if (!results){
                    notify('Error', 'Error Loading Payments')
                    return
                }
                //Load Graph
                setGraphData(results)
                loadCharts(results)
                console.log(results)
                filterCommissionTable(results)
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
            })
    }

    const loadCharts = (data) => {
        // Chart 1 : Group By Level This Month
        let counter = 0
        let oneData = []
        let range = getMonthRange(new Date())
        console.log('This Month', range)
        data.forEach(x => {
            const thisDay = new Date(x.TimeStamp)
            if (thisDay < range.firstDay || thisDay > range.maxLast){
                return
            }
            let existing = oneData.filter(y => y.PlanName === x.PlanName)[0]
            if (existing){
                existing.value += Number(x.Amount)
                existing.value = Number(existing.value.toFixed(4))
                existing.count++
            }
            else{
                oneData.push({
                    PlanName: x.PlanName,
                    value: x.Amount,
                    color: x.Color,
                    count: 1
                })
            }
        })
        counter = 0
        console.log(oneData)

        setChartOneData(oneData)

        // Chart 2 : Group By Level Last Month
        let twoData = []
        range.firstDay.setDate(range.firstDay.getDate() - 1)
        range = getMonthRange(range.firstDay)
        console.log('Last Month', range)
        data.forEach(x => {
            const thisDay = new Date(x.TimeStamp)
            if (thisDay < range.firstDay || thisDay > range.maxLast){
                return
            }
            let existing = twoData.filter(y => y.PlanName === x.PlanName)[0]
            if (existing){
                existing.value += Number(x.Amount)
                existing.value = Number(existing.value.toFixed(4))
                existing.count++
            }
            else{
                twoData.push({
                    PlanName: x.PlanName,
                    value: x.Amount,
                    color: x.Color,
                    count: 1
                })
            }
        })

        setChartTwoData(twoData)
    }

    const filterCommissionTable = (raw, s, e) => {
        if (!s) s = start
        if (!e) e = end
        e = new Date(e.getFullYear(), e.getMonth(), e.getDate(), 23, 59, 59, 999)
        let filtered = raw.filter(x => {
            const payDate = new Date(x.TimeStamp)
            return payDate >= s && payDate <= e
        })
        setTableData(filtered)
    }


    const columns = [
        {name: 'Amount', label: 'Amount',
            options: {                
                customBodyRender: (v) => `$${v}`,
                filter: false
            }
        },
        {name: 'Frequency', label: 'Frequency'},
        {name: 'UserName', label: 'Username',
            options: {
                filter: false
            }
        },
        {name: 'PlanName', label: 'Plan'},
        {name: 'PaymentType', label: 'Type'},
        {name: 'CardHolder', label: 'Card Holder',
            options: {
                filter: false
            }
        },
        {name: 'originalAmount', label: 'OriginalAmount',
            options: {
                customBodyRender: (v) => `$${v}`,
                filter: false
            }
        },
        {name: 'TimeStamp', label: 'Timestamp',
            options: {
                filter: false
            }
        }
    ]

    
    const tableOpts = {
        selectableRows: 'none',
        rowsPerPage: 25,
        rowsPerPageOptions: [10, 25, 50, 100],
        // filterType: 'multiselect',
        // onRowClick: handleRowClick
    }

    return (
        <Card bg="dark">
            <Card.Header style={{color: 'white'}}>
                <b>Payments</b>
            </Card.Header>
            <Card.Body style={{color: 'white'}}>
                <Row>
                    <Col sm={6}>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2} style={{textAlign: 'right'}}>User</Form.Label>
                            <Col>
                                <Form.Control as="select" onChange={(e) => setManagerID(e.target.value)} value={managerID}>
                                    <option value="">-- Select Manager --</option>
                                    {managers.map(x => {
                                        return (
                                            <option value={x.ID}>{x.Name}</option>
                                        )
                                    })}
                                </Form.Control>
                            </Col>
                            <Col>
                                <Button variant="secondary" onClick={loadPayments}>Load</Button>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Row>
                            <span style={{marginRight: '30px', marginLeft: '30px', paddingTop: '5px'}}>Commission Table Filter</span>
                            <DatePicker
                                selected={start}
                                onChange={(date) => { setStart(date); filterCommissionTable(graphData, date) }}
                                dateFormat="MMMM d, yyyy"
                            />
                            <span style={{marginRight: '30px', marginLeft: '30px', paddingTop: '5px'}}>To</span>
                            <DatePicker
                                selected={end}
                                onChange={(date) => { setEnd(date); filterCommissionTable(graphData, null, date)}}
                                dateFormat="MMMM d, yyyy"
                            />
                        </Row>
                    </Col>
                </Row>
                <Row>
                <Col lg={3} sm={6} md={4}>
                    <div>
                    <h2 style={{textAlign: 'center'}}>This Month Commission By Tier</h2>
                    <PieChart 
                        data={chartOneData}
                        label={() => {
                            let total = 0
                            chartOneData.forEach(x => {total += x.value})
                            return `$${total.toFixed(2)}`
                        }}
                        labelStyle={{
                        fontSize: '16px',
                        fontFamily: 'sans-serif',
                        fill: '#FFFFFF',
                        }}
                        labelPosition={0}
                        paddingAngle={5}
                        lineWidth={30}
                    />
                    </div>
                </Col>
                <Col lg={3} sm={6} md={4}>
                    <div>
                    <h2 style={{textAlign: 'center'}}>Last Month Commission By Tier</h2>
                    <PieChart 
                        data={chartTwoData}
                        label={() => {
                            let total = 0
                            chartTwoData.forEach(x => {total += x.value})
                            return `$${total.toFixed(2)}`
                        }}
                        labelStyle={{
                        fontSize: '16px',
                        fontFamily: 'sans-serif',
                        fill: '#FFFFFF',
                        }}
                        labelPosition={0}
                        paddingAngle={5}
                        lineWidth={30}
                    />
                    </div>
                </Col>                
                <Col sm={12}>
                    <MuiThemeProvider theme={DarkTheme()}>
                        <MUIDataTable
                            title="Commission"
                            columns={columns}
                            data={tableData}
                            options={tableOpts}
                        />
                    </MuiThemeProvider>
                    {/* <Table>
                        <thead>
                            <tr>
                                <th>Frequency</th>
                                <th>Amount</th>
                                <th>UserName</th>
                                <th>Plan</th>
                                <th>Timestamp</th>
                                <th>Original Amount</th>
                                <th>Type</th>
                                <th>Card Holder</th>
                            </tr>
                        </thead>
                        <tbody>
                            {graphData.map(x => {
                                return <tr>
                                    <td>{GetFreqString(x.Frequency)}</td>
                                    <td>{x.Amount}</td>
                                    <td>{x.UserName}</td>
                                    <td>{x.PlanName}</td>
                                    <td>{x.TimeStamp}</td>
                                    <td>{x.originalAmount}</td>
                                    <td>{x.PaymentType ? 'Recurring' : 'New'}</td>
                                    <td>{x.CardHolder}</td>
                                </tr>
                            })}
                        </tbody>
                    </Table> */}
                </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}