import React, { useState, useEffect, useCallback, Fragment, useRef } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { handleErrors } from '../../../api/base'
import { Container, Row, Col, Card, ListGroup, Table, Form, FormControl, Button, Modal, OverlayTrigger, PopoverTitle, PopoverContent } from 'react-bootstrap'


import { getCSSAPI, updateCSSAPI } from '../../../api/user/discordmanager'

export const OwnerCSS = ({props, notify}) => {

    const user = useSelector(state => state.user)

    const [content, setContent] = useState('')

    useEffect(() => {
        loadCSS()
    }, [])

    const loadCSS = () => {
        getCSSAPI(user.UserID, user.IdToken, user.AccessToken)
            .then(handleErrors)
            .then(results => {
                setContent(results)
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
            })
    }

    const saveCSS = () => {
        let notes = notify('Saving', 'Saving CSS...')
        updateCSSAPI(user.UserID, user.IdToken, user.AccessToken, content)
            .then(handleErrors)
            .then(results => {
                notify('Saved', 'CSS has been saved!', notes)
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString(), notes)
                }
            })
    }

    return (
        <Card bg="dark" style={{color: 'white'}}>
            <Card.Header style={{display: 'flex', justifyContent: 'space-between'}}>
                <b>Custom CSS</b>
                <div>
                    <Button variant="secondary" style={{marginRight: '5px'}} onClick={loadCSS}>Reload</Button>
                    <Button variant="secondary" style={{marginRight: '5px'}} onClick={saveCSS}>Save</Button>
                </div>
            </Card.Header>
            <Card.Body style={{color: 'white'}}>
                <Form.Control as="textarea" value={content} onChange={(e) => setContent(e.target.value)} style={{height: '75vh'}} />
            </Card.Body>
        </Card>
    )
}