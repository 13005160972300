import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { Container, Row, Col, Card, ListGroup, Table, Form, FormControl, Button, Modal, OverlayTrigger, PopoverTitle, PopoverContent } from 'react-bootstrap'



export const TradeViewGraph = ({symbol, ...props}) => {
    useEffect(() => {
        setTimeout(() => {
            loadChart()
        }, 2000)      
    }, [])

    const loadChart = () => {
        eval(`var tradeChart = new TradingView.widget(
            {
            "width": '100%',
            "height": 400,
            "symbol": "${symbol}",
            "interval": "1",
            "timezone": "America/New_York",
            "theme": "dark",
            "style": "1",
            "locale": "en",
            "toolbar_bg": "#f1f3f6",
            "enable_publishing": false,
            "hide_legend": false,
            "container_id": "tradingview_637c2"
            }
        );`)
    }

    const handleRefresh = () => {
        let holder = document.getElementById(`tradingview_637c2`)
        while (holder.firstChild){
            holder.removeChild(holder.firstChild)
        }
        loadChart()
    }

    return (
        <Card bg="dark">
            <Card.Header style={{color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <b>Chart (Delayed 15 minutes)</b>
                <Button onClick={handleRefresh} variant="secondary">Refresh</Button>
            </Card.Header>
            <Card.Body style={{padding: '0px'}}>
            <div id={`tradingview_637c2`}></div>
            {/* <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/symbols/NASDAQ-AAPL/" rel="noopener" target="_blank"><span class="blue-text">AAPL Chart</span></a> by TradingView</div> */}
            </Card.Body>
        </Card>
    )
}