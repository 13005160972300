export const TDTOKEN = 'TDTOKEN'
export const TDCLEAR = 'TDCLEAR'

export const TDSYMBOL = 'TDSYMBOL'
export const TDSTOCKTOGGLE = 'TDSTOCKTOGGLE'
export const TDEXPIRATIONS = 'TDEXPIRATIONS'
export const TDSTRIKES = "TDSTRIKES"
export const TDPRICECOLOR = 'TDPRICECOLOR'
export const TDOPTIONSIDE = 'TDOPTIONSIDE'


export function setTokenAction(token){
    token.tokenTime = new Date();
    if (token.refresh_token === null){
        delete token.refresh_token
        delete token.refresh_token_expires_in
    }
    return {
        type: TDTOKEN,
        data: token
    }
}

export function tdClearDataAction(){
    return {
        type: TDCLEAR
    }
}


export function setTDSymbolAction(symbol){
    return {
        type: TDSYMBOL,
        data: symbol
    }
}

export function setTDStockToggleAction(toggle){
    return {
        type: TDSTOCKTOGGLE,
        data: toggle
    }
}

export function setTDExpirationsAction(exps){
    return {
        type: TDEXPIRATIONS,
        data: exps
    }
}


export function setTDStrikesAction(stks){
    return {
        type: TDSTRIKES,
        data: stks
    }
}

export function setTDPriceColorAction(color){
    return {
        type: TDPRICECOLOR,
        data: color
    }
}

export function setTDOptionSideAction(color){
    return {
        type: TDOPTIONSIDE,
        data: color
    }
}