import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { handleErrors } from '../../../api/base'
import { Container, Row, Col, Card, ListGroup, Table, Form, Button,
     Modal, OverlayTrigger, PopoverTitle, PopoverContent, FormCheck, FormLabel,
      InputGroup, ProgressBar } from 'react-bootstrap'
import { Toasty } from '../../trading/toast'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { getAccountsToSMSAPI, sendSMSAPI } from '../../../api/user/discordusermanager'
import { getAllDMSettingsAPI } from '../../../api/user/discordmanager'
import { refreshAPI } from '../../../api/user/auth'
import { Redirect } from 'react-router-dom'
import{ validateSignIn, signOutAction } from '../../../actions/user'
import { loadDMSettingsAction } from '../../../actions/discordmanager'
import { FormControl, InputLabel, Select, ThemeProvider, MenuItem } from '@material-ui/core'
import DarkTheme from '../../../utility/muiTableDark'
import {utcStringToLocal} from '../../../utility/timeconvert'


export const AuditRecord = ({records}) => {
    
    const isBig = useMediaQuery('(min-width:600px)')

    // Redux    
    const user = useSelector(state => state.user)
    const dmState = useSelector(state => state.discordManager)
    const dispatch = useDispatch()

    // Local State
    const [recordId, setRecordId] = useState(null)
    const [recordData, setRecordData] = useState(null)
    const [prevRecord, setPrevRecord] = useState(null)

    const setData = (x, i) => {
        setRecordId(x.RecordId)
        setRecordData(JSON.parse(x.Record))
        
        const prev = records[i + 1]
        setPrevRecord((prev && JSON.parse(prev.Record)) || null)
    }

    return (
        <Fragment>
            {recordId && <Button onClick={() => {setRecordId(null);setRecordData(null);setPrevRecord(null)}}>Back</Button>}
            <Table>
                {!recordId && (
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>User</th>
                            <th>User Type</th>
                        </tr>
                    </thead>
                )}
                {recordId && (
                    <thead>
                        <tr>
                            <th>Field</th>
                            <th>Previous Value</th>
                            <th>Current Value</th>
                        </tr>
                    </thead>
                )}
                <tbody>
                {!recordId && records && records.map((x, i) => {
                    return (
                        <tr onClick={() => {setData(x, i)}}>
                            <td>{utcStringToLocal(x.Timestamp, 'datetime')}</td>
                            <td>{x.UserId || 'System'}</td>
                            <td>{x.UserType}</td>
                        </tr>
                    )
                })}
                {recordId && recordData && recordData.map((x, i) => {
                    const matchField = prevRecord && prevRecord[i]
                    const changed = !prevRecord || matchField.CurrentValue !== x.CurrentValue
                    
                    return (
                        <tr style={{backgroundColor: changed ? 'lightblue' : 'white'}}>
                            <td>{x.Name}</td>
                            <td>{(matchField && matchField.CurrentValue) || ''}</td>
                            <td>{x.CurrentValue}</td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        </Fragment>
    )
}