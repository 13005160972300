import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Card, ListGroup, Table, Form, FormControl, Button, Modal, OverlayTrigger, PopoverTitle, PopoverContent } from 'react-bootstrap'
import { makeStyles } from '@material-ui/styles'
import { handleErrors } from '../../api/base'
import { formatNegative, zeroIfNull } from '../../utility/numberFormat'
import OrderOptions from '@material-ui/icons/MenuOpenRounded'
import { Popover, FormGroup } from '@material-ui/core'
import { Redirect } from 'react-router-dom'

import { getAllDMSettingsAPI, setAllDMSettingsAPI, getAllDMPlanUsersAPI,
     UpdateDMPlanUserAPI, getDMGuildRolesAPI, getDMChannelsAPI } from '../../api/user/discordmanager'
import { loadDMSettingsAction } from '../../actions/discordmanager'
import { Toasty } from '../trading/toast'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DeleteIcon from '@material-ui/icons/Delete'
import SearchIcon from '@material-ui/icons/Search'
import { getDiscordServers } from '../../api/user/discord'
import {DiscordManagerUsers} from './discordManagerUsers'
import {DiscordManagerUsersLite} from './discordmanager/userTableLite'

import { getAccountsToManageAPI, getPlansAPI, getAccountUsersLiteAPI } from '../../api/user/discordusermanager'
import { refreshAPI } from '../../api/user/auth'
import { validateSignIn, signOutAction } from '../../actions/user'
import { ManagerDashboard } from './discordmanager/managerDashboard'

export const DiscordUserManager = () => {
    const isBig = useMediaQuery('(min-width:600px)');

    // Redux
    const user = useSelector(state => state.user)
    const dmState = useSelector(state => state.discordManager)
    const dispatch = useDispatch()


    // Local State
    const [toasts, setToasts] = useState([])
    const [planUsers, setPlanUsers] = useState([])
    const [accountsCanManage, setAccountsCanManage] = useState([])
    const [includeTrials, setIncludeTrials] = useState(false)
    const [hideTrials, setHideTrials] = useState(false)
    const [dashboard, setDashboard] = useState(false)


    useEffect(() => {
        // Which accounts Can I manage?
        if (!user || !user.UserID){
            return
        }

        getAccountsToManageAPI(user.UserID, user.IdToken, user.AccessToken)
            .then(handleErrors)
            .then(results => {
                if (!results){
                    notify('Error', 'Error Getting Accounts To Manage')
                    return
                }
                setAccountsCanManage(results)
                if (results.length === 1){
                    handleAccountSelect(results[0].OwnerID)
                    return
                }
                const selectedOwnerID = sessionStorage.getItem('selectedOwner')
                if (selectedOwnerID){
                    handleAccountSelect(selectedOwnerID)
                }
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    if (msg.message === 'Failed to fetch'){
                        dispatch(signOutAction())
                    }
                    notify('Error', msg.toString())
                }
            })

        // const interval = setInterval(() => {
        //     const selectedOwner = sessionStorage.getItem('selectedOwner')
        //     handleAccountSelect(selectedOwner)
        // }, 30000)

        // return () => {clearInterval(interval); sessionStorage.removeItem('selectedOwner')}
        

    }, [(user && user.IdToken)])

    const loadUsers = (include, hide) => {
        const selectedOwner = sessionStorage.getItem('selectedOwner')
        handleAccountSelect(selectedOwner, include, hide)
    }
    

    const notify = (title, msg) => {
        let tsts = toasts.map(x => x)
        tsts.push({
            Title: title,
            Message: msg
        })
        setToasts(tsts)
    }

    const handleAccountSelect = (ownerID, ovInclude, ovHide) => {
        if (!ownerID){
            return
        }
        setDashboard(false)
        sessionStorage.setItem('selectedOwner', ownerID)
        return getPlansAPI(user.UserID, user.IdToken, user.AccessToken, ownerID)
            .then(handleErrors)
            .then(results => {
                if (results == null){
                    notify('Error', 'Error loading account plans')
                    return
                }
                const data = {DiscordStripePlans: results}
                
                const settings = {
                    ExpiredTrials: dmState.IncludeTrial,
                    HideTrials: dmState.HideTrial,
                }
                if (ovInclude !== undefined){
                    settings.ExpiredTrials = ovInclude
                }
                if (ovHide !== undefined){
                    settings.HideTrials = ovHide
                }

                getAccountUsersLiteAPI(user.UserID, user.IdToken, user.AccessToken, ownerID, settings)
                    .then(handleErrors)
                    .then(userResults => {
                        if (!userResults){
                            notify('Error loading users')
                            return
                        }
                        setPlanUsers(userResults)
                    })
                    .catch(error => error)
                    .then(msg => {
                        if (msg){
                            if (msg.message === 'Failed to fetch'){
                                dispatch(signOutAction())
                            }
                            notify('Error', msg.toString())
                        }
                        dispatch(loadDMSettingsAction(data))
                    })

            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    if (msg === 'Refresh'){
                        refreshAPI(user.RefreshToken)
                            .then(handleErrors)
                            .then(result => {
                                dispatch(validateSignIn(result))
                            })
                            .catch(error => error)
                            .then(msg => {
                                if (msg && msg.message === '404'){
                                    dispatch(signOutAction())
                                }
                            })
                    }
                    else{
                        notify('Error', msg.toString())
                    }
                }
            })
    }


   

    if (!user.CanAccessOtherManagers){
        return <Redirect to="/" />
    }
    return (
        <Container fluid>
            <div style={{marginBottom: '5px'}}>
                {accountsCanManage && accountsCanManage.map(x => {
                    return (
                        <Button style={{marginRight: '5px', marginBottom: '5px'}} variant="dark" onClick={() => {handleAccountSelect(x.OwnerID)}}>
                            {x.Name}
                        </Button>
                    )
                })}
                <Button style={{marginRight: '5px', marginBottom: '5px'}} variant="dark" onClick={() => setDashboard(true)}>
                    Dashboard
                </Button>
            </div>
            {!dashboard && <DiscordManagerUsersLite users={planUsers} notify={notify} props={{ setIncludeTrials, includeTrials, hideTrials, setHideTrials }} refresh={loadUsers} />}
            {dashboard && <ManagerDashboard notify={notify} />}
            <div style={{position: 'fixed', top: '20px', right: '20px'}}>
                {toasts.map(x => {
                    return <Toasty title={x.Title} message={x.Message} />
                })}
            </div>
        </Container>
    )
}
