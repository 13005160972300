export const SIGN_IN = 'SIGN_IN'
export const SIGN_OUT = 'SIGN_OUT'

export function validateSignIn(tokens){
  tokens.tokenTime = new Date();
  if (tokens.RefreshToken === null){
    tokens = {
      AccessToken: tokens.AccessToken,
      IdToken: tokens.IdToken,
      UserID: tokens.UserID,
      Flags: tokens.Flags
    }
  }
  return {
    type: SIGN_IN,
    userInfo: tokens
  }
}

export function signOutAction(){
  return {
    type: SIGN_OUT,
    user: null
  }
}

