import React, { useEffect } from 'react'
import scheduleDemoIMG from '../../images/FeaturesLG/schedule_demo.png'
import Helmet from 'react-helmet'
import fullLogo from '../../images/FullLogo.png'

export const Pricing = () => {
    return (
        <div>
            <Helmet>
                <meta name="description" content="Varbis Discord Subscription Manager Pricing" />
                <title>Pricing - Varbis.NET</title>
            </Helmet>
            <div style={{width: '100%', height: '55px', 
                        backgroundSize: 'contain', backgroundImage: `url(${fullLogo})`,
                        backgroundRepeat: 'no-repeat', backgroundPositionX: 'center',
                        marginBottom: '3vh'
                    }}>
            </div>
            <div style={{width: '100vw', display: 'flex', justifyContent: 'center'}}>
                <img src={scheduleDemoIMG} alt="schedule demo" style={{width: '100vw'}} />
            </div>
        </div>
    )
}