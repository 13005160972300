import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Card, ListGroup, Table, Form, FormControl, Button, Modal, OverlayTrigger, PopoverTitle, PopoverContent } from 'react-bootstrap'
import { makeStyles } from '@material-ui/styles'
import { handleErrors } from '../../../api/base'
import { refreshAPI } from '../../../api/user/auth'
import { validateSignIn, signOutAction } from '../../../actions/user'
import { getAccountInfo, getOrders, getStrikes, getExpirations, getQuote, previewOrderAPI, placeOrderAPI } from '../../../api/trading/ally'
import { formatNegative, zeroIfNull } from '../../../utility/numberFormat'
import { Toasty } from '../toast'
import OrderOptions from '@material-ui/icons/MenuOpenRounded'
import { Popover, FormGroup, CardHeader } from '@material-ui/core'
import MUIDataTable, { TableHead } from 'mui-datatables'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import DarkTheme from '../../../utility/muiTableDark'



const CustomHead = (props) => {
    return <TableHead id="myCustomID" {...props} ItemComponent={CustomHeader} />
}

const CustomHeader = (props) => {
    console.log(props)
    return <CardHeader>NEW ORDER</CardHeader>
}

export const Orders = ({orders, toasts, setToasts, loadInfo}) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [activeOrder, setActiveOrder] = useState({})
    const [modifyMode, setModifyMode] = useState(false)
    const [newLimit, setNewLimit] = useState(null)
    const [newStop, setNewStop] = useState(null)
    const user = useSelector(state => state.user)
    if (!orders){
        return ''
    }


    const handleClick = (event, fullOrder) => {
        setAnchorEl(event.currentTarget);
        setActiveOrder(fullOrder)
    };

    const handleClose = () => {
        setAnchorEl(null);
        resetModify()
    };

    const resetModify = () => {
        setModifyMode(false)
        setNewLimit(null)
        setNewStop(null)
    }

    const handleCancel = () => {
        let thisOrder = activeOrder
        thisOrder.OrderAction = 2
        if (thisOrder.Expiration){
            thisOrder.Expiration = new Date(thisOrder.Expiration)
        }
        placeOrderAPI(thisOrder, user.UserID, user.IdToken, user.AccessToken)
            .then(handleErrors)
            .then(result => {
                let tsts = toasts.map(x => x)
                tsts.push(`Order ${result.OrderStatus}`)
                setToasts(tsts)
                setTimeout(loadInfo, 500)
            })
            .catch(error => error)
            .then(msg => {
                console.log(msg)
            })
        handleClose()
    }

    const handleModify = () => {
        let thisOrder = activeOrder
        thisOrder.OrderAction = 1
        if (thisOrder.Expiration){
            thisOrder.Expiration = new Date(thisOrder.Expiration)
        }
        if (newLimit){
            thisOrder.Price = Number(newLimit)
        }
        if (newStop){
            thisOrder.Stop = Number(newStop)
        }
        placeOrderAPI(thisOrder, user.UserID, user.IdToken, user.AccessToken)
            .then(handleErrors)
            .then(result => {
                let tsts = toasts.map(x => x)
                tsts.push(`Order ${result.OrderStatus}`)
                setToasts(tsts)
                setTimeout(loadInfo, 500)
            })
            .catch(error => error)
            .then(msg => {
                console.log(msg)
            })
        handleClose()
    }

    const open = Boolean(anchorEl)
    const id = open ? 'orderOptions' : undefined

    const columns = [
        { name: 'FullOrder', label: ' ',
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (tableMeta.rowData[1] === 'Open' || tableMeta.rowData[1] === 'Pending'){
                        return <OrderOptions onClick={(e) => {handleClick(e, value)}} />
                    }
                    return ''
                },
                viewColumns: false
            }
        },
        { name: 'Status', label: 'Status' },
        { name: 'Side', label: 'Side' },
        { name: 'Symbol', label: 'Symbol' },
        { name: 'Qty', label: 'Quantity' },
        { name: 'Price', label: 'Price' },
        { name: 'OrderType', label: 'Type'},
        { name: 'Desc', label: 'Description'},
        { name: 'Text', label: 'Message' }
    ]

    const tableOpts = {
        selectableRows: 'none',
        responsive: 'simple',
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 20, 100]
    }


    return (
        <Card bg="dark">
            <MuiThemeProvider theme={DarkTheme()}>
            <MUIDataTable 
                title={<b>Orders</b>}
                columns={columns}
                data={orders}
                options={tableOpts}
            />
            </MuiThemeProvider>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <PopoverTitle style={{backgroundColor: 'rgba(0,0,0,.03)', color: 'white'}}>Order Options</PopoverTitle>
                <PopoverContent>
                    {!modifyMode && (
                    <Fragment>
                        <Button style={{margin: '2px'}} variant="danger" onClick={handleCancel}>Cancel</Button>
                        <Button style={{margin: '2px'}} variant="dark" onClick={() => {setModifyMode(true)}}>Modify</Button>
                    </Fragment>
                    )}
                    {modifyMode && ( activeOrder.OrderType === 2 || activeOrder.OrderType === 4 ) && (
                        <Fragment>
                            <Form>
                                <Form.Group as={Row}>
                                    <Form.Label column style={{color: 'white'}}>New Limit</Form.Label>
                                    <Col>
                                        <Form.Control placeholder={`${activeOrder.Price}`} onChange={(e) => {setNewLimit(e.target.value)}} />
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Fragment>
                    )}
                    {modifyMode && ( activeOrder.OrderType === 3 || activeOrder.OrderType === 4 ) && (
                        <Fragment>
                            <Form>
                                <Form.Group as={Row}>
                                    <Form.Label column style={{color: 'white'}}>New Stop</Form.Label>
                                    <Col>
                                        <Form.Control placeholder={`${activeOrder.Stop}`} onChange={(e) => {setNewStop(e.target.value)}} />
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Fragment>
                    )}
                    {modifyMode && activeOrder.OrderType === 1 && ('Market Orders Cannot be Modified')}
                    {modifyMode && (newLimit || newStop) && (
                        <Button onClick={handleModify} variant="secondary">Submit</Button>
                    )}
                </PopoverContent>
            </Popover>
        </Card>
    )
}
