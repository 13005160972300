
import { TDTOKEN, TDCLEAR, TDSYMBOL, TDSTOCKTOGGLE,
     TDEXPIRATIONS, TDSTRIKES, TDPRICECOLOR,
     TDOPTIONSIDE} from '../actions/td'
import {SIGN_OUT} from '../actions/user'

export default function td(state = {}, action) {
	switch (action.type) {
        case TDTOKEN :
            return {
                ...state,
                token: {...state.token, ...action.data}
            }
        case TDSYMBOL :
            return {
                ...state,
                symbol: action.data
            }
        case TDSTOCKTOGGLE:
            return {
                ...state,
                stockToggle: action.data
            }
        case TDEXPIRATIONS:
            return {
                ...state,
                expirations: action.data
            }
        case TDSTRIKES:
            return {
                ...state,
                strikes: action.data
            }
        case TDPRICECOLOR:
            return {
                ...state,
                priceColor: action.data
            }
        case TDOPTIONSIDE:
            return{
                ...state,
                optionSide: action.data
            }
        case TDCLEAR: 
            return {}
            
        case SIGN_OUT :
            return {}
        default :
			return state
    }
}