import {LOAD_DU_DATA} from '../actions/discorduser'
import {SIGN_OUT} from '../actions/user'

export default function discordUser(state = {}, action) {
	switch (action.type) {
        case LOAD_DU_DATA :
            return {
                ...state,
                ...action.data
            }
        case SIGN_OUT :
            return {}
        default :
			return state
    }
}