import React from 'react'

export const PrivacyPolicy = () => {
    return <div>
        COLLECTED INFORMATION<br />
        1. When you give it to us <br />
        When you sign up for VARBIS you voluntarily share certain information including your name, email address, phone number, and any other information that you give us. We will still use your IP address, which is used to approximate your location, even if you dont choose to share your location with us.
        If you connect your Facebook, Google, or any other third-party accounts to Varbis, we use information from those accounts to improve your experience.
        <br />
        2. When you use Varbis<br />
        We also get technical information when you use VARBIS such as device, browser, and operating system information which are automatically logged.
        <br />
        3. Our partners share information with us<br />
        We also get information about you and your activity outside Varbis from our partners, affiliates, and other third parties we work with.
        <br /><br />
        WHAT WE DO WITH THE INFORMATION<br />
        1. Improve your experience by recognizing you when you sign in.
        <br />
        2. Sending you relevant marketing material targeting to your preferences.
        <br />
        3. Improve Varbis and offer new features
        <br />
        4. We DO NOT sell any of your data.
        <br /><br />
        Any questions please send them to support@varbis.net
    </div>
}