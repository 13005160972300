import React from 'react'
import { Card, ListGroup } from 'react-bootstrap'



export const BuyingPower = ({holdings}) => {
    if (!holdings){
        return ''
    }
    return (
        <Card bg="dark">
            <Card.Header style={{color: 'white'}}><b>Buying Power</b></Card.Header>
            <ListGroup  variant="flush">
                <ListGroup.Item variant="dark">Stock Buying Power: ${holdings.Stocks}</ListGroup.Item>
                <ListGroup.Item variant="dark">Options Buying Power: ${holdings.Options}</ListGroup.Item>
                <ListGroup.Item variant="dark">Day Trading Buying Power: ${holdings.DayTrading}</ListGroup.Item>
            </ListGroup>
        </Card>
    )
}
