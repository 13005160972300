import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Card, ListGroup, Table, Form, FormControl, Button, Modal, OverlayTrigger, PopoverTitle, PopoverContent } from 'react-bootstrap'
import { makeStyles } from '@material-ui/styles'
import { handleErrors } from '../../../api/base'
import { refreshAPI } from '../../../api/user/auth'
import { validateSignIn, signOutAction } from '../../../actions/user'
import { getAccountInfo, getOrders, getStrikes, getExpirations, getQuote, previewOrderAPI, placeOrderAPI } from '../../../api/trading/ally'
import { formatNegative, zeroIfNull } from '../../../utility/numberFormat'
import OrderOptions from '@material-ui/icons/MenuOpenRounded'
import { Popover, FormGroup } from '@material-ui/core'



export const TDCashInfo = ({holdings}) => {
    if (!holdings){
        return ''
    }
    return (
        <Card bg="dark">
            <Card.Header style={{color: 'white'}}><b>{holdings.AccountType} Account Info</b></Card.Header>
            <ListGroup  variant="flush">
                {holdings.AccountType === 'CASH'
                ? <Fragment>
                        <ListGroup.Item variant="dark">Pending Deposits: ${holdings.PendingDeposits}</ListGroup.Item>
                        <ListGroup.Item variant="dark">Unsettled Cash: ${holdings.UnsettledCash}</ListGroup.Item>
                        <ListGroup.Item variant="dark">Available For Withdrawal: ${holdings.AvailableForWithdrawal}</ListGroup.Item>
                </Fragment>
                : <Fragment>
                        <ListGroup.Item variant="dark">Cash Balance: ${holdings.AvailableForWithdrawal}</ListGroup.Item>
                        <ListGroup.Item variant="dark">Pending Deposits: ${holdings.PendingDeposits}</ListGroup.Item>
                        <ListGroup.Item variant="dark">Equity Percentage: ${holdings.UnsettledCash}</ListGroup.Item>
                </Fragment>
                }
                
            </ListGroup>
        </Card>
    )
}
