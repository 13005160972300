import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { handleErrors } from '../../../api/base'
import { Container, Row, Col, Card, ListGroup, Table, Form, Button,
     Modal, OverlayTrigger, PopoverTitle, PopoverContent, FormCheck, FormLabel,
      InputGroup, ProgressBar } from 'react-bootstrap'
import { Toasty } from '../../trading/toast'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { getAccountsToSMSAPI, sendSMSAPI } from '../../../api/user/discordusermanager'
import { getAllDMSettingsAPI } from '../../../api/user/discordmanager'
import { refreshAPI } from '../../../api/user/auth'
import { Redirect } from 'react-router-dom'
import{ validateSignIn, signOutAction } from '../../../actions/user'
import { loadDMSettingsAction } from '../../../actions/discordmanager'
import { FormControl, InputLabel, Select, ThemeProvider, MenuItem } from '@material-ui/core'
import DarkTheme from '../../../utility/muiTableDark'


export const ManagerSMS = () => {
    
    const isBig = useMediaQuery('(min-width:600px)')

    // Redux    
    const user = useSelector(state => state.user)
    const dmState = useSelector(state => state.discordManager)
    const dispatch = useDispatch()

    // Local State
    const [toasts, setToasts] = useState([])
    const [header, setHeader] = useState('')
    const [body, setBody] = useState('')
    const [ownerID, setOwnerID] = useState('')
    const [accountOptions, setAccountOptions] = useState([])
    const [preview, setPreview] = useState('')
    const [channels, setChannels] = useState([])
    const [selectedChannels, setSelectedChannels] = useState([])
    const [selectedAccount, setSelectedAccount] = useState('')

    const menuProps = {
        PaperProps: {
            className: 'SMSChannelPopper'
        }
    }

    useEffect(() => {
        getAccountsToSMSAPI(user.UserID, user.IdToken, user.AccessToken)
            .then(handleErrors)
            .then(results => {
                if (!results){
                    notify('Error', 'Error Getting Accounts To Manage')
                    return
                }
                setAccountOptions(results)
                if (results.length == 1){
                    setSelectedAccount(results[0].OwnerID.toString())
                    setChannels(results[0].Channels)
                }
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    if (msg === 'Refresh'){
                        refreshAPI(user.RefreshToken)
                            .then(handleErrors)
                            .then(result => {
                                dispatch(validateSignIn(result))
                            })
                            .catch(error => error)
                            .then(msg => {
                                if (msg && msg.message === '404'){
                                    dispatch(signOutAction())
                                }
                            })
                    }
                    if (msg.message === 'Failed to fetch'){
                        dispatch(signOutAction())
                    }
                    notify('Error', msg.toString())
                }
            })

        getAllDMSettingsAPI(user.UserID, user.IdToken, user.AccessToken)
            .then(handleErrors)
            .then(results => {
                if (!results){                    
                    notify('Error', 'Error Loading Settings')
                    return;
                }
                dispatch(loadDMSettingsAction(results))
                setHeader(results.Name.split(' ')[0] || '')
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    if (msg.message === 'Failed to fetch'){
                        dispatch(signOutAction())
                    }
                    notify('Error', msg.toString())
                }
            })
    }, [])

    const notify = (title, msg, existing) => {
        if (!existing){
            existing = toasts
        }
        let tsts = existing.map(x => x)
        tsts.push({
            Title: title,
            Message: msg
        })
        setToasts(tsts)
        return tsts
    }

    const updatePreview = (account, head, bod, forDiscord) => {
        if (account === ''){
            setPreview('')
            return
        }
        if (account === null){
            account = selectedAccount
        }
        account = Number(account)
        const match = accountOptions.filter(x => x.OwnerID === account)[0]
        
        account = (match && match.Name) || ''

        if (head === null){
            head = header
        }
        if (bod === null){
            bod = body
        }

        if (account === '' || bod === ''){
            setPreview('')
            return
        }
        if (forDiscord){
            return `${head && `[${head}]`} ${bod}`.substring(0, 300)
        }
        else{
            const formatted = `(${account}) ${head && `[${head.replace(/[^0-9a-z]/gi, '')}]`} ${bod}`.substring(0, 160)
            setPreview(formatted)
        }
    }

    const updateChannels = (account) => {
        if (!account){
            setChannels([])
            return
        }
        account = Number(account)
        const match = accountOptions.filter(x => x.OwnerID === account)[0]
        if (!match){
            setChannels([])
            return
        }
        setChannels(match.Channels)
    }

    const handleChannelSelect = (e) => {
        console.log(e.target.value)
        let options  = e.target.value
        if (false){
            options = e.target
            const value = []
            for (let i = 0, l = options.length; i < l; i += 1) {
                if (options[i].selected) {
                    value.push(options[i].value)
                }
            }
            options = value
        }
        console.log(options)
        //debugger
        // if (!options){
        //     return
        // }
        // const value = []
        // for (let i = 0, l = options.length; i < l; i += 1) {
        //     if (options[i].selected) {
        //         value.push(options[i].value)
        //     }
        // }
        setSelectedChannels(options)
    }

    const handleNativeSelect = (e) => {
        const newVal = e.target.value
        let newList = selectedChannels.map(x => x)

        newList.indexOf(newVal) === -1
            ? newList.push(newVal)
            : newList.length === 1
                ? (newList = [])
                : newList.splice(newList.indexOf(newVal), 1)

        setSelectedChannels(newList)
    }

    const sendSMS = () => {
        if (!preview || !selectedChannels || !selectedChannels.length){
            return
        }
        let notes = notify('Sending', 'Sending Notifications')
        const data = {
            DiscordAccountID: accountOptions.filter(x => x.OwnerID === Number(selectedAccount))[0].DiscordAccountID,
            Message: preview,
            ChannelIDs: selectedChannels.map(x => x.toString()),
            DiscordMessage: updatePreview(null, null, null, true)
        }
        sendSMSAPI(user.UserID, user.IdToken, user.AccessToken, data)
            .then(handleErrors)
            .then(results => {
                if (results !== 'Success'){
                    notify('Error', 'Error Sending SMS', notes)
                    return;
                }
                notify('Success', 'Notifications Sent', notes)
                setBody('')
                updatePreview(null, null, '')
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString(), notes)
                }
            })
    }

    if (!user.CanAccessOtherManagers && !user.DiscordManager){
        return <Redirect to="/" />
    }

    return (
        <Container fluid>
            <Card bg="dark" style={{color: 'white'}}>
                <Card.Header>
                    <b>Send Notifications - Discord & SMS</b>
                </Card.Header>
                <Card.Body>
                    <Form style={{textAlign: isBig ? 'right' : 'left'}}>
                        <Form.Group as={Row}>
                            <Col sm={0} lg={2}></Col>
                            <Form.Label sm={3} lg={2} column>
                                Account
                            </Form.Label>
                            <Col sm={4} lg={4}>
                                <Form.Control as="select" value={selectedAccount} onChange={(e) => {setSelectedAccount(e.target.value); updatePreview(e.target.value, null, null); updateChannels(e.target.value)}}>
                                    <option value="">Select Account</option>
                                    {accountOptions && accountOptions.map(x => {
                                        return (<option value={x.OwnerID}>{x.Name}</option>)
                                    })}
                                </Form.Control>
                            </Col>
                            <Col sm={0} lg={2}></Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm={0} lg={2}></Col>
                            <Form.Label sm={3} lg={2} column>
                                Channels
                            </Form.Label>
                            <Col sm={4} lg={4}>
                               <ThemeProvider theme={DarkTheme()}>
                                    <FormControl style={{color: 'white !important', width: '100%'}}>
                                        <Select
                                            id="channels-select"
                                            multiple
                                            native={false}
                                            value={selectedChannels}
                                            onChange={handleChannelSelect}
                                            MenuProps={menuProps}
                                            renderValue={(v) => {
                                                if (v.length > 2){
                                                    return `${v.length} Selected`
                                                }
                                                let matches = channels.filter(x => v.includes(x.ChannelID))
                                                matches = matches.map(x => x.Name)

                                                return matches.join(', ')
                                            }}
                                        >
                                            {true
                                                ? channels.map(x => {
                                                    return (
                                                        <MenuItem key={x.ChannelID} value={x.ChannelID}>
                                                            <Form.Check checked={selectedChannels.includes(x.ChannelID)} />
                                                            <span>{x.Name}</span>
                                                        </MenuItem>
                                                    )
                                                })
                                                : channels.map(x => {
                                                    return (
                                                        <option key={x.ChannelID} value={`${x.ChannelID}`}>{x.Name}</option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </ThemeProvider>
                            </Col>
                            <Col sm={0} lg={2}></Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm={0} lg={2}></Col>
                            <Form.Label sm={3} lg={2} column>
                                User
                            </Form.Label>
                            <Col sm={4} lg={4}>
                                <Form.Control value={header} onChange={(e) => {setHeader(e.target.value); updatePreview(null, e.target.value, null)}} />
                            </Col>
                            <Col sm={0} lg={2}></Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm={0} lg={2}></Col>
                            <Form.Label sm={3} lg={2} column>
                                Body
                            </Form.Label>
                            <Col sm={4} lg={4}>
                                <Form.Control rows="6" as="textarea" value={body} onChange={(e) => {setBody(e.target.value); updatePreview(null, null, e.target.value)}} />
                            </Col>
                            <Col sm={0} lg={2}></Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm={0} lg={2}></Col>
                            <Form.Label sm={3} lg={6} column style={{textAlign: 'end'}}>
                                Characters {preview.length} / 160
                            </Form.Label>
                            <Col sm={0} lg={2}></Col>
                        </Form.Group>
                        {preview && 
                            <Form.Group as={Row}>
                                <Col sm={0} lg={2}></Col>
                                <Form.Label sm={3} lg={2} column>
                                    Preview
                                </Form.Label>
                                <Col sm={4} lg={4}>
                                    <Form.Label style={{wordBreak: 'break-word'}}>
                                        {preview}
                                    </Form.Label>
                                </Col>
                                <Col sm={0} lg={2}></Col>
                            </Form.Group>
                        }
                        <Form.Group as={Row} style={{justifyContent: 'center'}}>
                            <Button disabled={!preview || !selectedChannels.length} onClick={sendSMS}>Send</Button>                            
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
            <div style={{position: 'fixed', top: '20px', right: '20px'}}>
                {toasts.map(x => {
                    return <Toasty title={x.Title} message={x.Message} />
                })}
            </div>
        </Container>
    )
}