import React, { useState } from 'react'
import {  useSelector } from 'react-redux'
import { Row, Col, Form, Button, Card } from 'react-bootstrap'
import { makeStyles } from '@material-ui/styles'
import { handleErrors } from '../../api/base'
import GoodIcon from '@material-ui/icons/Done'
import BadIcon from '@material-ui/icons/Error'
import Tooltip from '@material-ui/core/Tooltip'
import { changePasswordAPI } from '../../api/user/user'

const useStyles = makeStyles({
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    column: {
        flexDirection: 'column'
    },
    fullHeight: {
        height: '100vh'
    },
    big: {
        height: '40%',
        width: '80%'
    },
    outline: {
        border: '.2rem solid #ececec',
        borderRadius: '8px'
    },
    darkJumbo: {
        backgroundColor: '#d2d5d8'
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between'
    }
})

export const ChangePassword = ({notify}) => {
    const classes = useStyles();
    const user = useSelector(state => state.user)
    const [oldPassword, setOldPassword] = useState('')
    const [password, setPassword] = useState('')
    const [rptPW, setRptPW] = useState('')
    const [pwGood, setPWGood] = useState(undefined)
    const [rptGood, setRptGood] = useState(null)


    const handlePWCheck = (pw) => {
        const valid = pw.length >= 8 && pw.match(/[A-Z]/) && pw.match(/[a-z]/) && pw.match(/\d/) && pw !== oldPassword
        if (pw === ''){
            setPWGood(undefined)
        }
        else{
            setPWGood(valid)
        }
    }

    const handleRptPWCheck = (pw1, pw2) => {
        if (pw2 === undefined){
            pw2 = password
        }
        if (pw1 === undefined){
            pw1 = rptPW
        }
        const valid = pw1 === pw2
        if (password === '' || rptPW === ''){
            setRptGood(null)
        }
        else{
            setRptGood(valid)
        }
    }

    const changePassword = (e) => {
        e.preventDefault()

        notify('Updating', 'Updating your password')
        const data = {
            OldPassword: oldPassword,
            NewPassword: password
        }
        return changePasswordAPI(user.UserID, user.IdToken, user.AccessToken, data)
            .then(handleErrors)
            .then(results => {
                if (results !== 'Success'){
                    notify('Error', 'Error Changing Password')
                    return
                }
                notify('Updated', 'Password updated')
                setRptPW('')
                setPassword('')
                setOldPassword('')
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
            })
    }

    return (
        <Card bg="dark" style={{color: 'white'}}>
            <Card.Header><b>Change Password</b></Card.Header>
            <Card.Body>
                <Form onSubmit={changePassword}>
                    <Form.Group as={Row}>
                        <Col sm={0} lg={1} xl={2}></Col>
                        <Form.Label column>
                            Current Password
                        </Form.Label>
                        <Col>
                            <Form.Control type="password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                        </Col>
                        <Col sm={0} lg={1} xl={2}></Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={0} lg={1} xl={2}></Col>
                        <Form.Label column style={{display: 'flex', justifyContent: 'space-between'}}>
                            <span>New Password</span>
                            {pwGood !== undefined && (pwGood 
                                ? <Tooltip title="Good"><GoodIcon style={{color: 'green'}} /></Tooltip>
                                : <Tooltip title="Must have 8 Characters, Upper, Lower, Number"><BadIcon style={{color: 'red'}} /></Tooltip>
                            )}
                        </Form.Label>
                        <Col>
                            <Form.Control type="password" value={password} onChange={(e) => {setPassword(e.target.value); handlePWCheck(e.target.value); handleRptPWCheck(undefined, e.target.value)}} />
                        </Col>
                        <Col sm={0} lg={1} xl={2}></Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={0} lg={1} xl={2}></Col>
                        <Form.Label column style={{display: 'flex', justifyContent: 'space-between'}}>
                            <span>Repeat New Password</span>
                            {rptGood !== null && (rptGood 
                                ? <Tooltip title="Good"><GoodIcon style={{color: 'green'}} /></Tooltip>
                                : <Tooltip title="Must Match Password"><BadIcon style={{color: 'red'}} /></Tooltip>
                            )}
                        </Form.Label>
                        <Col>
                            <Form.Control type="password" value={rptPW} onChange={(e) => {setRptPW(e.target.value); handleRptPWCheck(e.target.value)}} />
                        </Col>
                        <Col sm={0} lg={1} xl={2}></Col>
                    </Form.Group>                    
                    <div className={`${classes.center}`}>
                        <Button variant={password === oldPassword || !oldPassword || !password || !pwGood || !rptPW || !rptGood ? "danger" : "success"} type="submit" disabled={password === oldPassword || !oldPassword || !password || !pwGood || !rptPW || !rptGood}>
                            Change Password
                        </Button>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    )
}

export default ChangePassword