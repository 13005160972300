import React from 'react'

export const Iframe = ({props}) => {
    console.log('iframe props', props)
    
    return (
        <div>
            <iframe src={props.src || ''} height={props.height || 0} width={props.width || 0} />
        </div>
    )
}

export default Iframe