export const SYMBOL = 'SYMBOL'
export const STOCKTOGGLE = 'STOCKTOGGLE'
export const CLEAR = 'CLEAR'
export const EXPIRATIONS = 'EXPIRATIONS'
export const STRIKES = "STRIKES"

export function setSymbolAction(symbol){
    return {
        type: SYMBOL,
        data: symbol
    }
}

export function setStockToggleAction(toggle){
    return {
        type: STOCKTOGGLE,
        data: toggle
    }
}

export function setExpirationsAction(exps){
    return {
        type: EXPIRATIONS,
        data: exps
    }
}


export function setStrikesAction(stks){
    return {
        type: STRIKES,
        data: stks
    }
}

export function clearAlly(){
    return {
        type: CLEAR
    }
}


