import React, { useState, useEffect } from 'react'
import { connectToQBOWS } from '../../../api/quickbooks/socket'
import qs from 'query-string'
import { useSelector } from 'react-redux'

export const QBAuth = ({...props}) => {
    
    const user = useSelector(state => state.user)
    const [qboSocket, setQBOSocket] = useState(null)
    const [message, setMessage] = useState('Linking')

    // URL parsing
    const value = qs.parse(props.location.search)
    const code = value.code
    const realmId = value.realmId
    const state = value.state

    useEffect(() => {
        if (!code || !realmId || !state){
            setMessage('Error Linking QuickBooks')
            return
        }
        if (user && (user.Flags & 2048) === 2048 && qboSocket == null){
            const socketProps = {
                AccessToken: user.AccessToken,
                GetTokens: true,
                code,
                realmId,
                state,
                setMessage
            }
            setQBOSocket(connectToQBOWS(socketProps))
        }
    }, [])

    return <h2 style={{textAlign: 'center'}}>
        {message}
    </h2>
}