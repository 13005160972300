import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { handleErrors } from '../../../api/base'
import { setTokenAction } from '../../../actions/td'
import qs from 'query-string'
import {getTokenFromCode} from '../../../api/trading/td'

export const TDSync = (props) => {


    const dispatch = useDispatch()

    const value = qs.parse(props.location.search);

    const code = value.code
    const user = useSelector(state => state.user)
    

    useEffect(() => {
        if (!user.UserID){
            alert('Must log in first!');
            return;
        }
        if (!code){
            alert('Code required!');
        }
        getTokenFromCode(user.UserID, user.IdToken, user.AccessToken, code)
            .then(handleErrors)
            .then((result) => {
                if (!result || !result.access_token){
                    alert('Invalid!')
                    return;
                }
                dispatch(setTokenAction(result))
                setTimeout(() => {window.close()}, 2000)
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    console.log(msg)
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])        

    return (
        ''
    )
}
