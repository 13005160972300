import { createMuiTheme } from '@material-ui/core/styles';

export default function getMuiTheme() {
    return createMuiTheme({
        overrides: {
            MUIDataTableBodyCell: {
                root: {
                    backgroundColor: '#343a40',
                    color: 'white'
                }
            },
            MUIDataTableHeadCell: {
                root: {
                    backgroundColor: '#343a40 !important',
                    color: 'white'
                },
                sortActive: {
                    color: 'white'
                },
            },
            MUIDataTableToolbar: {
                root: {
                    // backgroundColor: '#343a40 !important',
                    color: 'white',
                    backgroundColor: 'rgba(0,0,0,.03)',
                    borderBottom: '1px solid rgba(0,0,0,.125)'
                }
            },
            MuiSvgIcon: {
                root: {
                    color: 'white'
                }
            },
            MuiTableRow: {
                root: {
                    marginBottom: '3px'
                }
            },      
            MuiTableFooter: {
                root: {
                    backgroundColor: '#343a40 !important',
                    color: 'white',
                    // backgroundColor: 'rgba(0,0,0,.03)',
                    borderTop: '1px solid rgba(0,0,0,.125)'
                }
            },
            MuiTypography: {
                root: {
                    color: 'white !important'
                }
            },
            MuiSelect: {
                root: {
                    color: 'white'
                },
                icon: {
                    color: 'white'
                }
            },
            MuiPopover: {
                paper: {              
                    backgroundColor: '#343a40',
                    boxShadow: '1px 1px 5px slategrey'
                }
            },
            MUIDataTableFilter: {
                root: {              
                    backgroundColor: '#343a40',
                }
            },
            MuiFormLabel: {
                root: {
                    color: 'white'
                }
            },
            MuiButton: {
                label: {
                    color: 'white'
                }
            },
            MuiListItem: {
                root: {
                    color: 'white'
                }
            },
            MUIDataTableSearch: {
                searchIcon: {
                    color: 'white'
                },
                searchText: {
                    color: 'white'
                }
            },
            MuiPaper: {
                root: {
                    backgroundColor: '#343a40',
                }
            },
            MuiToolbar: {
                root: {                
                    backgroundColor: '#343a40',
                }
            },
            MuiInputBase: {
                root: {
                    color: 'white'
                }
            },
            MuiTableSortLabel: {
                icon: {
                    color: 'white !important'
                }
            },
            MuiInput: {
                underline: {
                    color: 'white',
                    '&:before': {
                        borderBottom: '1px solid rgba(255, 255, 255, 0.42)',
                    },
                    '&:hover:not(.Mui-disabled):before': {
                        borderBottom: '2px solid rgba(255, 255, 255, 0.42)',
                    }
                }
            }
        }
    })
}