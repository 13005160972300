import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Card, Form } from 'react-bootstrap'
import { makeStyles } from '@material-ui/styles'
import { handleErrors } from '../../../api/base'
import { refreshAPI } from '../../../api/user/auth'
import { validateSignIn, signOutAction } from '../../../actions/user'
import { Toasty } from '../toast'
import {BuyingPower} from '../ally/buyingpower'
import {TDTrade} from './trade'
import {TDHoldings} from './holdings'
import {Orders} from './orders'
import {TDCashInfo} from './cashInfo'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TradeViewGraph } from '../tradingview/graph2'



import { getNewTokenWithRefresh, getTDAccountAPI, getTDQuote, getTDExpirations, getTDStrikes } from '../../../api/trading/td'

import { setTokenAction, tdClearDataAction, setTDSymbolAction, setTDExpirationsAction, setTDStrikesAction, setTDPriceColorAction } from '../../../actions/td'

const useStyles = makeStyles({
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    column: {
        flexDirection: 'column'
    },
    fullHeight: {
        height: '100vh'
    },
    big: {
        height: '40%',
        width: '80%'
    },
    outline: {
        border: '.2rem solid #ececec',
        borderRadius: '8px'
    },
    darkJumbo: {
        backgroundColor: '#d2d5d8'
    },
    padded: {
        padding: '15px'
    },
    noPad: {
        padding: '0px'
    }
})

export const TD = () => {
    const classes = useStyles()
    const [holdings, setHoldings] = useState(null)
    const [orders, setOrders] = useState(null)
    const [message, setMessage] = useState('')
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const [toasts, setToasts] = useState([])
    const [stockToggle, setStockToggle] = useState(false)
    const [expiration, setExpiration] = useState('')
    const [strike, setStrike] = useState('0')
    const [side, setSide] = useState('1')
    const [quote, setQuote] = useState(null)
    const [quantity, setQuantity] = useState('0')
    const [priceColor, setPriceColor] = useState('white')
    const [showInfo, setShowInfo] = useState(true)

    const tdState = useSelector(state => state.td)

    const symbol = tdState.symbol
    const setSymbol = (x) => {dispatch(setTDSymbolAction(x))}
    const setExpirations = (x) => {dispatch(setTDExpirationsAction(x))}
    const setStrikes = (x) => {dispatch(setTDStrikesAction(x))}

    const isBig = useMediaQuery('(min-width:600px)');

    useEffect(() => {
        try{
            // dispatch(clearAlly())
            if (holdings == null){
                checkToken()
                    .then(() => {loadInfo()})                
            }
            const interval = setInterval(() => {
                checkToken()
                    .then(() => {loadInfo()})
            }, 20000);
            return () => clearInterval(interval)
        }
        catch (err){
            console.log(err)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const checkToken = () => {
        if (!tdState || !tdState.token || !tdState.token.access_token){
            window.open('https://auth.tdameritrade.com/auth?response_type=code&redirect_uri=https%3a%2f%2flocalhost%3a3000%2ftdsync&client_id=27CVWVLRIVWLAAMWEJRNLJLH4CSSQG9V%40AMER.OAUTHAP')
            dispatch(tdClearDataAction())
            return Promise.resolve()
        }
        let recTime = new Date(tdState.token.tokenTime)
        let needsRefresh = false;
        if (!recTime){
            needsRefresh = true
        }
        else{
            recTime.setSeconds(recTime.getSeconds() + 1600)
            if (new Date() > recTime){
                needsRefresh = true
            }
        }
        if (needsRefresh && tdState.token.refresh_token){
            return getNewTokenWithRefresh(user.UserID, user.IdToken, user.AccessToken, tdState.token.refresh_token)
                .then(handleErrors)
                .then(result => {
                    if (!result || !result.access_token){
                        notify('Token Error', 'Error While Refreshing Token')
                        return
                    }
                    dispatch(setTokenAction(result))
                })
                .catch(error => error)
                .then(msg => {     
                    if (msg){
                        console.log(msg)
                        notify('Error', msg.toString())
                    }
                })
        }
        return Promise.resolve()
    }

    const notify = (title, msg, tsts) => {
        if (!tsts){
            tsts = toasts.map(x => x)
        }
        tsts.push({
            Title: title,
            Message: msg
        })
        setToasts(tsts)
        return tsts
    }

    const loadInfo = () => {
        return getTDAccountAPI(user.UserID, user.IdToken, user.AccessToken, tdState.token.access_token)
            .then(handleErrors)
            .then(result => {
                if (result == null){
                    return;
                }
                setHoldings(result)
                setOrders(result.Orders)
            })
            .catch(error => error)
            .then(msg => {
                if (msg) {
                    if (msg.message === 'Failed to fetch'){
                        dispatch(signOutAction())
                    }
                    if (msg === 'Refresh'){
                        refreshAPI(user.RefreshToken)
                            .then(handleErrors)
                            .then(result => {
                                dispatch(validateSignIn(result))
                                window.location.reload()
                            })
                            .catch(error => error)
                            .then(msg => {
                                if (msg && msg.message === '404'){
                                    dispatch(signOutAction())
                                }
                            })
                    }
                    setMessage(msg)
                }
            })
    }

    const getExps = (sym) => {
        
        if (!sym) {
            sym = symbol
        }
        if (!sym){
            return;
        }
        
        return getTDExpirations(sym, user.UserID, user.IdToken, user.AccessToken, tdState.token.access_token)
            .then(handleErrors)
            .then(result => {
                setExpirations(result)
            })
            .catch(error => error)
            .then(msg => {
                if (msg) {
                    setMessage(msg)
                }
                setDDLDefaults()
            })

    }

    const getStks = (sym, skipUpdate = false, expir) => {
        
        if (!sym) {
            sym = `${symbol}|${expir}`
        }
        if (!sym){
            return;
        }
        sym = encodeURIComponent(sym)
        return getTDStrikes(sym, user.UserID, user.IdToken, user.AccessToken, tdState.token.access_token)
            .then(handleErrors)
            .then(result => {
                result = result.map(x => {
                    x = {Price: x}
                    return x
                })
                setStrikes(result)
                if (!skipUpdate){
                    setTimeout(() => {updateStrikes(result)}, 500)
                }
            })
            .catch(error => error)
            .then(msg => {
                if (msg) {
                    setMessage(msg)
                }
            })
    }

    const updateStrikes = (stks) => {
        let found = false
        const qt = JSON.parse(sessionStorage.getItem('tdQuote'))
        const last = qt ? qt.Last : 0
        let targetStrike
        let newStrikes = stks.map(x => {            
            if (!found && x.Price >= last){
                targetStrike = x.Price
                found = true
            }
            return x
        })
        setStrikes(newStrikes)
        setDDLDefaults()
        setStrike(targetStrike)
    }
    
    const setDDLDefaults = () => {
        let sde = document.getElementById('sideDDL')
        if (!sde){
            return
        }
        sde = sde.value
        setSide(sde)
        let stk = document.getElementById('tdStrikeDDL')
        let exp = document.getElementById('tdExpDDL')
        if (!stk || !exp){
            return
        }
        stk = stk.value
        setStrike(stk)
        exp = exp.value
        setExpiration(exp)
    }

    const getQte = (sym) => {
        if (!sym) {
            sym = document.getElementById('tdSymbol').value
        }
        if (!sym){
            return Promise.resolve()
        }
        return getTDQuote(sym, user.UserID, user.IdToken, user.AccessToken, tdState.token.access_token)
            .then(handleErrors)
            .then(result => {
                let lastQuote = sessionStorage.getItem('tdQuote')
                if (lastQuote){
                    lastQuote = JSON.parse(lastQuote)
                }
                if (lastQuote && result.symbol === lastQuote.symbol){
                    if (result.Last > lastQuote.Last){
                        dispatch(setTDPriceColorAction('#0cff0c'))
                    }
                    else if (result.Last < lastQuote.Last){
                        dispatch(setTDPriceColorAction('red'))
                    }
                    else{
                        dispatch(setTDPriceColorAction('white'))
                    }
                }
                else{
                    dispatch(setTDPriceColorAction('white'))
                }
                setQuote(result)
                sessionStorage.setItem('tdQuote', JSON.stringify(result))
            })
            .catch(error => error)
            .then(msg => {
                if (msg) {
                    setMessage(msg)
                }
                setDDLDefaults()
            })
    }


    //Trading Props    
    const tradeProps = {symbol, setSymbol, stockToggle, setStockToggle, getExps, getStks,
        expiration, setExpiration, strike, setStrike, quantity, setQuantity,
         side, setSide, setDDLDefaults, getQte, quote, loadInfo, notify, priceColor}

    return (
        <Container fluid>
            <Card bg="dark">
                <Card.Header style={{color: 'white'}}>
                    <div style={{display: 'flex'}}>
                    <b>TD Ameritrade &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {holdings ? `Account Value: $${holdings.Value}` : 'Loading Account...'}</b>
                    <Form style={{marginLeft: '15px'}}>
                        <Form.Check checked={showInfo} type="switch" id="tdAccountInfo"
                             label="Show Account Info" onChange={(e) => {setShowInfo(e.target.checked)}}
                        />
                    </Form>
                    </div>
                </Card.Header>
                <Card.Body className={isBig ? '' : classes.noPad}>
                    <Container fluid className={isBig ? '' : classes.noPad}>
                        <Row>
                        <Col sm={12} lg={6}>
                            {showInfo && (
                                <Row>
                                    <Col sm={12} lg={6} className={classes.padded}>
                                        <BuyingPower holdings={holdings} />
                                    </Col>
                                    <Col sm={12} lg={6} className={classes.padded}>
                                        <TDCashInfo holdings={holdings} />
                                    </Col>
                                </Row>
                            )}
                            {symbol 
                            ? <Row>
                                <Col sm={12} className={classes.padded}>
                                    <TradeViewGraph symbol={symbol} />
                                </Col>
                                </Row>
                            : '' }
                            <Row>
                                <Col sm={12}>
                                    <TDHoldings holdings={holdings} {...tradeProps} />
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} lg={6}>
                            <Row>
                                <Col sm={12} className={classes.padded}>
                                    <TDTrade toasts={toasts} setToasts={setToasts} {...tradeProps} />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} className={classes.padded}>
                                    <Orders orders={orders} notify={notify} loadInfo={loadInfo} />
                                </Col>
                            </Row>
                        </Col>
                        </Row>
                        {/* <Col>
                            <Row>
                                <Col sm={6} lg={3} className={classes.padded}>
                                    <BuyingPower holdings={holdings} />
                                </Col>
                                <Col sm={6} lg={3} className={classes.padded}>
                                    <TDCashInfo holdings={holdings} />
                                </Col>
                                <Col sm={12} lg={6} className={classes.padded}>
                                    <TDTrade toasts={toasts} setToasts={setToasts} {...tradeProps} />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} lg={6} className={classes.padded}>
                                    <TDHoldings holdings={holdings} {...tradeProps} />
                                </Col>
                                <Col sm={12} lg={6} className={classes.padded}>
                                    <Orders orders={orders} toasts={toasts} setToasts={setToasts} loadInfo={loadInfo} />                                
                                </Col>
                            </Row>
                        </Col> */}
                    </Container>
                </Card.Body>
            </Card>            
            <div style={{position: 'fixed', top: '20px', right: '20px'}}>
                {toasts.map(x => {
                    return <Toasty title={x.Title} message={x.Message} />
                })}
            </div>
        </Container>
    )
}

