
import { SYMBOL, CLEAR, STOCKTOGGLE, EXPIRATIONS, STRIKES } from '../actions/ally'
import {SIGN_OUT} from '../actions/user'

export default function ally(state = {}, action) {
	switch (action.type) {
        case SYMBOL :
            return {
                ...state,
                symbol: action.data
            }
        case STOCKTOGGLE:
            return {
                ...state,
                stockToggle: action.data
            }
        case EXPIRATIONS:
            return {
                ...state,
                expirations: action.data
            }
        case STRIKES:
            return {
                ...state,
                strikes: action.data
            }
        case CLEAR :
            return {}
            
        case SIGN_OUT :
            return {}
        default :
			return state
    }
}