import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { handleErrors } from '../../api/base'
import qs from 'query-string'
import { getDUToken } from '../../api/user/discorduser'
import { loadDUDataAction } from '../../actions/discorduser'
import { Container } from 'react-bootstrap'

export const DiscordUserSync = (props) => {


    const dispatch = useDispatch()

    const value = qs.parse(props.location.search);

    const code = value.code
    const user = useSelector(state => state.user)

    const [isDone, setIsDone] = useState(false)

    const redirectInfo = value.state

    let referral, ownerURL, promoURL, tier, linking;


    if (redirectInfo){
        const parts = redirectInfo.split('!')
        parts.forEach(part => {
            const innerParts = part.split('@')
            switch (innerParts[0]) {
                case 'a':
                    ownerURL = innerParts[1]
                    break
                case 'p':
                    promoURL = innerParts[1]
                    break
                case 'r':
                    referral = innerParts[1]
                    break
                case 't':
                    tier = innerParts[1]
                    break
                case 'l':
                    linking = innerParts[1]
                    break
            }
        })
    }
    

    useEffect(() => {
        if (!code){
            setIsDone(null)
            alert('Code required!');
        }

        if (ownerURL){
            if (ownerURL.includes('?')){
                ownerURL += '&code=' + code
            }
            else{
                ownerURL += '?code=' + code
            }
            if (promoURL){
                ownerURL += '&p=' + promoURL
            }
            if (referral){
                ownerURL += '&r=' + referral
            }
            if (tier){
                ownerURL += '&t=' + tier
            }
            if (linking){
                ownerURL += '&l=' + linking
            }
            window.location.replace(ownerURL)
        }
        else {
            getDUToken(code)
                .then(handleErrors)
                .then((result) => {
                    if (!result || !result.Token || !result.Token.access_token){
                        alert('Invalid Code!')
                        setIsDone(null)
                        return;
                    }
                    dispatch(loadDUDataAction(result))
                    localStorage.setItem('DUToken', JSON.stringify(result))
                    setIsDone(true)
                    window.close()
                })
                .catch(error => error)
                .then(msg => {
                    if (msg){
                        alert('An Error Has Occurred!')
                        console.log(msg)
                        setIsDone(null)
                    }
                })
            }
    }, [])        

    return (
        <Container fluid style={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', flexDirection: 'column'}}>
            {isDone === false && <h1>Linking...</h1>}
            {isDone === true && <h1>Linked! If This Window does not close it is safe to do so now.</h1>}
            {!redirectInfo && isDone === null && <h1>An Error Occurred When Linking!</h1>}
            <br />
            {!redirectInfo && (<h1>This window will close when sync has completed</h1>)}
            {redirectInfo && (<h1>Will Redirect When Done</h1>)}
        </Container>
    )
}
