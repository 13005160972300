import React, { useEffect, useState, Fragment } from 'react'
import { Form } from 'react-bootstrap'
import { Card, Row, Col, Button, Accordion } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { connectToQBOWS, requestType } from '../../../api/quickbooks/socket'
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckIcon from '@material-ui/icons/Check'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import  DatePicker  from 'react-datepicker'
import { ListGroup } from 'react-bootstrap'

export const QuickBooks = ({props, notify}) => {
    
    const user = useSelector(state => state.user)

    const [qboSocket, setQBOSocket] = useState(null)
    const [qbSettings, setQBSettings] = useState(null)
    const [mappings, setMappings] = useState(null)
    const [qbAuthInfo, setQBAuthInfo] = useState(null)
    const [savingSettings, setSavingSettings] = useState(false)
    const [savingMappings, setSavingMappings] = useState(false)
    const isSmall = useMediaQuery('(max-width:525px)')
    const [mappingError, setMappingError] = useState(true)
    const [processUpdates, setProcessUpdates] = useState([])
    const [isProcessing, setIsProcessing] = useState(false)
    const [qbVendors, setQBVendors] = useState([])
    const [qbAccounts, setQBAccounts] = useState([])
    const [mappingSearch, setMappingSearch] = useState('')

    const now = new Date()    
    now.setHours(0, 0, 0, 0)

    const [start, setStart] = useState(now)
    const [end, setEnd] = useState(now)

    useEffect(() => {
        if ((user.Flags & 2048) === 2048 && qboSocket == null){
            const socketProps = {
                AccessToken: user.AccessToken,
                notify,
                setQBSettings,
                setMappings,
                setSavingSettings,
                setSavingMappings,
                setQBAuthInfo,
                qbAuthInfo,
                setMappingError,
                mappingError,
                qbSettings,
                setProcessUpdates,
                setIsProcessing,
                processUpdates,
                setQBVendors,
                setQBAccounts
            }
            setQBOSocket(connectToQBOWS(socketProps))
        }
    }, [])

    const updateSetting = (prop, value, withSave) => {                
        const clone = Object.assign({}, qbSettings)
        clone[prop] = value
        setQBSettings(clone)
        if (withSave){
            saveSettings(null, clone)
        }
    }

    const saveSettings = (event, overrideSettings) => {
        setSavingSettings(true)
        const request = {
            RequestType: requestType.SaveSettings,
            Data: overrideSettings || qbSettings
        }
        
        const socketClone = {
            socket: qboSocket.socket,
            props: qboSocket.props
        }
        socketClone.props.qbSettings = request.Data
        setQBOSocket(socketClone)
        qboSocket.socket.send(JSON.stringify(request))
    }

    const process = () => {
        if (isProcessing){
            return
        }
        // prevent multiple concurent processings
        setIsProcessing(true)
        const request = {
            RequestType: requestType.ProcessAll,
            Data: {
                Start: start,
                End: end
            }
        }

        // reset update log
        setProcessUpdates([])

        // update socket props to empty
        const socketClone = {
            socket: qboSocket.socket,
            props: qboSocket.props
        }
        socketClone.props.processUpdates = []
        setQBOSocket(socketClone)

        // make request
        qboSocket.socket.send(JSON.stringify(request))
    }

    const getTestArray = (j) => {
        const x = []
        for (let i = 0; i < j; i++){
            x.unshift('Test ' + i)
        }
        return x
    }

    const qbAccountDDL = (presetVal) => {
        return <Form.Control as="select">
            <option>-- Account --</option>
            {qbAccounts && qbAccounts.Data.map(x => {
                return <option value={x.Id}>{x.Name}</option>
            })}
        </Form.Control>
    }
    const qbVendorDDL = (presetVal) => {
        return <Form.Control as="select">
            <option>-- Vendor --</option>
            {qbVendors && qbVendors.Data.map(x => {
                return <option value={x.Id}>{x.Name}</option>
            })}
        </Form.Control>
    }
    let searchTimeout
    const debounceSearch = (e) => {
        clearTimeout(searchTimeout)
        const tempVal = e.target.value
        searchTimeout = setTimeout(() => setMappingSearch(tempVal), 250)
    }

    return (
        <Card bg="dark" style={{color: 'white'}}>
            <Card.Header style={{display: 'flex', justifyContent: 'space-between'}}>
                <b>QuickBooks Online</b>
                {qbAuthInfo && (
                    <Button variant="secondary" href={qbAuthInfo.AuthLink} target="_blank">Authorize</Button>
                )}
            </Card.Header>
            <Card.Body>                
                {(user.Flags & 2048) === 2048
                ? (<Fragment>
                    <Accordion defaultActiveKey="settings">
                    <Card bg="dark" style={{marginBottom: '15px'}}>
                        <Accordion.Toggle as={Card.Header} eventKey="settings">
                            <b style={{paddingRight: '20px'}}>Settings</b>
                            {savingSettings
                            ? <CircularProgress style={{height: '20px', width: '20px'}} />
                            : <CheckIcon style={{color: 'green'}} />
                            }
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="settings">
                        {!qbSettings 
                        ? <Card.Body style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <CircularProgress style={{width: '100px', height: '100px'}} />
                        </Card.Body>
                        : <Card.Body>
                            <Form>
                                <Form.Group as={Row} style={{alignItems: 'center', textAlign: isSmall ? 'start' : 'end'}}>
                                    <Form.Label column sm={2}>
                                        Stripe Bank
                                    </Form.Label>
                                    <Col sm={2}>
                                        <Form.Control value={qbSettings.StripeBank} onChange={(e) => updateSetting('StripeBank', e.target.value)} onBlur={saveSettings} />
                                    </Col>
                                    <Form.Label column sm={2}>
                                        Payment Method
                                    </Form.Label>
                                    <Col sm={2}>
                                        <Form.Control value={qbSettings.PayMethod} onChange={(e) => updateSetting('PayMethod', e.target.value)} onBlur={saveSettings} />
                                    </Col>
                                    <Form.Label column sm={2}>
                                        Processing Fees Account
                                    </Form.Label>
                                    <Col sm={2}>
                                        <Form.Control value={qbSettings.ProcessingFees} onChange={(e) => updateSetting('ProcessingFees', e.target.value)} onBlur={saveSettings} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} style={{alignItems: 'center', textAlign: isSmall ? 'start' : 'end'}}>
                                    <Form.Label column sm={2}>
                                        Refund Account
                                    </Form.Label>
                                    <Col sm={2}>
                                        <Form.Control value={qbSettings.RefundAccount} onChange={(e) => updateSetting('RefundAccount', e.target.value)} onBlur={saveSettings} />
                                    </Col>
                                    <Form.Label column sm={2}>
                                        Dispute Account
                                    </Form.Label>
                                    <Col sm={2}>
                                        <Form.Control value={qbSettings.DisputeAccount} onChange={(e) => updateSetting('DisputeAccount', e.target.value)} onBlur={saveSettings} />
                                    </Col>
                                    <Form.Label column sm={2}>
                                        Dispute Fees Account
                                    </Form.Label>
                                    <Col sm={2}>
                                        <Form.Control value={qbSettings.DisputeFees} onChange={(e) => updateSetting('DisputeFees', e.target.value)} onBlur={saveSettings} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} style={{alignItems: 'center', textAlign: isSmall ? 'start' : 'end'}}>
                                    <Form.Label column sm={2}>
                                        Disputes Won Account
                                    </Form.Label>
                                    <Col sm={2}>
                                        <Form.Control value={qbSettings.DisputesWonAccount} onChange={(e) => updateSetting('DisputesWonAccount', e.target.value)} onBlur={saveSettings} />
                                    </Col>
                                    <Form.Label column sm={2}>
                                        Additional Stripe Fees
                                    </Form.Label>
                                    <Col sm={2}>
                                        <Form.Control value={qbSettings.AdditionalStripeFees} onChange={(e) => updateSetting('AdditionalStripeFees', e.target.value)} onBlur={saveSettings} />
                                    </Col>
                                    <Form.Label column sm={2}>
                                        Generic CustomerId
                                    </Form.Label>
                                    <Col sm={2}>
                                        <Form.Control value={qbSettings.GenericCustomerId} onChange={(e) => updateSetting('GenericCustomerId', e.target.value)} onBlur={saveSettings} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} style={{alignItems: 'center', textAlign: isSmall ? 'start' : 'end'}}>
                                    <Form.Label column sm={2}>
                                        Stripe Secret Key
                                    </Form.Label>
                                    <Col sm={2}>
                                        <Form.Control value={qbSettings.StripeSecretKey} onChange={(e) => updateSetting('StripeSecretKey', e.target.value)} onBlur={saveSettings} />
                                    </Col>
                                    <Form.Label column sm={2}>
                                        Timezone
                                    </Form.Label>
                                    <Col sm={2}>
                                        <Form.Control as="select" value={qbSettings.TimeZone} onChange={(e) => updateSetting('TimeZone', e.target.value, true)}>
                                            <option value={0}>-- Select Timezone -- </option>
                                            <option value={5}>Eastern</option>
                                            <option value={6}>Central</option>
                                            <option value={7}>Mountain</option>
                                            <option value={8}>Pacific</option>
                                            <option value={9}>Alaska</option>
                                            <option value={10}>Hawaii</option>
                                        </Form.Control>
                                    </Col>
                                    <Form.Label column sm={2}>
                                        Observes DST
                                    </Form.Label>
                                    <Col sm={2} style={{display: 'flex'}}>
                                        <Form.Check id={`dstCheck`} type="switch" label="" checked={qbSettings.ObserveDST} onChange={(e) => updateSetting('ObserveDST', e.target.checked, true)} />
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                        }
                        </Accordion.Collapse>
                    </Card>
                    </Accordion>
                    <Accordion defaultActiveKey="mappings">
                    <Card bg="dark" style={{marginBottom: '15px'}}>
                        <Accordion.Toggle as={Card.Header} eventKey="mappings">
                            <b style={{paddingRight: '20px'}}>Mappings</b>
                            {savingMappings
                            ? <CircularProgress style={{height: '20px', width: '20px'}} />
                            : <CheckIcon style={{color: 'green'}} />
                            }
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="mappings">
                            <Fragment>
                            {mappingError === true && (
                                <Card.Body style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <CircularProgress style={{width: '100px', height: '100px'}} />
                                </Card.Body>
                            )}
                            <Card.Body>
                                {mappingError
                                ? <h2>{mappingError}</h2>
                                : <Fragment>
                                    <Form.Group as={Row}>
                                        <Form.Label column lg={2} style={{textAlign: 'end'}}>
                                            Search
                                        </Form.Label>
                                        <Col lg={3}>
                                            <Form.Control onChange={(e) => debounceSearch(e)} />
                                        </Col>
                                    </Form.Group>
                                    <div style={{maxHeight: '20vh', overflowY: 'auto', overflowX: 'hidden'}}>
                                    {mappings && mappings.StripeAccounts.filter(x => mappingSearch === '' || x.Name.toLowerCase().includes(mappingSearch.toLowerCase())).map((x, i) => {
                                        const existing = mappings.Mappings.filter(y => y.StripeAccountId == x.Id)[0]
                                        return <Form.Group as={Row} style={{alignItems: 'center', textAlign: isSmall ? 'start' : 'end'}}>
                                            <Col lg={3}>
                                                <Row>
                                                    <Form.Label column lg={4}>
                                                        Stripe Account
                                                    </Form.Label>
                                                    <Form.Label column lg={8} style={{textAlign: 'left'}}>
                                                        {x.Name}
                                                    </Form.Label>
                                                </Row>
                                            </Col>
                                            <Col lg={3}>
                                                <Row>
                                                    <Form.Label column lg={5}>
                                                        QB Vendor
                                                    </Form.Label>
                                                    <Col lg={7}>
                                                        {qbVendorDDL()}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={3}>
                                                <Row>
                                                    <Form.Label column lg={5}>
                                                        QB Account
                                                    </Form.Label>
                                                    <Col lg={7}>
                                                        {qbAccountDDL()}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Form.Group>   
                                    })}       
                                    </div>                             
                                </Fragment>
                                }
                            </Card.Body>
                            </Fragment>
                        </Accordion.Collapse>
                    </Card>
                    </Accordion>
                    <Card bg="dark">
                        <Card.Header>
                            <b style={{paddingRight: '20px'}}>Processing</b>
                            {isProcessing
                            ? <CircularProgress style={{height: '20px', width: '20px'}} />
                            : <CheckIcon style={{color: 'green'}} />
                            }
                        </Card.Header>
                        {mappingError === true && (
                            <Card.Body style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <CircularProgress style={{width: '100px', height: '100px'}} />
                            </Card.Body>
                        )}
                        <Card.Body>
                            {mappingError
                            ? <h2>{mappingError}</h2>
                            : (<Fragment>
                            <Row style={{textAlign: isSmall ? 'start' : 'end'}}>
                                <Form.Label column lg={1}>
                                    Start
                                </Form.Label>
                                <Col lg={3} style={{textAlign: 'start'}}>
                                    <DatePicker
                                        selected={start}
                                        onChange={(date) => setStart(date)}
                                        
                                        dateFormat="MMMM d, yyyy"
                                    />
                                </Col>
                                <Form.Label column lg={1}>
                                    End
                                </Form.Label>
                                <Col lg={3} style={{marginBottom: '10px', textAlign: 'start'}}>
                                    <DatePicker
                                        selected={end}
                                        onChange={(date) => setEnd(date)}
                                       
                                        dateFormat="MMMM d, yyyy"
                                    />
                                </Col>
                                <Col lg={4} style={{textAlign: 'center'}}>
                                    <Button variant="success" onClick={process} disabled={isProcessing}>Process</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={2} sm={1}></Col>
                                <Col lg={8} sm={10}>
                                    <ListGroup style={{color: 'black', overflowY: 'auto', maxHeight: '25vh'}}>
                                        {processUpdates && processUpdates.map((x, i) => (
                                            <ListGroup.Item key={i}>{x}</ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Col>
                                <Col lg={2} sm={1}></Col>
                            </Row>
                            </Fragment>
                            )}
                        </Card.Body>
                    </Card>
                    </Fragment>
                ) : <h2 style={{textAlign: 'center'}}>Please contact us to enable QuickBooks Online for your account</h2>
                }
                
            </Card.Body>
        </Card>
    )
}