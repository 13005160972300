import { baseWS } from '../base'
import { handleGetSettings, handleSaveSettings, handleAuth, handleTokenUpdate,
     handleGetTokens, handleProcessUpdate, handleProcessDone, handleGetQBVendors,
     handleGetQBAccounts, handleGetMappings } from './qbo'

const controller = `${baseWS}/qbo/v1`

export const requestType = {
    HeartBeat: 0,
    Auth: 1,
    ProcessAll: 2,
    GetMappings: 3,
    GetSettings: 4,
    SaveAllMapppings: 5,
    SaveSettings: 6,
    SaveMapping: 7,
    DeleteMapping: 8,
    GetTokens: 9,
    GetQBAccounts: 10,
    GetQBVendors: 11,

    ProcessUpdate: 100,
    TokenUpdate: 101
}




export const connectToQBOWS = (props) => {
    const token = props.AccessToken
    if (!token){
        return false
    }

    let socket = new WebSocket(controller)

    socket.onopen = () => {
        const authData = {
            RequestType: requestType.Auth,
            Data: token
        }
        socket.send(JSON.stringify(authData))
    }

    props.thisSocket = socket
    
    socket.onmessage = (e) => handleReceive(e, props)

    return { socket, props }
}

const handleReceive = (event, props) => {
    try{
        const response = JSON.parse(event.data)        
        switch (response.RequestType){
            case requestType.HeartBeat:
                return                
            case requestType.Auth:
                if (response.IsError){
                    props.notify('Error', 'Error Connecting!')
                }
                else{
                    // Start Heartbeat
                    props.heartBeat = setInterval(() => {
                        beatHeart(props)
                    }, 60000)

                    handleAuth(response, props)
                }
                break
            case requestType.GetSettings:
                handleGetSettings(response, props)
                break
            case requestType.SaveSettings:
                handleSaveSettings(response, props)
                break
            case requestType.GetTokens:
                handleGetTokens(response, props)
                break;
            case requestType.TokenUpdate:
                handleTokenUpdate(response, props)
                break
            case requestType.ProcessUpdate:
                handleProcessUpdate(response, props)
                break
            case requestType.ProcessAll:
                handleProcessDone(response, props)
                break
            case requestType.GetQBVendors:
                handleGetQBVendors(response, props)
                break
            case requestType.GetQBAccounts:
                handleGetQBAccounts(response, props)
                break
            case requestType.GetMappings:
                handleGetMappings(response, props)
                break
        }
    }
    catch (err){
        console.log(err.toString())
    }
}

const beatHeart = (props) => {
    if (!props.thisSocket || props.thisSocket.readyState !== WebSocket.OPEN){
        clearInterval(props.heartBeat)
    }
    else{
        props.thisSocket.send(JSON.stringify({
            RequestType: requestType.HeartBeat
        }))
    }
}