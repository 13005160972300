import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { handleErrors } from '../../../api/base'
import { Container, Row, Col, Card, ListGroup, Table, Form, FormControl, Button,
     Modal, OverlayTrigger, PopoverTitle, PopoverContent, FormCheck, FormLabel,
      InputGroup, ProgressBar } from 'react-bootstrap'

import { uploadEndpoint, getAllContent } from '../../../api/user/discordmanager'
import MUIDataTable from 'mui-datatables'
import { MuiThemeProvider } from '@material-ui/core/styles'
import DarkTheme from '../../../utility/muiTableDark'



export const ManagerContent = ({notify}) => {

    const user = useSelector(state => state.user)

    const [progress, setProgress] = useState(0)
    const [showProgress, setShowProgress] = useState(false)
    const [progressDone, setProgressDone] = useState(false)

    const [content, setContent] = useState([])

    useEffect(() => {
        getContent()
    }, [])

    const handleUpload = (e) => {
        var file = e.target.files[0]

        if (file.size > 104857600){
            notify('Error', 'File Too Big. Max Size is 100 MB')
            e.target.value = ''
            return
        }

        setProgress(0)
        setProgressDone(false)
        let xhr = new XMLHttpRequest()
        if (xhr.upload){
            xhr.upload.onprogress = (x) => {
                const done = x.position || x.loaded
                const total = x.totalSize || x.total
                setProgress(Math.floor(done / total * 1000) / 10)
            }
        }
        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE){
                setProgressDone(true)
                getContent()
            }
        }
        let formData = new FormData()
        formData.append('uploadFile', file)

        xhr.open('POST', `${uploadEndpoint}${user.UserID}`, true)
        xhr.setRequestHeader('Authorization', 'Bearer ' + user.IdToken)
        xhr.setRequestHeader('Access', user.AccessToken)
        xhr.send(formData)
    }

    const getContent = () => {
        return getAllContent(user.UserID, user.IdToken, user.AccessToken)
            .then(handleErrors)
            .then(results => {
                if (!results){
                    notify('Error', 'Error Getting Content')
                }
                setContent(results)
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
            })
    }


    const columns = [
        { name: 'RequestID', label: 'Request ID' },
        { name: 'Name', label: 'Name' },
        { name: 'Created', label: 'Created'},
        { name: 'Active', label: 'Active' }
    ]

    const tableOpts = {
        selectableRows: 'none',
        rowsPerPage: 25,
        rowsPerPageOptions: [10, 25, 50, 100],
        filterType: 'multiselect',
    }

    return (
        <Card bg="dark" style={{color: 'white'}}>
            <Card.Header>
                <b>Manage Content</b>
            </Card.Header>
            <Card.Body>
                <Form.Control type="file" accept=".pdf" onChange={(e) => {setShowProgress(true); handleUpload(e)}} />
                {showProgress && 
                    <ProgressBar style={{marginTop: '15px'}} animated
                     striped now={progress} variant={progressDone ? 'success' : 'primary'} 
                     label={progressDone ? 'Complete' :
                        progress === 100 ? 'Processing...' : `${progress}%`
                     }
                     />
                }
                 <MuiThemeProvider theme={DarkTheme()}>
                    <MUIDataTable
                        title=""
                        columns={columns}
                        data={content}
                        options={tableOpts}
                    />
                </MuiThemeProvider>
            </Card.Body>
        </Card>
    )
}