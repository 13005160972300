import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import ourServicesBigIMG from '../../images/FeaturesLG/our_services.png'
import ourServicesIMG from '../../images/Features/our_services.png'
import fullLogo from '../../images/FullLogo.png'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export const Landing = () => {

    const isBig = useMediaQuery('(min-width:600px)');

    return (
        <div>
            <Helmet>
                <meta name="description" content="Varbis Discord Subscription Manager" />
                <title>Varbis.NET</title>
            </Helmet>
            <div style={{width: '100%', height: '55px', 
                        backgroundSize: 'contain', backgroundImage: `url(${fullLogo})`,
                        backgroundRepeat: 'no-repeat', backgroundPositionX: 'center',
                        marginBottom: '3vh'
                    }}>
            </div>
            
            <div style={{width: '100vw', display: 'flex', justifyContent: 'center'}}>
                <div style={{width: isBig ? '75vw' : '100vw'}}>
                    <img src={isBig ? ourServicesBigIMG : ourServicesIMG} alt="our services" style={{width: '100%'}} />
                </div>
            </div>
        </div>
    )
}
