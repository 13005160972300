import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Card, ListGroup, Table, Form, FormControl, Button, Modal } from 'react-bootstrap'
import { makeStyles } from '@material-ui/styles'
import { handleErrors } from '../../api/base'

import {getTestimonials} from '../../api/user/discorduser'
import Slide from '@material-ui/core/Slide'
import { utcStringToLocal } from '../../utility/timeconvert'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'

export const DiscordTestimonials = ({match, ...props}) => {

    const serverURL = match.params.server

    const [tests, setTests] = useState([])
    const [activeTest, setActiveTest] = useState(null)
    let index = 0
    let varTests = []
    useEffect(() => {
        loadTestimonials()       

        const interval = setInterval(() => {
            processChange();
        }, 13000)

        return () => clearInterval(interval)

    }, [])

    const processChange = () => {
        let startIndex = index;
        let displayIndexes = []
        while (displayIndexes.length < 5 && displayIndexes.length < varTests.length){
            displayIndexes.push(startIndex++)
            if (startIndex >= varTests.length){
                startIndex = 0
            }
        }
        setTests(varTests.map((x, i) => {
            x.Show = false
            if (displayIndexes.includes(i)){
                x.Show = true
            }
            return x
        }))
        if (index++ >= varTests.length){
            index = 0
        }
    }

    
    
    const loadTestimonials = () => {
        if (!serverURL){
            return Promise.resolve()
        }
        return getTestimonials(serverURL)
            .then(handleErrors)
            .then(results => {
                if (results){
                    setTests(results)
                    varTests = results
                    processChange()
                }
                else{
                    setTests([])
                }
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    setTests([])
                }
            })
    }

    if (!serverURL){
        return ''
    }

    return (
        <Fragment>
            <Container fluid style={{paddingTop: '20px', backgroundColor: 'white', height: '100vh', overflowX: 'hidden'}}>
                {tests.map(x => {
                    return (
                        <Slide direction="left" in={x.Show} mountOnEnter unmountOnExit timeout={{enter: 500, exit: 1000, appear: 300}}>
                            <Card style={{marginBottom: '10px', borderWidth: '2px'}}>
                                <Card.Body style={{position: 'relative'}}>
                                    <div style={{position: 'absolute', top: '0px', left: '0px'}}>
                                        <FormatQuoteIcon style={{fontSize: '60px', marginLeft: '-18px', marginTop: '-21px', transform: 'scale(-1, 1)'}} className="quoteSVG" />
                                    </div>
                                    <blockquote className="blockquote mb-0">
                                        <p style={{fontSize: '0.75em'}}>
                                            {x.Content}
                                        </p>
                                        <footer className="blockquote-footer" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                {x.Author.Avatar && <img src={`https://cdn.discordapp.com/avatars/${x.Author.ID}/${x.Author.Avatar}.png?size=64`} alt="avatar" style={{marginLeft: '15px', borderRadius: '10px'}} />}
                                                <div style={{marginLeft: '10px'}}>
                                                    <div>
                                                        {x.Author.Username}
                                                    </div>
                                                    <div>
                                                        {utcStringToLocal(x.TimeStamp)}
                                                    </div>
                                                </div>
                                        </footer>
                                    </blockquote>
                                </Card.Body>
                            </Card>
                        </Slide>
                    )
                })}
            </Container>
        </Fragment>
    )
}