import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import { roundMoney } from '../../../utility/numberFormat'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { utcStringToLocalDate } from '../../../utility/timeconvert'
import {Elements} from '@stripe/react-stripe-js'
import { CheckOutForm } from '../../stripe/checkOutForm'
import {addBusinessDays} from '../../../utility/timeconvert'
import { getCouponInfoAPI } from '../../../api/user/discorduser'
import { Container, Row, Col, Card, ListGroup, Table, Form, FormControl, Button, Modal, Nav, Badge, InputGroup, FormLabel } from 'react-bootstrap'

import TierPanel from './tierPanel'
import { useSelector } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import { fileDownload } from '../../../api/user/discorduser'
import robinhoodIMG from '../../../images/robinhood.png'
import webullIMG from '../../../images/webull.png'
import tdIMG from '../../../images/TD.png'


const useStyles = makeStyles({
    tierPanel: {
        borderColor: 'gray',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 20,
        margin: 'auto',
        marginBottom: 5,
        backgroundColor: 'gainsboro',
        height: '100%'
    },
    tierHeader: {
      padding: 20,
      borderTopLeftRadius: 18,
      borderTopRightRadius: 18,
      color: 'white',
      textShadow: '1px 1px rgba(0,0,0,0.5)',
      textAlign: 'center'
    },
    tierDetails: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: '3vh',
      textAlign: 'center',
      paddingLeft: '5px',
      paddingRight: '5px'
    },
    tierPrice: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '2vh',
        marginBottom: '2vh'
    },
})

const FREQ = {
    Daily: 0,
    Weekly: 1,
    Monthly: 2,
    Quarterly: 3,
    SemiAnnualy: 4,
    Yearly: 5,
    LifeTime: 6,
    Trial: 7
}

const getMonthlyPriceGlobal = (maxPrice, maxFreq) => {    
    switch (maxFreq){
        case FREQ.Daily:
            return roundMoney(maxPrice * 30).toFixed(2)
        case FREQ.Weekly:
            return roundMoney(maxPrice * 4.285).toFixed(2)
        case FREQ.Monthly:
            return roundMoney(maxPrice).toFixed(2)
        case FREQ.Quarterly:
            return roundMoney(maxPrice / 3).toFixed(2)
        case FREQ.SemiAnnualy:
            return roundMoney(maxPrice / 6).toFixed(2)
        case FREQ.Yearly:
            return roundMoney(maxPrice / 12).toFixed(2)
        default:
            return Number(0).toFixed(2)
    }
}

const GetFreqString = (freqNum) => {
    switch (Number(freqNum)){
        case FREQ.Daily:
            return 'Per Day'
        case FREQ.Weekly:
            return 'Per Week'
        case FREQ.Monthly:
            return 'Per Month'
        case FREQ.Quarterly:
            return 'Per 3 Months'
        case FREQ.SemiAnnualy:
            return 'Per 6 Months'
        case FREQ.Yearly:
            return 'Per Year'
        case FREQ.LifeTime:
            return 'For Life'
        default:
            return ''
    }
}

const getSubStatusFormatted = (status) => {
    switch (status){
        case 'incomplete':
            return 'Failed Payment - Will Recharge'
        case 'incomplete_expired':
            return 'Failed Multiple Payments - Will Not Recharge'
        case 'trialing':
            return 'Trial Account'
        case 'active':
            return 'Active Account'
        case 'past_due':
            return 'Payment Past Due'
        case 'canceled':
            return 'Canceled'
        case 'unpaid':
            return 'Unpaid'
        default:
            return ''
    }
}

const badSubStatus = (status) => {
    switch (status){
        case 'trialing':
        case 'active':
            return false
        case 'incomplete':
        case 'incomplete_expired':
        case 'past_due':
        case 'canceled':
        case 'unpaid':
        default:
            return true
    }
}


export const CheckOutWizard = ({props, stage}) => {

    // REFERRAL LINKS
    const robinhoodRF = 'http://join.robinhood.com/connorv1'
    const webullRF = 'https://act.webull.com/n/mXpi2zPO5oCq/0g6/'
    const tdRF = 'https://www.tdameritrade.com/'
  
    const isBig = useMediaQuery('(min-width:900px)');
    

    const getTrialEnd = () => {
        if (!props.hasSub || !props.selectedPlan){
            return ''
        }
        let localStart = utcStringToLocalDate(props.hasSub.Created)
        localStart = addBusinessDays(localStart, props.selectedPlan.TrialDays)
        return localStart.toLocaleString()
    }

    const [step, setStep] = useState(0)


    return (
        <Fragment>            
            {stage && stage === 'Has Sub Just Changing Freq' && (
                <Fragment>
                    <Form>
                        <WizardStepper stage="Billing Frequency Row" props={{priceID: props.priceID, selectedPlan: props.selectedPlan, setPriceID: props.setPriceID}} />
                        <WizardStepper stage="Coupon Row" props={{coupon: props.coupon, setCoupon: props.setCoupon, checkCoupon: props.checkCoupon, couponInfo: props.couponInfo}} />
                    </Form>                                                                
                    <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop: '50px'}}>
                        {props.hasSub.JustImported
                            ? (
                                <Tooltip interactive PopperProps={{className: 'JustImportedWarning'}} title={`You will be able to change this here after your next Billing Cycle.
                                    If you wish to update this please reachout to support in discord or email us at admin@varbis.net`}>
                                    <Button variant="secondary" style={{opacity: '0.8', cursor: 'not-allowed'}}>Apply Change</Button>
                                </Tooltip>
                            )
                            :<Button variant={props.priceID && props.priceID !== props.hasSub.PriceID ? 'success' : 'danger'} onClick={() => {props.setAuthorizeFunction(() => props.updateSubscription); props.setShowAuthorize(true)}} disabled={!props.priceID || props.priceID == props.hasSub.PriceID}>Apply Change</Button>
                        }
                        <Button variant="secondary" onClick={() => props.setShowFreq(false)}>Choose Different Plan</Button>
                    </div>
                    <Row style={{marginTop: '15px', fontSize: '12px'}}>
                        <Col sm={3}></Col>
                        <Col sm className="prorate-legal">
                            <ProrateLanguage />
                        </Col>
                    </Row>
                </Fragment>
            )}
            {stage && stage === 'Has Sub Show All Options' && (
                <Fragment>
                    <div style={{marginBottom: '40px', display: 'flex', flexDirection: 'column'}}>
                        <h3>Status: {getSubStatusFormatted(props.hasSub.SubscriptionStatus)}</h3>
                        {props.hasSub.SubscriptionStatus === 'trialing' && (<h4>Will End at {getTrialEnd()}</h4>)}
                        {props.hasSub.CardHolder && props.activeFreq && (<h4>{!props.activeFreq.Enabled && '[Legacy] '}${props.activeFreq.Cost.toFixed(2)} Per {GetFreqString(props.activeFreq.Frequency)}</h4>)}
                        {props.hasSub.WillCancel && (<h4 style={{marginTop: '10px'}}>Will Cancel At The End of the Period</h4>)}
                    </div>
                    <Row style={{display: 'flex', justifyContent: 'space-evenly', width: '100%'}}>
                        {props.hasSub.CardHolder 
                            ? (props.activeFreq && props.activeFreq.Frequency === 6 && props.hasSub.SubscriptionStatus === 'active'
                                ? (
                                    <Fragment>
                                        <Col xs={12}>
                                            <h1 className="LifeTimeThankYou">Thank You For Being A Lifetime Member!</h1>
                                        </Col>
                                        <Col sm={5} lg={3} style={{margin: '10px'}}>
                                            <Button variant="secondary" onClick={() => props.setViewContent(true)}>View Content</Button>
                                        </Col>
                                        <Col sm={5} lg={3} style={{margin: '10px'}}>
                                            <Button variant="secondary" onClick={() => props.setShowPhoneLink(true)}>Update Phone</Button>
                                        </Col>
                                        <Col sm={5} lg={3} style={{margin: '10px'}}>
                                            {props.dUser && props.dUser.User && props.dUser.User.PhoneVerified && props.hasSub && props.hasSub.AllowNotifications
                                                ? <Button variant="secondary" onClick={() => props.setChangeNotifications(true)}>Notification Settings</Button>
                                                : <Tooltip  PopperProps={{className: 'JustImportedWarning'}} title="Verify Phone And Allow Notifications First">
                                                    <Button variant="secondary" style={{opacity: '0.7'}}>Notification Settings</Button>
                                                </Tooltip>
                                            }
                                        </Col>
                                    </Fragment>
                                )
                                : (
                                    <Fragment>
                                            {!badSubStatus(props.hasSub.SubscriptionStatus) && (
                                                <Fragment>
                                                    <Col sm={5} lg={3} style={{margin: '10px'}}>
                                                        <Button variant="secondary" onClick={props.handleChangePlan}>Change Plan</Button> 
                                                    </Col>
                                                    <Col sm={5} lg={3} style={{margin: '10px'}}>
                                                        <Button variant="secondary" onClick={() => props.setShowFreq(true)}>Change Frequency</Button>
                                                    </Col>
                                                </Fragment>
                                            )}                                            
                                            <Col sm={5} lg={3} style={{margin: '10px'}}>
                                                <Button variant="secondary" onClick={() => props.setChangePayment(true)}>{props.hasSub.CardHolder ? 'Update' : 'Add'} Payment Method</Button>
                                            </Col>
                                            {!badSubStatus(props.hasSub.SubscriptionStatus) && (
                                                <Col sm={5} lg={3} style={{margin: '10px'}}>
                                                    <Button variant="secondary" onClick={() => props.setViewContent(true)}>View Content</Button>
                                                </Col>
                                            )}
                                            <Col sm={5} lg={3} style={{margin: '10px'}}>
                                                <Button variant="secondary" onClick={() => props.setShowPhoneLink(true)}>Update Phone</Button>
                                            </Col>
                                            <Col sm={5} lg={3} style={{margin: '10px'}}>
                                                {props.dUser && props.dUser.User && props.dUser.User.PhoneVerified && props.hasSub && props.hasSub.AllowNotifications
                                                    ? props.hasSub.SubscriptionStatus === 'trialing'
                                                        ?   <Tooltip  PopperProps={{className: 'JustImportedWarning'}} title="Please purchase to unlock notifications">
                                                                <Button variant="secondary" style={{opacity: '0.7'}}>Notification Settings</Button>
                                                            </Tooltip>
                                                        : <Button variant="secondary" onClick={() => props.setChangeNotifications(true)}>Notification Settings</Button>
                                                    : <Tooltip  PopperProps={{className: 'JustImportedWarning'}} title="Verify Phone And Allow Notifications First">
                                                        <Button variant="secondary" style={{opacity: '0.7'}}>Notification Settings</Button>
                                                    </Tooltip>
                                                }
                                            </Col>  
                                            {props.hasSub.SubscriptionStatus === 'trialing' && (
                                                <Col sm={5} lg={3} style={{margin: '10px'}}>
                                                    <Button variant="success" onClick={() => props.setShowEndTrialModal(true)}>End Trial Now</Button>
                                                </Col>
                                            )}                                          
                                            
                                    </Fragment>
                                )
                            )
                            : (
                                <Fragment>
                                    <Col sm={5} lg={3} style={{margin: '10px'}}>
                                        <Button variant="success" onClick={() => props.handleChangePlan()}>Start a Different Plan</Button>
                                    </Col>
                                    <Col sm={5} lg={3} style={{margin: '10px'}}>
                                        <Button variant="primary" onClick={() => props.setContinuePlan(true)}>Start {props.selectedPlan.Name} Membership</Button>
                                    </Col>
                                </Fragment>
                            )
                        }
                        {(props.isDone || !props.prepopPlanID) && (!props.activeFreq || props.activeFreq.Frequency !== 6) && (
                            !props.hasSub.WillCancel
                            ? (
                                <Col sm={5} lg={3} style={{margin: '10px'}}>
                                    <Button variant="danger" onClick={() => props.setShowCancelModal(true)}>Cancel Subscription</Button>
                                </Col>
                            )
                            : (
                                <Col sm={5} lg={3} style={{margin: '10px'}}>
                                    <Button variant="success" onClick={() => props.resumeSubscription()}>Resume Subscription</Button>
                                </Col>
                            )
                        )}
                    </Row>
                    {props.isDone && props.serverURL === 'slapstocks' && (
                        <Row>
                            <Col style={{textAlign: 'left'}}>
                                <p style={{marginTop: '15px'}}><b>You Are All Set!</b></p>
                                <p>Welcome and Thank You for joining the Slap Stocks! We are excited to to have you as part of our family.</p>
                                <p>If you have a selected a Tier 3 (T3) plan, you will see a blue button to your right, click on that to schedule your one-on-one session with our highly knowledgable and profitable educators. After you complete scheduling your one-on-one session, hop back over to discord and start enjoying all the benefits of your membership.</p>
                                <p>If you have a selected a Tier 2 (T2) plan, go ahead and log into our discord server and spend some time looking around. I am sure you are going to want to upgrade to a tier 3 plan right away, after all, it practically pays for itself! When you are ready to to upgrade, you come right back to this site and complete your upgrade to T3.</p>
                                <p>Now jump back over to discord and enjoy all the valuable content our group has to offer.</p>
                                <p>In Service, <br />
                                Cyrus <br />
                                <i>Founder</i><br />
                                <b>Slap Stocks</b><br />
                                </p>
                            </Col>
                        </Row>
                    )}
                    {props.isDone && props.serverURL === 'testserver' && (
                        <Row>
                            <Col style={{textAlign: 'left'}}>
                                <p style={{marginTop: '15px'}}><b>Your Welcome Message Goes Here</b></p>
                            </Col>
                        </Row>
                    )}
                </Fragment>
            )}
            {stage && stage === 'Has Sub Just Changing Payment' && (
                <WizardStepper stage="Payment Information No Trial" props={{stripePromise: props.stripePromise, setPaymentMethod: props.setPaymentMethod, updateCustomerPayment: props.updateCustomerPayment, paymentMethod: props.paymentMethod, setChangePayment: props.setChangePayment, allowBack: true}} />
            )}
            {stage && stage === 'Has Sub Just Changing Notifications' && (
                <Fragment>  
                    <div style={{marginBottom: '20px'}} className="CouponInfo"> 
                        <h2>SMS Notification Channels</h2>
                    </div>
                    <Row style={{textAlign: 'left'}}>
                        {props.selectedPlan && props.selectedPlan.SubscribeChannels && props.selectedPlan.SubscribeChannels.map((x, i) => {
                            const match = props.selectedSubscribes.filter(z => z.ChannelID == x.ChannelID)[0]
                            return (
                                <Col sm={12} lg={5}>
                                    <Form.Check type="switch" 
                                        id={`notChannel${i}`} label={x.Name} 
                                        onChange={(e) => props.handleChangeSubscribeChannel(x.ChannelID, x.Name, e.target.checked)} 
                                        checked={match}
                                    />
                                </Col>
                            )
                        })}
                    </Row>                                                                                
                    <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop: '50px'}}>
                        <Button variant="success" onClick={() => props.saveChannelSubscribes()}>Apply Change</Button>
                        <Button variant="secondary" onClick={() => props.setChangeNotifications(false)}>Back</Button>
                    </div>
                </Fragment>
            )}
            {stage && stage === 'Has Sub Just Viewing Content' && (
                <Fragment>   
                    <div style={{marginBottom: '20px'}} className="CouponInfo"> 
                        <h2>Available Content</h2>
                    </div>
                    <Row style={{testAlign: 'left'}}>
                        {props.selectedPlan && props.selectedPlan.DiscordContents.map(x => {
                            return (
                                <Col sm={12} lg={5}>
                                    <Form.Label>
                                        <a href={`${fileDownload}/${props.hasSub.DiscordUserID}/${x.RequestID}`} target="_blank" download>{x.Name}</a>
                                    </Form.Label>
                                </Col>
                            )
                        })}
                    </Row>                                                                                
                    <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop: '50px'}}>
                        <Button variant="secondary" onClick={() => props.setViewContent(false)}>Back</Button>
                    </div>
                </Fragment>
            )}
            {stage && stage === "Has Sub Looking At New Plan" && (
                <Fragment>
                    {(step === 0 || props.hasSub.CardHolder) && (
                        <Form style={{marginTop: '30px'}}>
                            <WizardStepper stage="Billing Frequency Row" props={{priceID: props.priceID, selectedPlan: props.selectedPlan, setPriceID: props.setPriceID}} />
                            <WizardStepper stage="Coupon Row" props={{coupon: props.coupon, setCoupon: props.setCoupon, checkCoupon: props.checkCoupon, couponInfo: props.couponInfo}} />
                        </Form>
                    )}
                    {step === 1 && !props.hasSub.CardHolder && (
                        !props.paymentMethod
                        ? <WizardStepper stage="Payment Information No Trial" props={{stripePromise: props.stripePromise, setPaymentMethod: props.setPaymentMethod, updateCustomerPayment: props.updateCustomerPayment, paymentMethod: props.paymentMethod, setChangePayment: props.setChangePayment, allowBack: false}} />
                        : <WizardStepper stage="Payment Information On File" props={{setPaymentMethod: props.setPaymentMethod}} />
                    )}

                    <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop: '50px'}}>
                        {((step === 0 && props.hasSub.CardHolder) || props.paymentMethod) && (
                            props.hasSub.JustImported
                                ? (
                                    <Tooltip interactive PopperProps={{className: 'JustImportedWarning'}} title={`You will be able to change this here after your next Billing Cycle.
                                        If you wish to update this please reachout to support in discord or email us at admin@varbis.net`}>
                                        <Button variant="secondary" style={{opacity: '0.8', cursor: 'not-allowed'}}>Update Subscription</Button>
                                    </Tooltip>
                                )
                                : <Button variant="success" onClick={() => {props.setAuthorizeFunction(() => props.updateSubscription); props.setShowAuthorize(true)}}>Update Subscription</Button>
                            )
                        }
                        {step === 0 && !props.hasSub.CardHolder && <Button variant="success" onClick={() => setStep(step + 1)}>Enter Payment Information</Button>}
                        {step > 0 && <Button onClick={() => setStep(step - 1)}>Back</Button>}
                        <Button variant="secondary" onClick={props.handleChangePlan} style={{marginLeft: '5px'}}>Choose Different Plan</Button>
                    </div>
                    <Row style={{marginTop: '15px', fontSize: '12px'}}>
                        <Col sm={3}></Col>
                        <Col sm className="prorate-legal">
                           <ProrateLanguage />
                        </Col>
                    </Row>
                </Fragment>
            )}
            {stage && stage === 'Has Sub but doesnt have selected plan' && (
                <Row style={{color: 'black', justifyContent: 'center'}}>
                    {props.accountData.DiscordStripePlans && props.accountData.DiscordStripePlans.filter(x => x.Enabled).map(x => {
                        return (
                            <Col sm={6} lg={4} style={{marginBottom: '5px'}}>
                                <TierPanel data={x} select={props.handlePlanSelect} hasSub={props.hasSub} />
                            </Col>
                        )
                    })}
                </Row>
            )}
            {stage && stage === 'No Sub and No Selected Plan' && (
                <Row style={{color: 'black', justifyContent: 'center'}}>
                    {props.accountData.DiscordStripePlans && props.accountData.DiscordStripePlans.filter(x => x.Enabled).map(x => {
                        return (
                            <Col sm={6} lg={4} style={{marginBottom: '5px'}}>
                                <TierPanel data={x} select={props.handlePlanSelect} hasSub={props.hasSub} hideTrial={props.refFromURL} />
                            </Col>
                        )
                    })}
                </Row>
            )}
            {stage && stage === 'No Sub, Has Promo, No Prepop Tier, Has Frequency, Finished Signing Up For Promo' && (
                <Row style={{color: 'black', paddingBottom: '30px'}}>
                    <Col sm={6} lg={4} style={{marginBottom: '5px'}}>
                        <TierPanel data={props.selectedPlan} hidePrice={true} />
                    </Col>
                    <Col style={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', flexDirection: 'column', color: 'white'}}>
                        <h3 style={{marginBottom: '10px'}}>Congratulations!</h3>
                        <h4 style={{marginBottom: '20px'}}>You Have Just Been Granted Full Access to Our Discord Server. Click the Proceed to Discord Button Below to Jump In and Start Accessing Our Highly Valuable Content Today!</h4>
                        <Button onClick={() => {window.open('https://discord.com/app')}}>
                            Proceed to Discord
                        </Button>
                        <div style={{marginTop: '25px'}}>
                            <h2 className="CouponInfo">New to Trading?</h2>
                            <Row>
                                <Col sm={12} lg={4}>
                                    <img style={{width: '100%', cursor: 'pointer'}} src={robinhoodIMG} onClick={() => {window.open(robinhoodRF)}} />
                                </Col>
                                <Col sm={12} lg={4}>
                                    <img style={{width: '100%', cursor: 'pointer'}} src={webullIMG} onClick={() => {window.open(webullRF)}} />
                                </Col>                                
                                <Col sm={12} lg={4}>
                                    <img style={{width: '100%', cursor: 'pointer'}} src={tdIMG} onClick={() => {window.open(tdRF)}} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            )}
            {stage && stage === 'No Sub, Has Promo, No Prepop Tier, Has Frequency, Just Needs To Hit Start' && (
                <Row style={{color: 'black', paddingBottom: '30px'}}>
                    <Col sm={6} lg={4} style={{marginBottom: '5px'}}>
                        <TierPanel data={props.selectedPlan} hidePrice={true} />
                    </Col>
                    <Col style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Button variant="success" onClick={() => {props.startSub(true)}}>Start Your Free Trial</Button>
                    </Col>
                </Row>
            )}
            {stage && stage === 'No Sub, Has Promo, No Prepop Tier, Has Frequency, Needs Payment' && (
                <Row style={{color: 'black', paddingBottom: '30px'}}>
                    <Col sm={6} lg={4} style={{marginBottom: '5px'}}>
                        <TierPanel data={props.selectedPlan} hidePrice={false} />
                    </Col>
                    <Col style={{color: 'white'}}>
                        <Row>
                            <Col>
                                <Form>
                                    <WizardStepper stage="Billing Frequency Row" props={{priceID: props.priceID, selectedPlan: props.selectedPlan, setPriceID: props.setPriceID}} />
                                    {props.paymentMethod 
                                        ? <WizardStepper stage="Payment Information On File" props={{setPaymentMethod: props.setPaymentMethod}} />
                                        : <WizardStepper stage="No Sub Payment Information" props={{selectedPlan: props.selectedPlan, stripePromise: props.stripePromise, setPaymentMethod: props.setPaymentMethod}} />
                                    }
                                </Form>
                            </Col>
                        </Row>
                        {props.selectedPlan.DontCancelTrial && (
                            <Row style={{marginTop: '10px'}}>
                                <Col className="prorate-legal">
                                    By clicking Start Your Free Trial you are agreeing that unless canceled, your card will be charged at the end of the trial period.
                                </Col>
                            </Row>
                        )}
                        <Row style={{marginTop: '25px'}}>
                            <Col style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Button variant="success" onClick={() => {props.startSub()}} disabled={!props.paymentMethod}>Start Your Free Trial</Button>
                            </Col>
                        </Row>
                    </Col>                    
                </Row>
            )}
            {stage && stage === 'No Sub, No Promo, Selected A Plan' && (
                <Row style={{color: 'black', paddingBottom: '30px'}}>
                <Col sm={6} lg={4} style={{marginBottom: '5px'}}>
                    <TierPanel data={props.selectedPlan} hasSub={props.hasSub} hideTrial={props.ref} />
                </Col>
                <Col style={{color: 'white'}}>
                    <Row>
                        <Col>
                            <Form>
                                <WizardStepper stage="Billing Frequency Row" props={{priceID: props.priceID, selectedPlan: props.selectedPlan, setPriceID: props.setPriceID}} />
                                <WizardStepper stage="Coupon Row" props={{coupon: props.coupon, setCoupon: props.setCoupon, checkCoupon: props.checkCoupon, couponInfo: props.couponInfo}} />
                                {!props.refFromURL && 
                                    <WizardStepper stage="Referral Code Row" props={{referral: props.referral, setReferral: props.setReferral}} />
                                }
                                <Row>
                                    <Col></Col>
                                    <Col sm={10} lg={6}>
                                        {!props.selectedPlan.HasTrial || props.selectedPlan.TrialRequiresPayment || props.trialOverride || props.hasSub || props.refFromURL 
                                            ? (props.paymentMethod 
                                                ? <WizardStepper stage="Payment Information On File" props={{setPaymentMethod: props.setPaymentMethod}} />
                                                : <WizardStepper stage="No Sub Payment Information" props={{stripePromise: props.stripePromise, setPaymentMethod: props.setPaymentMethod, selectedPlan: props.selectedPlan, trialOverride: props.trialOverride, setTrialOverride: props.setTrialOverride}} />
                                            ) 
                                            : <WizardStepper stage="Trial No Payment Required" props={{setTrialOverride: props.setTrialOverride}} />
                                        } 
                                    </Col>
                                    <Col></Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '25px'}}>                        
                        <Col sm>
                            <Row style={{display: 'flex', justifyContent: 'space-between'}}>
                                <span></span>
                                {!props.paymentMethod 
                                    ? (props.selectedPlan.HasTrial && !props.trialOverride && !props.hasSub && !props.refFromURL 
                                        ? <Button variant="success" onClick={() => props.startSub(true)}>Start Trial</Button>
                                        : <span></span>
                                    )
                                    : <Button variant="success" onClick={() => {props.setAuthorizeFunction(() => props.startSub); props.setShowAuthorize(true)}}>Start Subscription</Button>
                                    }
                                <Button onClick={() => {props.setSelectedPlan(null)}}>Choose Different Plan</Button>
                            </Row>
                        </Col>
                        {(!props.selectedPlan.HasTrial || props.selectedPlan.TrialRequiresPayment || props.trialOverride || props.hasSub) &&
                            <Col sm={6} className="prorate-legal">
                                <ProrateLanguage />
                            </Col>
                        }
                    </Row>
                </Col>
            </Row>
            )}
        </Fragment>
    )
}

const ProrateLanguage = () => {
    return (
        <i>**We prorate subscription changes. For example, if a customer signs up on June 1 for a $100 plan, she'll be billed $100 immediately.
        If on June 15 she switches to a $200 plan, then on June 15th she will be billed again for the full time on the $200 plan minus
        the unused time on the $100 plan (%50 is $50) and the billing cycle will be reset. Resulting in a charge of $150 on June 15th and a $200 charge on July 15th. Downgrades are processed the
        same way, but result in a credit that will be applied to your next billing cycle. Downgrades and cancelations do not result in a refund.</i>
    )
}


const WizardStepper = ({props, stage}) => {

    const isBig = useMediaQuery('(min-width:900px)')

    return (
        <Fragment>
            {stage && stage === 'Billing Frequency Row' && (
                <Form.Group as={Row}>
                    <Col sm={0} lg={1}></Col>
                    <Form.Label column sm={12} lg={3} style={{textAlign: isBig ? 'end' : 'left'}}>
                        Select Billing Cycle
                    </Form.Label>
                    <Col sm={12} lg={6}>
                        <Form.Control as="select" value={props.priceID} onChange={(e) => props.setPriceID(e.target.value)}>
                            {props.selectedPlan.DiscordStripePlanFrequencies.filter(x => x.Enabled).sort((a, b) => (b.Cost < a.Cost) ? 1 : -1).map(x => {

                                let displayString = `$${x.Cost} ${GetFreqString(x.Frequency)}`
                                if (x.Frequency !== FREQ.Monthly && x.Frequency !== FREQ.LifeTime){
                                    displayString += ` - Approx $${getMonthlyPriceGlobal(x.Cost, x.Frequency)} Per Month`
                                }
                                return (
                                    <option value={`${x.PriceID}`}>{displayString}</option>
                                )
                            })}
                        </Form.Control>
                    </Col>
                </Form.Group>
            )}
            {stage && stage === 'Coupon Row' && (
                <Fragment>
                    <Form.Group as={Row}>
                        <Col sm={0} lg={1}></Col>
                        <Form.Label column sm={12} lg={3} style={{textAlign: isBig ? 'end' : 'left'}}>
                            Promo Code
                        </Form.Label>
                        <Col sm={12} lg={6}>
                            <InputGroup>
                                <Form.Control placeholder="(optional)" value={props.coupon || ''} onChange={(e) => props.setCoupon(e.target.value)} />
                                <InputGroup.Append>
                                    <Button className="CouponCheck" onClick={props.checkCoupon} disabled={!props.coupon}>
                                        Check Coupon
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    {props.couponInfo && (
                        <div style={{display: 'flex', justifyContent: 'space-evenly', marginBottom: '1rem'}} className="CouponInfo">
                            <span style={{color: props.couponInfo.Valid ? 'green' : 'red'}}>
                                <b>{props.couponInfo.Valid ? 'Valid' : 'Invalid'}</b>
                            </span>
                            {props.couponInfo.Name && (
                                <span>
                                    Name : {props.couponInfo.Name}
                                </span>
                            )}
                            {props.couponInfo.PercentOff && (
                                <span>
                                    {props.couponInfo.PercentOff}% Off
                                </span>
                            )}
                            {Boolean(props.couponInfo.AmountOff) && (
                                <span>
                                    ${props.couponInfo.AmountOff} Off
                                </span>
                            )}
                        </div>
                    )}
                </Fragment>
            )}
            {stage && stage === 'Referral Code Row' && (
                <Form.Group as={Row}>
                <Col sm={0} lg={1}></Col>
                <Form.Label column sm={12} lg={3} style={{textAlign: isBig ? 'end' : 'left'}}>
                    Referral Code
                </Form.Label>
                <Col sm={12} lg={6}>
                    <Form.Control placeholder="(optional)" value={props.referral} onChange={(e) => props.setReferral(e.target.value)} />
                </Col>
            </Form.Group>
            )}
            {stage && stage === 'Payment Information On File' && (
                <div style={{display: 'flex', flexDirection: 'column', marginTop: '30px'}}>
                    <h3 style={{textAlign: 'center'}}>Payment Information On File!</h3>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                        <Button variant="danger" onClick={() => props.setPaymentMethod(null)}>Change Payment</Button>
                    </div>
                </div>
            )}
            {stage && stage === 'No Sub Payment Information' && (
                <Fragment>
                    <Elements stripe={props.stripePromise}>
                        <CheckOutForm setPaymentMethod={props.setPaymentMethod} />
                    </Elements>
                    {props.selectedPlan.HasTrial && !props.selectedPlan.TrialRequiresPayment && props.trialOverride && 
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                        <Button onClick={() => props.setTrialOverride(false)}>Enter payment information later</Button>
                    </div>}
                </Fragment>
            )}
            {stage && stage === 'Trial No Payment Required' && (
                <div style={{display: 'flex', flexDirection: 'column', marginTop: '30px'}}>
                    <h3 style={{textAlign: 'center'}}>No Payment Information Required</h3>
                    <p style={{marginTop: '30px', textAlign: 'center'}}>You can choose to skip the trial and purchase instead</p>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                        <Button variant="success" onClick={() => props.setTrialOverride(true)}>Add Payment To Purchase</Button>
                    </div>
                </div>
            )}
            {stage && stage === 'Payment Information No Trial' && (
                <Fragment>
                    <Elements stripe={props.stripePromise}>
                        <CheckOutForm setPaymentMethod={props.setPaymentMethod} />
                    </Elements>
                    {props.paymentMethod && props.updateCustomerPayment && (
                        <Button variant="success" onClick={props.updateCustomerPayment}>Save Payment Method</Button>
                    )}
                    {props.allowBack && (
                        <Row style={{marginTop: '10px'}}>
                            <Col>
                                <Button variant="secondary" onClick={() => props.setChangePayment(false)}>Back</Button>
                            </Col>
                        </Row>
                    )}
                </Fragment>
            )}
        </Fragment>
    )
}

export default CheckOutWizard