import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import { roundMoney } from '../../../utility/numberFormat'
import { Container, Row, Col, Card, ListGroup, Table, Form, FormControl, Button, Modal, Nav, Badge } from 'react-bootstrap'


const useStyles = makeStyles({
    tierPanel: {
        borderColor: 'gray',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 20,
        margin: 'auto',
        marginBottom: 5,
        backgroundColor: 'gainsboro',
        height: '100%'
    },
    tierHeader: {
      padding: 20,
      borderTopLeftRadius: 18,
      borderTopRightRadius: 18,
      color: 'white',
      textShadow: '1px 1px rgba(0,0,0,0.5)',
      textAlign: 'center'
    },
    tierDetails: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: '3vh',
      textAlign: 'center',
      paddingLeft: '5px',
      paddingRight: '5px'
    },
    tierPrice: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '2vh',
        marginBottom: '2vh'
    },
    tierPricePlan: {
        fontSize: '1rem',
    }
})

export const TierPanel = ({data, select, hidePrice, hasSub, hideTrial}) => {
    const x = data
    const classes = useStyles()

    const FREQ = {
        Daily: 0,
        Weekly: 1,
        Monthly: 2,
        Quarterly: 3,
        SemiAnnualy: 4,
        Yearly: 5,
        LifeTime: 6
    }

    const getMonthlyPrice = (x) => {
        let maxFreq = Math.max.apply(Math, x.DiscordStripePlanFrequencies.filter(x => x.Enabled && x.Frequency !== FREQ.LifeTime).map(o => o.Frequency))
        let maxPrice = x.DiscordStripePlanFrequencies.filter(y => y.Frequency == maxFreq)[0]
        const maxCost = maxPrice ? maxPrice.Cost : 0
        const retObj = {
            price: Number(0).toFixed(2),
            freq: maxPrice && maxPrice.Frequency
        }
        switch (maxPrice && maxPrice.Frequency){
            case FREQ.Daily:
                retObj.price = roundMoney(maxCost * 30).toFixed(2)
                break
            case FREQ.Weekly:
                retObj.price = roundMoney(maxCost * 4.285).toFixed(2)
                break
            case FREQ.Monthly:
                retObj.price = roundMoney(maxCost).toFixed(2)
                break
            case FREQ.Quarterly:
                retObj.price = roundMoney(maxCost / 3).toFixed(2)
                break
            case FREQ.SemiAnnualy:
                retObj.price = roundMoney(maxCost / 6).toFixed(2)
                break
            case FREQ.Yearly:
                retObj.price = roundMoney(maxCost / 12).toFixed(2)
                break
        }
        return retObj
    }

    const GetFreqName = (freqNum) => {
        switch (Number(freqNum)){
            case FREQ.Daily:
                return 'Daily'
            case FREQ.Weekly:
                return 'Weekly'
            case FREQ.Monthly:
                return 'Monthly'
            case FREQ.Quarterly:
                return 'Quarterly'
            case FREQ.SemiAnnualy:
                return 'Semiannualy'
            case FREQ.Yearly:
                return 'Yearly'
        }
    }
    
    const moPrice = getMonthlyPrice(x)

    return (
        <div  className={`${classes.tierPanel} tierPanel`}>
            <h4 className={classes.tierHeader} style={{ backgroundImage: `linear-gradient(to bottom, ${x.TopColor}, ${x.BottomColor})`}}>
                {!x.Enabled && '[Legacy] '}{x.Name}
            </h4>
            <div className={classes.tierDetails}>
                <p style={{whiteSpace: 'pre-line'}}>{x.Description}</p>
                <br />
                {x.RoleName && (
                    <Fragment>
                        <div style={{fontSize: '1.2rem'}}>
                            <b>Discord Role: {x.RoleName}</b>
                        </div>
                        <br />
                    </Fragment>
                )}
                {x.HasTrial && !hasSub && !hideTrial && (
                    <Fragment>
                        <div style={{fontSize: '1.2rem'}}>
                            <b>{x.TrialDays} Day Free Trial!</b>
                        </div>
                        {!x.TrialRequiresPayment && (
                            <div>No Payment Information Required!</div>
                        )}
                    </Fragment>
                )}
            </div>
            {!hidePrice && x.DiscordStripePlanFrequencies.filter(x => x.Enabled).length > 0 && (
                <div className={classes.tierPrice} style={{fontSize: '1.4rem'}}>
                    {x.DiscordStripePlanFrequencies.filter(x => x.Enabled).length === 1 && x.DiscordStripePlanFrequencies.filter(x => x.Enabled)[0].Frequency === FREQ.LifeTime 
                    ? <span>${roundMoney(x.DiscordStripePlanFrequencies.filter(x => x.Enabled)[0].Cost).toFixed(2)} For Life</span>
                    : Boolean(Number(moPrice.price))
                        ? <Row>
                            <Col md={12} style={{textAlign: 'center'}}><span>As Low As ${moPrice.price}/Month</span></Col>
                            {moPrice.freq !== FREQ.Monthly && moPrice.freq !== FREQ.LifeTime && (
                                <Col md={12} style={{textAlign: 'center'}}><span className={classes.tierPricePlan}>&nbsp;&nbsp;(On {GetFreqName(moPrice.freq)} Plan)</span></Col>
                            )}
                            </Row>
                        : ''
                    }
                    
                </div>
            )}
            { select && (
                <div className={classes.tierPrice}>
                    <Button size="lg" onClick={() => {select(x)}}>Select</Button>
                </div>
            )}
        </div>
    )
}

export default TierPanel