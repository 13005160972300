
import { MuiThemeProvider } from '@material-ui/core/styles'
import DarkTheme from '../../utility/muiTableDark'

import React, { useState, useEffect, useCallback, Fragment, useRef } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Card, ListGroup, Table, Form, FormControl, Button, Modal, OverlayTrigger, PopoverTitle, PopoverContent, FormCheck, FormLabel, InputGroup } from 'react-bootstrap'
import { makeStyles } from '@material-ui/styles'
import { handleErrors } from '../../api/base'
import { formatNegative, zeroIfNull } from '../../utility/numberFormat'
import { Popover, FormGroup } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import MUIDataTable from 'mui-datatables'
import CreateIcon from '@material-ui/icons/Create'
import { setUserCanManageAPI, getReferralCodeAPI, setUserManagerAPI } from '../../api/user/discordmanager'
import { utcStringToLocal, utcStringToLocalDate } from '../../utility/timeconvert'
// import DatePicker from '../datepicker/datepicker'
import  DatePicker  from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {copyTextToClipboard} from '../../utility/commands'



const useStyles = makeStyles({
    fullCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    leftFlex: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center'
    }
})

const FREQ = {
    Daily: 0,
    Weekly: 1,
    Monthly: 2,
    Quarterly: 3,
    SemiAnnualy: 4,
    Yearly: 5,
    LifeTime: 6
}

const getFreqString = (freqNum) => {
    switch (Number(freqNum)){
        case FREQ.Daily:
            return 'Day'
        case FREQ.Weekly:
            return 'Week'
        case FREQ.Monthly:
            return 'Month'
        case FREQ.Quarterly:
            return '3 Months'
        case FREQ.SemiAnnualy:
            return '6 Months'
        case FREQ.Yearly:
            return 'Year'
        case FREQ.LifeTime:
            return 'Lifetime'
        default:
            return ''
    }
}

export const DiscordManagerUsers = ({users, notify, owner, refresh}) => {
    const dmState = useSelector(state => state.discordManager)
    const user = useSelector(state => state.user)
    
    const classes = useStyles()

    const [userState, setUserState] = useState(users)
    const [filteredState, setFilteredState] = useState(users)

    
    let now = new Date()
    let end = new Date()
    end.setDate(end.getDate() - 1)
    const [dateFilter, setDateFilter] = useState('1')
    const [dateEnd, setDateEnd] = useState(now)
    const [dateStart, setDateStart] = useState(end)
    const [generatedURL, setGeneratedURL] = useState('')
    const [urlTier, setUrlTier] = useState('')
    const [urlCode, setUrlCode] = useState('')
    const genFieldRef = useRef(null)

    const [whichManager, setWhichManager] = useState(null)

    useEffect(() => {
        setUserState(users)
        handleFilters(users)
    }, [users])

    const columns = [
        { name: 'CustomerID', options: {
            display: 'false',
            download: false,
            filter: false,
            viewColumns: false
        }},
        { name: 'DiscordAccountID', options: {
            display: 'false',
            download: false,
            filter: false,
            viewColumns: false
        }},
        { name: 'DiscordUserID', options: {
            display: 'false',
            download: false,
            filter: false,
            viewColumns: false
        }},
        { name: 'Name', label: 'User',
            options: {
                customBodyRender: (value, tableMeta) => {
                    return <a href={`https://dashboard.stripe.com/customers/${tableMeta.rowData[0]}`} target="__blank">{value}</a>
                },
                filter: false
            }
        },
        { name: 'Email', label: 'Email',
            options: {
                filter: false
            }
        },
        { name: 'CardHolder', label: 'Card Holder',
            options: {
                filter: false
            } 
        },
        { name: 'SubscriptionStatus', label: 'Status'},
        { name: 'WillCancel', label: 'Canceled',
            options: {
                customBodyRender: (value) => {
                    return value ? 'Yes' : ''
                },
                filterOptions: {
                    renderValue: (v) => v ? 'Yes' : 'No'
                },
                customFilterListOptions: {
                    render: (v) => v ? 'Yes': 'No'
                }
            }    
        },
        { name: 'Plan', label: 'Plan'
        },
        { name: 'Tier', label: 'Tier' },
        { name: 'PromoCode', label: 'Promo Code',
            options: {
                filterOptions: {
                    renderValue: (v) => v || 'No Promo'
                }
            }
        },
        { name: 'ReferralCode', label: 'Referral Code',
            options: {
                customBodyRender: (value, tableMeta) => {
                    return value ? <span>{value}</span> 
                    : (owner ? <CreateIcon onClick={(e) => handleClick(e, 'RefCode', tableMeta.rowData[2])} /> : '')
                }
            }
        },
        { name: 'ReferredBy', label: 'Referred By',
            options: {
                filterOptions: {
                    renderValue: (v) => v || 'Not Referred'
                },
                customFilterListOptions: {
                    render: (v) => v || 'Not Referred'
                }
            }
        },
        { name: 'ReferralCount', label: 'Referral Count',
            options: {
                filter: false
            }
        },
        { name: 'CanManageOthers', label: 'Can Manage',
            options: {
                customBodyRender: (value, tableMeta) => {
                    return owner ? <FormCheck checked={value} onChange={(e) => {canManageChange(e.target.checked, tableMeta.rowData[2])}} />
                            : <FormCheck checked={value} />
                },
                filterOptions: {
                    renderValue: (v) => v ? 'Yes' : 'No'
                },
                customFilterListOptions: {
                    render: (v) => v ? 'Yes' : 'No'
                }
            }
        },
        { name: 'OpeningManager', label: 'Opening Manager', 
            options: {
                customBodyRender: (value, tableMeta) => {
                    
                    return (                        
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <span style={{marginRight: '5px'}}>
                                {value}
                            </span>
                            {owner ? <CreateIcon onClick={(e) => {setWhichManager('open');handleClick(e, 'Manager', tableMeta.rowData[2])}} /> : ''}
                        </div>
                    )
                }
            }
        },        
        { name: 'ClosingManager', label: 'Closing Manager', 
            options: {
                customBodyRender: (value, tableMeta) => {
                    return (
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <span style={{marginRight: '5px'}}>
                                {value}
                            </span>
                            {owner ? <CreateIcon onClick={(e) => {setWhichManager('close');handleClick(e, 'Manager', tableMeta.rowData[2])}} /> : ''}
                        </div>
                    )
                }
            }
        },
        { name: 'Created', label: 'Aquisition',
            options: {
                customBodyRender: (value) => {
                    if (value){
                        return utcStringToLocal(value)
                    }
                    return ''
                },
                filter: false
            }
        },
        { name: 'Conversion', label: 'Conversion',
            options: {
                customBodyRender: (value) => {
                    if (value){
                        return utcStringToLocal(value)
                    }
                    return ''
                },
                filter: false
            }
        },
        { name: 'Canceled', label: 'Cancelation',
            options: {
                customBodyRender: (value) => {
                    if (value){
                        return utcStringToLocal(value)
                    }
                    return ''
                },
                filter: false
            }
        }
    ]

    const tableOpts = {
        selectableRows: 'none',
        rowsPerPage: 25,
        rowsPerPageOptions: [10, 25, 50, 100],
        filterType: 'multiselect',
    }

    const handleClick = (event, what, id) => {
        setAnchorEl(event.currentTarget)
        setPopWhat(what)
        setPopID(id)
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const [popWhat, setPopWhat] = useState(null)
    const [popID, setPopID] = useState(null)
    const open = Boolean(anchorEl)
    const id = open ? 'editUser' : undefined


    const replaceUser = (newUser) => {
        const newUsers = userState.map(x => {
            if (x.DiscordUser && x.DiscordUser.DiscordUserID === newUser.DiscordUser.DiscordUserID){
                x = newUser
            }
            return x
        })
        setUserState(newUsers)
        handleFilters(newUsers)
    }

    const generateCode = () => {
        handleClose()
        const request = {
            DiscordAccountID: dmState.DiscordAccountID,
            DiscordUserID: popID
        }
        return getReferralCodeAPI(user.UserID, user.IdToken, user.AccessToken, request)
            .then(handleErrors)
            .then(results => {
                if (!results){
                    notify && notify('Error', 'Error Generating Referral Code')
                    return
                }
                refresh()
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify && notify('Error', msg.toString())
                }
            })
    }

    const canManageChange = (canManage, dUserID) => {
        const request = {
            DiscordAccountID: dmState.DiscordAccountID,
            DiscordUserID: dUserID,
            CanManage: canManage
        }
        // Update Check right away to make it feel responsive
        const targetUser = userState.filter(x => x.DiscordUserID === dUserID)[0]
        targetUser.CanManageOthers = canManage;
        //replaceUser(targetUser)
        return setUserCanManageAPI(user.UserID, user.IdToken, user.AccessToken, request)
            .then(handleErrors)
            .then(results => {
                if (!results){
                    notify && notify('Error', 'Error Updating Can Manage')
                    return
                }
                refresh()
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify && notify('Error', msg.toString())
                }
            })
    }

    const updateManager = (id) => {
        handleClose()
        const request = {
            DiscordAccountID: dmState.DiscordAccountID,
            DiscordUserID: popID,
            ManagerID: id,
            WhichManager: whichManager
        }
        return setUserManagerAPI(user.UserID, user.IdToken, user.AccessToken, request)
            .then(handleErrors)
            .then(results => {
                if (!results){
                    notify && notify('Error', 'Error Generating Referral Code')
                    return
                }
                refresh()
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify && notify('Error', msg.toString())
                }
            })
    }

    const handleFilters = (fullData, start, end, filter) => {
        if (!fullData){
            fullData = userState
        }
        if (start === undefined){
            start = dateStart
        }
        if (end === undefined){
            end = dateEnd
        }
        if (filter === undefined){
            filter = dateFilter
        }

        if (fullData){
            const filteredData = fullData.filter(x => {
                let checkDate
                if (filter === '1'){
                    // Aquisitions
                    checkDate = x.Created
                }
                else if (filter === '2'){
                    // Conversion
                    checkDate = x.Conversion
                }
                else if (filter === '3'){
                    // Cancelation
                    checkDate = x.Canceled
                }
                else if (!filter){
                    return true
                }

                //CheckDate in UTC
                const localTime = utcStringToLocalDate(checkDate)

                return localTime >= start && localTime <= end
            })
            setFilteredState(filteredData)
        }
    }
    const u = undefined
    const datepickerProps = {
        start: dateStart,
        setStart: (x) => {setDateStart(x); handleFilters(u, x, u, u)},
        end: dateEnd,
        setEnd: (x) => {setDateEnd(x); handleFilters(u, u, x, u)},
        dateFilter,
        setDateFilter: (x) => {setDateFilter(x); handleFilters(u, u, u, x)},
        handleClick
    }

    const getURLBase = (accountID) => {
        switch (accountID){
            case 1:
                // Demo

                // Prod
                return 'https://discord.com/api/oauth2/authorize?client_id=716365960390508597&redirect_uri=https%3A%2F%2Fvarbis.net%2Fdiscorduser&response_type=code&scope=identify%20email%20guilds.join&prompt=none&state=a%40https%3A%2F%2Fslapstocks.com/pages/subscription-manager-demo%21'
                
                // Local
                //return 'https://discord.com/api/oauth2/authorize?client_id=716365960390508597&redirect_uri=https%3A%2F%2Fvarbis.net%2Fdiscorduser&response_type=code&scope=identify%20email%20guilds.join&prompt=none&state=a%40https%3A%2F%2Fslapstocks.com/pages/subscription-manager-demo%21'
            case 3:
                // Slap Stocks
                return `https://slapstocks.com/pages/sign-up?z=1`
                //return 'https://discord.com/api/oauth2/authorize?client_id=716365960390508597&redirect_uri=https%3A%2F%2Fvarbis.net%2Fdiscorduser&response_type=code&scope=identify%20email%20guilds.join&prompt=none&state=a%40https%3A%2F%2Fslapstocks.com/pages/subscription-manager%21'
            default:
                return ''
        }
    }

    const generateURL = () => {
        const accountID = dmState.DiscordStripePlans && dmState.DiscordStripePlans[0] && dmState.DiscordStripePlans[0].DiscordAccountID
        let base = getURLBase(accountID);
        
        switch(accountID){
            case 3:
                {
                    if (urlTier){
                        base += '&t=' + urlTier
                    }
                    if (urlCode){
                        base += '&r=' + urlCode
                    }
                    setGeneratedURL(base)
                }
                break
            default:
                {
                    if (urlTier){
                        base += 't%40' + urlTier + '%21'
                    }
                    if (urlCode){
                        base += 'r%40' + urlCode + '%21'
                    }
                    setGeneratedURL(base)
                }
                break
        }
        
    }

    const copyURL = (e) => {        
        genFieldRef.current.select()
        try {
            document.execCommand('copy');
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <Card bg="dark">
            <Card.Header style={{color: 'white'}}>
                <div>
                    <DateRangeFilter {...datepickerProps} />
                </div>
            </Card.Header>
            <MuiThemeProvider theme={DarkTheme()}>
                <MUIDataTable
                    title=""
                    columns={columns}
                    data={filteredState}
                    options={tableOpts}
                />
            </MuiThemeProvider>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <PopoverTitle style={{backgroundColor: 'rgba(0,0,0,.03)', color: 'white'}}>
                    {popWhat && popWhat === 'RefCode' && (
                        'Referral Code'
                    )}
                    {popWhat && popWhat === 'Manager' && (
                        'Manager'
                    )}
                    {popWhat && popWhat === 'urls' && (
                        <Fragment>
                            <span>Referral Code Generator</span>
                        </Fragment>
                    )}
                </PopoverTitle>
                <PopoverContent style={{color: 'white'}}>
                    {popWhat && popWhat === 'RefCode' && (
                        <Fragment>
                            <Button onClick={generateCode}>Generate</Button>
                        </Fragment>
                    )}
                    {popWhat && popWhat === 'Manager' && (
                        <ListGroup>
                            <ListGroup.Item variant="danger" onClick={() => updateManager(null)}>
                                Remove Manager
                            </ListGroup.Item>
                            {users && userState.filter(x => x.CanManageOthers).sort((a,b) => a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1).map(x => {
                                return (
                                    <ListGroup.Item variant="dark" onClick={() => updateManager(x.DiscordID)}>
                                        {x.Name}
                                    </ListGroup.Item>
                                )
                            })}
                        </ListGroup>
                    )}
                    {popWhat && popWhat === 'urls' && (
                        <Fragment>
                            <Form.Group as={Row}>
                                <Form.Label column>Referral Code</Form.Label>
                                <Col>
                                    <Form.Control value={urlCode} onChange={(e) => setUrlCode(e.target.value)} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                            <Form.Label column>Plan</Form.Label>
                                <Col>
                                    <Form.Control as="select" value={urlTier} onChange={(e) => setUrlTier(e.target.value)}>
                                        <option value="">None</option>
                                        {dmState.DiscordStripePlans && dmState.DiscordStripePlans.filter(x => x.Enabled).map(x => {
                                            return <option value={x.PlanID}>{x.Name}</option>
                                        })}
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col style={{justifyContent: 'center', display: 'flex'}}>
                                    <Button onClick={generateURL}>
                                        Generate
                                    </Button>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <Button variant="secondary" onClick={copyURL}>
                                                Copy
                                            </Button>
                                        </InputGroup.Prepend>
                                        <Form.Control value={generatedURL} ref={genFieldRef} />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                        </Fragment>
                    )}
                </PopoverContent>
            </Popover>
        </Card>
    )
}

const DateRangeFilter = ({...props}) => {
    const classes = useStyles()    
    const isBig = useMediaQuery('(min-width:600px)');
    return(
        <Fragment>
            <Row>
                <Col sm={3}><b>Users</b></Col>
                <Col sm={2}>
                    <FormControl as="select" value={props.dateFilter} onChange={(e) => props.setDateFilter(e.target.value)}>
                        <option value="">No Range</option>
                        <option value="1">Aquisition</option>
                        <option value="2">Conversion</option>
                        <option value="3">Cancelation</option>
                    </FormControl>
                </Col>
                    {isBig ? (
                    <Fragment>                
                    <Col lg={4} className={classes.leftFlex}>    
                        <DatePicker
                            selected={props.start}
                            onChange={(date) => props.setStart(date)}
                            showTimeSelect
                            timeIntervals={30}
                            timeCaption="Time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                        />
                        <span style={{marginRight: '30px', marginLeft: '30px'}}>To</span>
                        <DatePicker
                            selected={props.end}
                            onChange={(date) => props.setEnd(date)}
                            showTimeSelect
                            timeIntervals={30}
                            timeCaption="Time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                        />
                        <Button style={{marginLeft: '10px'}} onClick={(e) => {props.handleClick(e, 'urls')}}>
                            Generate Links
                        </Button>
                        </Col>
                    </Fragment>
                    ) : (
                        <Fragment>
                            <Col sm={10} className={classes.fullCenter} style={{marginTop: '10px'}}>
                                <DatePicker
                                    selected={props.start}
                                    onChange={(date) => props.setStart(date)}
                                    showTimeSelect
                                    timeIntervals={30}
                                    timeCaption="Time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                />
                            </Col>
                            <Col sm={10} className={classes.fullCenter}>
                                <span style={{marginRight: '30px', marginLeft: '30px'}}>To</span>
                            </Col>
                            <Col sm={10} className={classes.fullCenter}>
                                <DatePicker
                                    selected={props.end}
                                    onChange={(date) => props.setEnd(date)}
                                    showTimeSelect
                                    timeIntervals={30}
                                    timeCaption="Time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                />
                            </Col>
                            <Col sm={10} className={classes.fullCenter}>                            
                                <Button onClick={(e) => {props.handleClick(e, 'urls')}}>
                                    Generate Links
                                </Button>
                            </Col>
                        </Fragment>
                    )}
            </Row>
        </Fragment>
    )
}