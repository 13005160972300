import {LOAD_TOKEN, SAVE_GUILDS} from '../actions/discord'
import {SIGN_OUT} from '../actions/user'

export default function discord(state = {}, action) {
	switch (action.type) {
        case LOAD_TOKEN :
            return {
                ...state,
                token: {...state.token, ...action.data}
            }
        case SAVE_GUILDS :
            return {
                ...state,
                guilds: action.data
            }
        case SIGN_OUT :
            return {}
        default :
			return state
    }
}