import {baseURL} from '../base'

const controller = `${baseURL}/ally/v1`

export async function getAccountInfo(userID, idToken, access){
    const response = await fetch(`${controller}/account/${userID}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
    })
    return response
}

export async function getOrders(userID, idToken, access){
    const response = await fetch(`${controller}/getorders/${userID}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
    })
    return response
}

export async function getStrikes(symbol, userID, idToken, access){
    const response = await fetch(`${controller}/getstrikes/${userID}/${symbol}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
    })
    return response
}

export async function getExpirations(symbol, userID, idToken, access){
    const response = await fetch(`${controller}/getexpirations/${userID}/${symbol}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
    })
    return response
}

export async function getQuote(symbol, userID, idToken, access){
    const response = await fetch(`${controller}/getquote/${userID}/${symbol}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
    })
    return response
}

export async function previewOrderAPI(order, userID, idToken, access){
    const response = await fetch(`${controller}/orderpreview/${userID}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
        body: JSON.stringify(order)
    })
    return response
}

export async function placeOrderAPI(order, userID, idToken, access){
    const response = await fetch(`${controller}/placeorder/${userID}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
        body: JSON.stringify(order)
    })
    return response
}