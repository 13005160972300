import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Jumbotron, Form, Button, Nav } from 'react-bootstrap'
import { makeStyles } from '@material-ui/styles'
import { handleErrors } from '../../api/base'
import { signUpAPI, checkEmailAPI } from '../../api/user/auth'
import { validateSignIn } from '../../actions/user'
import { Redirect } from 'react-router-dom'
import GoodIcon from '@material-ui/icons/Done'
import BadIcon from '@material-ui/icons/Error'
import Tooltip from '@material-ui/core/Tooltip'
import fullLogo from '../../images/FullLogo.png'
import Helmet from 'react-helmet'

const useStyles = makeStyles({
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    column: {
        flexDirection: 'column'
    },
    fullHeight: {
        height: '100vh'
    },
    big: {
        height: '40%',
        width: '80%'
    },
    outline: {
        border: '.2rem solid #ececec',
        borderRadius: '8px'
    },
    darkJumbo: {
        backgroundColor: '#d2d5d8'
    }
})

export const SignUp = () => {
    const classes = useStyles();
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const [pwGood, setPWGood] = useState(null)
    const [emailGood, setEmailGood] = useState(null)

    const [rptPW, setRptPW] = useState('')
    const [rptGood, setRptGood] = useState(null)

    const [message, setMessage] = useState('')

    const [registered, setRegistered] = useState(false)

    const handleSignUp = (e) => {
        e.preventDefault()
        if (!username || !password || !emailGood || !pwGood || !rptPW || !rptGood){
            alert('Invalid Fields. Please Try Again')
            return Promise.resolve()
        }

        return signUpAPI(username, password)
            .then(handleErrors)
            .then(result => {
                if (result === true){
                    setRegistered(true)
                }
                else{                    
                    setMessage(result)
                }
            })
            .catch(error => error)
            .then(msg => {
                if (msg) {
                    console.log(msg)
                    if (msg.message === 'Failed to fetch' || msg === '503'){
                        msg = 'Currently Under Maintenance'
                    }
                    setMessage(msg)
                }
            })
            
    }


    const handleEmailCheck = () => {
        //validate email
        return checkEmailAPI(username)
            .then(handleErrors)
            .then(results => {
                setEmailGood(!results)
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    setMessage(msg.toString())
                }
            })
    }

    const handlePWCheck = (pw) => {
        const valid = pw.length >= 8 && pw.match(/[A-Z]/) && pw.match(/[a-z]/) && pw.match(/\d/)
        if (pw === ''){
            setPWGood(undefined)
        }
        else{
            setPWGood(valid)
        }
    }

    const handleRptPWCheck = (pw1, pw2) => {
        if (pw2 === undefined){
            pw2 = password
        }
        if (pw1 === undefined){
            pw1 = rptPW
        }
        const valid = pw1 === pw2
        if (password === '' || rptPW === ''){
            setRptGood(null)
        }
        else{
            setRptGood(valid)
        }
    }

    if (registered){
        return (
            <Redirect to="/" />
        )
    }

    return (
        <Container fluid>
            <Helmet>
                <meta name="description" content="Varbis Discord Subscription Manager Sign Up" />
                <title>Sign Up - Varbis.NET</title>
            </Helmet>
            <Row>
                <Col xs={0} md={2} lg={3}></Col>
                <Col xs>
                    <div className={` ${classes.fullHeight}`}>
                    <div style={{width: '100%', height: '55px', 
                        backgroundSize: 'contain', backgroundImage: `url(${fullLogo})`,
                        backgroundRepeat: 'no-repeat', backgroundPositionX: 'center',
                        marginBottom: '10vh'
                    }}>
                    </div>

                    <div>
                        <Jumbotron className={`${classes.center} ${classes.darkJumbo} ${classes.column}`}  style={{width: '100%'}}>
                            <h2 style={{marginBottom: '20px'}}>Sign Up</h2>
                            {message && <h5 style={{color: 'red'}}>{message.toString()}</h5>}
                            <Form onSubmit={handleSignUp}>
                                <Form.Group controlId="email">
                                    <Form.Label className={classes.row}>Email Address 
                                        {emailGood !== null && (emailGood 
                                            ? <Tooltip title="Not Taken"><GoodIcon style={{color: 'green'}} /></Tooltip>
                                            : <Tooltip title="Taken"><BadIcon style={{color: 'red'}} /></Tooltip>
                                        )}
                                    </Form.Label>
                                    <Form.Control value={username} type="email" placeholder="Enter Email" onChange={(e) => {setUsername(e.target.value)}} onBlur={handleEmailCheck} />
                                </Form.Group>
                                <Form.Group controlId="password">
                                    <Form.Label className={classes.row}>Password 
                                        {pwGood !== undefined && (pwGood 
                                            ? <Tooltip title="Good"><GoodIcon style={{color: 'green'}} /></Tooltip>
                                            : <Tooltip title="Must have 8 Characters, Upper, Lower, Number"><BadIcon style={{color: 'red'}} /></Tooltip>
                                        )}
                                    </Form.Label>
                                    <Form.Control value={password} type="password" placeholder="Password" onChange={(e) => {setPassword(e.target.value); handlePWCheck(e.target.value); handleRptPWCheck(undefined, e.target.value)}} />
                                </Form.Group>
                                <Form.Group controlId="password">
                                    <Form.Label className={classes.row}>Repeat Password 
                                        {rptGood !== null && (rptGood 
                                            ? <Tooltip title="Good"><GoodIcon style={{color: 'green'}} /></Tooltip>
                                            : <Tooltip title="Must Match Password"><BadIcon style={{color: 'red'}} /></Tooltip>
                                        )}</Form.Label>
                                    <Form.Control value={rptPW} type="password" placeholder="Password" onChange={(e) => {setRptPW(e.target.value); handleRptPWCheck(e.target.value)}} />
                                </Form.Group>
                                <div>By signin up you are agreeing to<br /> the following terms of service:</div>       
                                <div style={{display: 'flex', justifyContent: 'center', marginTop: '5px', marginBottom: '10px'}}>
                                    
                                    <Nav.Link href="/eula">EULA</Nav.Link>
                                    <Nav.Link href="/privacypolicy">Privacy</Nav.Link>
                                </div>

                                <div className={`${classes.center}`}>
                                    <Button variant="primary" type="submit" disabled={!username || !password || !emailGood || !pwGood || !rptPW || !rptGood}>
                                        Sign Up
                                    </Button>
                                </div>
                            </Form>
                        </Jumbotron>
                    </div>
                    </div>
                </Col>
                <Col xs={0} md={2} lg={3}></Col>
            </Row>
        </Container>
    )
}

export default SignUp