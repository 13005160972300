export const formatNegative = (x) => {
    x = Number(x)
    if (x < 0){
        x = x * -1;
        return `(${x})`
    }
    return x.toString()
}

export const zeroIfNull = (x) => {
    let y = Number(x)
    if (isNaN(y)){
        return 0
    }
    return y
}

export const roundMoney = (x) => {
    return Math.round((x + Number.EPSILON) * 100) / 100
}