import {baseURL} from '../base'

const controller = `${baseURL}/discordusermanager/v1`

export async function getAccountsToManageAPI(userID, idToken, access){
    const response = await fetch(`${controller}/accountsToManage/${userID}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        }
    })
    return response
}

export async function getPlansAPI(userID, idToken, access, ownerID){
    const response = await fetch(`${controller}/getplans/${userID}/${ownerID}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        }
    })
    return response
}

export async function getAccountUsersAPI(userID, idToken, access, ownerID){
    const response = await fetch(`${controller}/accountuserslazy/${userID}/${ownerID}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        }
    })
    return response
}

export async function getAccountUsersLiteAPI(userID, idToken, access, ownerID, request){
    const response = await fetch(`${controller}/accountuserslite/${userID}/${ownerID}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function getFullAccountUserAPI(userID, idToken, access, ownerID, target){
    const response = await fetch(`${controller}/getfulluser/${userID}/${ownerID}/${target}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        }
    })
    return response
}

export async function getAccountsToSMSAPI(userID, idToken, access){
    const response = await fetch(`${controller}/accountsToSMS/${userID}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        }
    })
    return response
}

export async function sendSMSAPI(userID, idToken, access, request){
    const response = await fetch(`${controller}/sendSMS/${userID}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function getUserManagerPaymentsAPI(userID, idToken, access){
    const response = await fetch(`${controller}/getpayments/${userID}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        }
    })
    return response
}