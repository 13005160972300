import {baseURL} from '../base'

const controller = `${baseURL}/user/v1`

export async function changePasswordAPI(userID, idToken, access, request){
    const response = await fetch(`${controller}/changepw/${userID}`, {
        method: 'PATCH',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function updateSettingsAPI(userID, idToken, access, settings){
    const response = await fetch(`${controller}/updatesettings/${userID}`, {
        method: 'PATCH',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
        body: JSON.stringify(settings)
    })
    return response
}