import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Toast, Row, Col, Card, ListGroup, Table, Form, FormControl, Button } from 'react-bootstrap'
import { makeStyles } from '@material-ui/styles'
import { handleErrors } from '../../api/base'
import { refreshAPI } from '../../api/user/auth'
import { validateSignIn, signOutAction } from '../../actions/user'
import { getAccountInfo, getOrders, getStrikes, getExpirations, getQuote, previewOrderAPI, placeOrderAPI } from '../../api/trading/ally'
import { formatNegative, zeroIfNull } from '../../utility/numberFormat'

export const Toasty = ({title, message, icon}) => {
    const [show, setShow] = useState(true)
    return (
        <Toast onClose={() => setShow(false)} show={show} delay={5000} autohide={true}>
            <Toast.Header>
                <strong>{title}</strong>
            </Toast.Header>
            <Toast.Body>
                {message}
            </Toast.Body>
        </Toast>
    )
}