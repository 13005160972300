import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Card, ListGroup, Table, Form, FormControl, Button, Modal, OverlayTrigger, PopoverTitle, PopoverContent } from 'react-bootstrap'
import { makeStyles } from '@material-ui/styles'
import { handleErrors } from '../../api/base'
import { formatNegative, zeroIfNull } from '../../utility/numberFormat'
import OrderOptions from '@material-ui/icons/MenuOpenRounded'
import { Popover, FormGroup } from '@material-ui/core'
import { Redirect } from 'react-router-dom'

import { getAllDMSettingsAPI, setAllDMSettingsAPI, getAllDMPlanUsersLiteAPI,
     UpdateDMPlanUserAPI, getDMGuildRolesAPI, getDMChannelsAPI, getPlanPromosAPI, updatePlanPromosAPI } from '../../api/user/discordmanager'
import { loadDMSettingsAction } from '../../actions/discordmanager'
import { Toasty } from '../trading/toast'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DeleteIcon from '@material-ui/icons/Delete'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/AddCircle'
import { getDiscordServers } from '../../api/user/discord'
import {DiscordManagerUsers} from './discordManagerUsers'
import { signOutAction } from '../../actions/user'
import { ManagerContent } from './discordmanager/managerContent'
import { DiscordManagerUsersLite } from './discordmanager/userTableLite'
import { ManagerPayments } from './discordowner/managerpayments'
import CircularProgress from '@material-ui/core/CircularProgress'
import { OwnerCSS } from './discordowner/ownercss'
import { QuickBooks } from './discordowner/quickbooks'

export const DiscordManager = () => {
    // Consts
    const SETTINGS_PAGE = 'SETTINGS_PAGE'
    const USER_PAGE = 'USER_PAGE'
    const CONTENT_PAGE = 'CONTENT_PAGE'
    const PAYMENTS_PAGE = 'PAYMENTS_PAGE'
    const CSS_PAGE = 'CSS_PAGE'
    const QBO_PAGE = 'QBO_PAGE'

    const isBig = useMediaQuery('(min-width:600px)');

    // Redux
    const user = useSelector(state => state.user)
    const dmState = useSelector(state => state.discordManager)
    const discordState = useSelector(state => state.discord)
    const dispatch = useDispatch()

    // Set Defaults    
    const mName = dmState.ManagerName || ''
    const url = dmState.ManagerURL || ''
    const defaultRefs = dmState.DefaultReferralCodes || false
    const stripeAPI = (dmState.DiscordStripes && dmState.DiscordStripes.length && dmState.DiscordStripes[0].APIKey) || ''
    const stripePKAPI = (dmState.DiscordStripes && dmState.DiscordStripes.length && dmState.DiscordStripes[0].PKAPIKey) || ''
    const stripeWHSecret = (dmState.DiscordStripes && dmState.DiscordStripes.length && dmState.DiscordStripes[0].SubscriptionSecret) || ''
    const plans = dmState.DiscordStripePlans || []
    const gID = dmState.GuildID || ''
    const testID = dmState.TestimonialChannelID || '' 
    const notifID = dmState.NotificationChannelID || ''

    // Local State
    const [toasts, setToasts] = useState([])
    const [page, setPage] = useState(SETTINGS_PAGE)
    const [managerName, setManagerName] = useState(mName)
    const [managerURL, setManagerURL] = useState(url)
    const [defaultReferrals, setDefaultReferrals] = useState(defaultRefs)
    const [stripeAPIKey, setStripeAPIKey] = useState(stripeAPI)
    const [stripePKAPIKey, setStripePKAPIKey] = useState(stripePKAPI)
    const [webHookSecret, setWebHookSecret] = useState(stripeWHSecret)
    const [stripePlans, setStripePlans] = useState(plans)
    const [guildID, setGuildID] = useState(gID)
    const [showServerModal, setShowServerModal] = useState(false)
    const [servers, setServers] = useState([])
    const [showRoleModal, setShowRoleModal] = useState(false)
    const [serverRoles, setServerRoles] = useState([])
    const [planSelecting, setPlanSelecting] = useState(null)
    const [planUsers, setPlanUsers] = useState([])
    const [trialRoleSelector, setTrialRoleSelector] = useState(false)
    const [showChannelModal, setShowChannelModal] = useState(false)
    const [channels, setChannels] = useState([])
    const [testimonialSelector, setTestimonialSelector] = useState(false)
    const [testimonialID, setTestimonialID] = useState(testID)
    const [notificationID, setNotificationID] = useState(notifID)
    const [notifSelector, setNotifSelector] = useState(false)
    const [subChannelSelector, setSubChannelSelector] = useState(null)
    const [showContentModal, setShowContentModal] = useState(false)
    const [showPromoModal, setShowPromoModal] = useState(false)
    const [displayPromos, setDisplayPromos] = useState([])
    const [promoLoading, setPromoLoading] = useState(false)
    const [promoPlanID, setPromoPlanID] = useState(null)

    useEffect(() => {
        loadSettings()
    }, [])

    const loadSettings = () => {
        getAllDMSettingsAPI(user.UserID, user.IdToken, user.AccessToken)
        .then(handleErrors)
        .then(results => {
            if (!results){                    
                notify('Error', 'Error Loading Settings')
                return;
            }
            dispatch(loadDMSettingsAction(results))
            setManagerName(results.ManagerName || '')
            setManagerURL(results.ManagerURL || '')
            setDefaultReferrals(results.DefaultReferralCodes || false)
            setStripeAPIKey((results.DiscordStripes && results.DiscordStripes.length && results.DiscordStripes[0].APIKey) || '')
            setStripePKAPIKey((results.DiscordStripes && results.DiscordStripes.length && results.DiscordStripes[0].PKAPIKey) || '')
            setStripePlans(results.DiscordStripePlans || [])
            setGuildID(results.GuildID || '')
            setWebHookSecret((results.DiscordStripes && results.DiscordStripes.length && results.DiscordStripes[0].SubscriptionSecret) || '')
            setTestimonialID(results.TestimonialChannelID || '')
            setNotificationID(results.NotificationChannelID || '')
        })
        .catch(error => error)
        .then(msg => {
            if (msg){
                if (msg.message === 'Failed to fetch'){
                    dispatch(signOutAction())
                }
                notify('Error', msg.toString())
            }
        })
    }

    const notify = (title, msg) => {
        let tsts = toasts.map(x => x)
        tsts.push({
            Title: title,
            Message: msg
        })
        setToasts(tsts)
        return tsts
    }

    const addPlanHandler = () => {
        const newPlans = stripePlans.map(x => x)
        newPlans.push({
            BottomColor: '',
            Description: '',
            DiscordStripePlanFrequencies: [],
            Enabled: true,
            HasTrial: false,
            Name: '',
            ProductID: '',
            RoleName: '',
            RoleID: '',
            TopColor: '',
            TrialDays: '',
            TrialRequiresPayment: false,
            TrialPromo: false,
            PromoCode: '',
            TrialRoleName: '',
            TrialRowID: '',
            OnboardChannelName: '',
            OnboardChannelID: ''
        })
        setStripePlans(newPlans)
    }

    const addPriceHandler = (index) => {
        const newPlans = stripePlans.map((x, i) => {
            if (i == index){
                x.DiscordStripePlanFrequencies.push({
                    PriceID: '',
                    Frequency: '2',
                    Enabled: true,
                    Cost: '0'
                })
            }
            return x
        })
        setStripePlans(newPlans)
    }
    
    const handleContentSelect = (reqID) => {  
        const newPlans = stripePlans.map((x, i) => {
            if (i == planSelecting){
                x.DiscordPlanContents.push({
                    PlanID: x.PlanID,
                    RequestID: reqID
                })
            }
            return x
        })
        setStripePlans(newPlans)
        setShowContentModal(false)
    }

    const removePriceHandler = (iIndex, jIndex) => {
        const newPlans = stripePlans.map((x, i) => {
            if (i == iIndex){
                let newFreqs = []
                x.DiscordStripePlanFrequencies.forEach((y, j) => {
                    if (j !== jIndex){
                        newFreqs.push(y)
                    }
                })
                x.DiscordStripePlanFrequencies = newFreqs
            }
            return x
        })
        setStripePlans(newPlans)
    }

    const removePlanHandler = (iIndex) => {        
        let newPlans = []
        stripePlans.forEach((x, i) => {
            if (i !== iIndex){
                newPlans.push(x)
            }
        })
        setStripePlans(newPlans)
    }

    const removeContent = (iIndex, jIndex) => {
        let newPlans = stripePlans.map((x, i) => {
            if (i === iIndex){
                x.DiscordPlanContents = x.DiscordPlanContents.filter((y, j) => {
                    return j !== jIndex
                })
            }
            return x
        })
        setStripePlans(newPlans)
    }

    const removeChannel= (iIndex, jIndex) => {
        let newPlans = stripePlans.map((x, i) => {
            if (i === iIndex){
                x.DiscordAccountSubscribableChannels = x.DiscordAccountSubscribableChannels.filter((y, j) => {
                    return j !== jIndex
                })
            }
            return x
        })
        setStripePlans(newPlans)
    }

    const updatePlanProp = (iIndex, prop, value) => {
        const newPlans = stripePlans.map((x, i) => {
            if (i == iIndex){
                x[prop] = value
            }
            return x
        })
        setStripePlans(newPlans)
    }

    const updatePlanFreqProp = (iIndex, jIndex, prop, value) => {
        const newPlans = stripePlans.map((x, i) => {
            if (i == iIndex){
                const newFreqs = x.DiscordStripePlanFrequencies.map((y, j) => {
                    if (j == jIndex){
                        y[prop] = value
                    }
                    return y
                })
                x.DiscordStripePlanFrequencies = newFreqs
            }
            return x
        })
        setStripePlans(newPlans)
    }

    const prepToSend = () => {
        const newPlans = stripePlans.map((x, i) => {
            const newFreqs = x.DiscordStripePlanFrequencies.map((y, j) => {
                y.Frequency = Number(y.Frequency)
                y.Cost = Number(y.Cost)
                return y
            })
            x.TrialDays = Number(x.TrialDays)
            if (isNaN(x.TrialDays)){
                x.TrialDays = 0
            }
            x.DiscordStripePlanFrequencies = newFreqs
            return x
        })
        const fullAccount = {
            ManagerName: managerName,
            ManagerURL: managerURL,
            DefaultReferralCodes: defaultReferrals,
            GuildID: guildID,
            DiscordStripes: [{APIKey: stripeAPIKey, PKAPIKey: stripePKAPIKey, SubscriptionSecret: webHookSecret}],
            DiscordStripePlans: newPlans,
            TestimonialChannelID: testimonialID,
            NotificationChannelID: notificationID
        }
        return fullAccount
    }

    const saveSettings = () => {
        notify('Saving', 'Saving...')
        const data = prepToSend()
        console.log(data)

        setAllDMSettingsAPI(user.UserID, user.IdToken, user.AccessToken, data)
            .then(handleErrors)
            .then(results => {
                if (results !== 'Success'){
                    notify('Error', 'There was an error saving!')
                    return;
                }
                getAllDMSettingsAPI(user.UserID, user.IdToken, user.AccessToken)
                    .then(handleErrors)
                    .then(getResults => {
                        if (!getResults){                    
                            notify('Error', 'Error Loading Settings')
                            return;
                        }
                        dispatch(loadDMSettingsAction(getResults))
                        
                        setManagerURL(getResults.ManagerURL || '')
                        setDefaultReferrals(getResults.DefaultReferralCodes || false)
                        setStripeAPIKey((getResults.DiscordStripes && getResults.DiscordStripes.length && getResults.DiscordStripes[0].APIKey) || '')
                        setStripePlans(getResults.DiscordStripePlans || [])
                        setGuildID(getResults.GuildID || '')
                        setWebHookSecret((getResults.DiscordStripes && getResults.DiscordStripes.length && getResults.DiscordStripes[0].SubscriptionSecret) || '')
                        setTestimonialID(getResults.TestimonialChannelID || '')
                        setNotificationID(getResults.NotificationChannelID || '')
                        notify('Saved', 'Successfully Saved')
                    })
                    .catch(error => error)
                    .then(msg => {
                        if (msg){
                            notify('Error', msg.toString())
                        }
                    })
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
            })
    }

    const getServers = () => {
        return getDiscordServers(user.UserID, user.IdToken, user.AccessToken, discordState.token.access_token)
            .then(handleErrors)
            .then(results => {
                if (results){
                    setServers(results)
                    setShowServerModal(true)
                }
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
            })
    }

    const getServerRoles = (id) => {
        return getDMGuildRolesAPI(user.UserID, user.IdToken, user.AccessToken, id)
            .then(handleErrors)
            .then(results => {
                if (!results){
                    notify('Error', 'Error Getting Server Roles')
                    return;
                }
                setServerRoles(results)
                setShowRoleModal(true)
            })
            .catch(error => error)
            .then((msg) => {
                if (msg){
                    notify('Error', msg.toString())
                }
            })
    }

    const handleGetRoles = (iIndex, trialSelecting) => {
        if (!guildID){
            getServers();
        }
        else{
            getServerRoles(guildID)
        }
        setPlanSelecting(iIndex)
        setTrialRoleSelector(trialSelecting)
    }

    const handleGuildSelect = (id) => {
        setGuildID(id)
        setShowServerModal(false)
        getServerRoles(id)
    }

    const handleRoleSelect = (id, name) => {
        let newPlans = stripePlans.map((x, i) => {
            if (!trialRoleSelector && i === planSelecting){
                x.RoleID = id
                x.RoleName = name
            }
            else if (trialRoleSelector && i === planSelecting){
                x.TrialRoleID = id
                x.TrialRoleName = name
            }
            return x
        })
        setStripePlans(newPlans)
        setShowRoleModal(false)
    }

    const getPlanUsers = (ovInclude, ovHide) => {

        const settings = {
            ExpiredTrials: dmState.IncludeTrial,
            HideTrials: dmState.HideTrial,
        }
        if (ovInclude !== undefined){
            settings.ExpiredTrials = ovInclude
        }
        if (ovHide !== undefined){
            settings.HideTrials = ovHide
        }

        return getAllDMPlanUsersLiteAPI(user.UserID, user.IdToken, user.AccessToken, settings)
            .then(handleErrors)
            .then(results => {
                if (!results){
                    notify('Error', 'Error loading Users')
                    return
                }
                setPlanUsers(results)
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
            })
    }

    const handleChannelFind = (iIndex) => {
        if (!guildID){
            notify('Error', 'Link Guild First')
            return Promise.resolve()
        }
        if (!testimonialSelector){
            setPlanSelecting(iIndex)
        }
        return getDMChannelsAPI(user.UserID, user.IdToken, user.AccessToken)
            .then(handleErrors)
            .then(results => {
                if (!results){
                    notify('Error', 'Error loading channels')
                    return;
                }
                setChannels(results)
                setShowChannelModal(true)
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
            })
    }

    const handleChannelSelect = (id, name) => {
        if (testimonialSelector){
            setTestimonialID(id)
        }
        else if (notifSelector){
            setNotificationID(id)
        }
        else if (subChannelSelector){
            let newPlans = stripePlans.map((x, i) => {
                if (i === planSelecting){
                    x.DiscordAccountSubscribableChannels.push({
                        PlanID: x.PlanID,
                        ChannelID: id,
                        Name: name
                    })
                }
                return x
            })
            setStripePlans(newPlans)
        }
        else{
            let newPlans = stripePlans.map((x, i) => {
                if (i === planSelecting){
                    x.OnboardChannelID = id
                    x.OnboardChannelName = name
                }
                return x
            })
            setStripePlans(newPlans)
        }
        setShowChannelModal(false)
    }

    const loadPromos = (iIndex) => {
        setPromoLoading(true)
        let promos = stripePlans.filter((x, i) => i === iIndex)
        if (!promos[0] || !promos[0].PlanID){
            setDisplayPromos([])
            setPromoLoading(false)
        }
        else {
            // load from API
            setPromoPlanID(promos[0].PlanID)
            getPlanPromosAPI(user.UserID, user.IdToken, user.AccessToken, promos[0].PlanID)
                .then(handleErrors)
                .then(results => {
                    if (!results){
                        notify('Error', 'Error loading promos')
                        return
                    }
                    setDisplayPromos(results)
                })
                .catch(error => error)
                .then(msg => {
                    if (msg){
                        notify('Error', msg.toString())
                    }
                    setPromoLoading(false)
                })
        }
    }

    const addPromo = () => {
        let promos = displayPromos.map(x => x)
        promos.unshift({
            PromoCode: '',
            Aquisitions: 0,
            Conversions: 0,
            Active: true
        })
        setDisplayPromos(promos)
    }

    const removePromo = (iIndex) => {
        let promos = displayPromos.filter((x, i) => i !== iIndex)
        setDisplayPromos(promos)
    }

    const updatePromo = (iIndex, field, value) => {
        let promos = displayPromos.map((x, i) => {
            if (i === iIndex){
                x[field] = value
            }
            return x
        })
        setDisplayPromos(promos)
    }

    const savePromos = () => {
        setPromoLoading(true)
        let toSend = displayPromos.filter(x => x.PromoCode && x.PromoCode.trim()).map(x => {
            return {
                PromoCode: x.PromoCode,
                Active: x.Active,
                PromotionID: x.PromotionID || 0,
                PixelId: x.PixelId || null
            }
        })
        updatePlanPromosAPI(user.UserID, user.IdToken, user.AccessToken, promoPlanID, toSend)
            .then(handleErrors)
            .then(results => {
                if (results !== 'Success'){
                    notify('Error', 'Error Saving Promos')
                    setPromoLoading(false)
                    return
                }
                setShowPromoModal(false)
                notify('Saved', 'Promo Codes have been saved')
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                    setPromoLoading(false)
                }
            })
    }


    if (!user.DiscordManager){
        return <Redirect to="/" />
    }
    return (
        <Container fluid>
            <div>
                <Button variant="dark" style={{marginRight: '5px', marginBottom: '5px'}} onClick={() => {setPage(SETTINGS_PAGE); loadSettings()}}>Settings</Button>
                <Button variant="dark" style={{marginRight: '5px', marginBottom: '5px'}} onClick={() => {setPage(USER_PAGE); getPlanUsers()}}>Users</Button>
                <Button variant="dark" style={{marginRight: '5px', marginBottom: '5px'}} onClick={() => setPage(CONTENT_PAGE)}>Content</Button>
                <Button variant="dark" style={{marginRight: '5px', marginBottom: '5px'}} onClick={() => setPage(PAYMENTS_PAGE)}>Payments</Button>
                <Button variant="dark" style={{marginRight: '5px', marginBottom: '5px'}} onClick={() => setPage(CSS_PAGE)}>CSS</Button>
                <Button variant="dark" style={{marginRight: '5px', marginBottom: '5px'}} onClick={() => setPage(QBO_PAGE)}>QBO</Button>
            </div>
            {page === SETTINGS_PAGE && (
                <Fragment>
                    <Card bg="dark" style={{color: 'white'}}>
                        <Card.Header style={{display: 'flex', justifyContent: 'space-between'}}>
                            <b>Settings</b>
                            <Button variant="secondary" onClick={saveSettings}>Save</Button>
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                <Form.Group as={Row} style={{alignItems: 'center', textAlign: 'end'}}>
                                    <Form.Label column sm={2}>
                                        Account Name
                                    </Form.Label>
                                    <Col sm={2}>
                                        <Form.Control value={managerName} onChange={(e) => {setManagerName(e.target.value)}}></Form.Control>
                                    </Col>
                                    <Form.Label column sm={2}>
                                        Account URL
                                    </Form.Label>
                                    <Col sm={2}>
                                        <Form.Control value={managerURL} onChange={(e) => {setManagerURL(e.target.value)}}></Form.Control>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Check id="defRefCodes" type="switch" label="Default Referral Codes" checked={defaultReferrals} onChange={(e) => {setDefaultReferrals(e.target.checked)}} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} style={{alignItems: 'center', textAlign: 'end'}}>
                                    <Form.Label column sm={2}>
                                        Stripe Secret API Key
                                    </Form.Label>
                                    <Col column sm={2}>
                                        <Form.Control value={stripeAPIKey} onChange={(e) => {setStripeAPIKey(e.target.value)}} />
                                    </Col>
                                    <Form.Label column sm={2}>
                                        Stripe Public API Key
                                    </Form.Label>
                                    <Col column sm={2}>
                                        <Form.Control value={stripePKAPIKey} onChange={(e) => {setStripePKAPIKey(e.target.value)}} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} style={{alignItems: 'center', textAlign: 'end'}}>
                                    <Form.Label column sm={2}>
                                        Stripe WebHook Secret
                                    </Form.Label>
                                    <Col column sm={2}>
                                        <Form.Control value={webHookSecret} onChange={(e) => {setWebHookSecret(e.target.value)}} />
                                    </Col>
                                    <Form.Label column sm={2}>
                                        Testimonial ChannelID
                                    </Form.Label>
                                    <Col column sm={2}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <Form.Control disabled={true} value={testimonialID} />
                                            <SearchIcon onClick={() => {setSubChannelSelector(false);setNotifSelector(false);setTestimonialSelector(true);handleChannelFind()}} />
                                        </div>
                                    </Col>                                    
                                    <Form.Label column sm={2}>
                                        Notification ChannelID
                                    </Form.Label>
                                    <Col column sm={2}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <Form.Control disabled={true} value={notificationID} />
                                            <SearchIcon onClick={() => {setSubChannelSelector(false);setNotifSelector(true);setTestimonialSelector(false);handleChannelFind()}} />
                                            <DeleteIcon onClick={() => setNotificationID('')} />
                                        </div>
                                    </Col>
                                </Form.Group>
                            </Form>

                            <Card bg="dark" style={{color: 'white'}}>
                                <Card.Header style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <b>Plans</b>
                                    <Button variant="secondary" onClick={addPlanHandler}>Add Plan</Button>
                                </Card.Header>
                                <Card.Body>
                                    {stripePlans.map((x, i) => {
                                        return (
                                            <ListGroup style={{marginBottom: '5px'}}>
                                                <ListGroup.Item variant="dark">
                                                    <Form>
                                                        <Form.Group as={Row} style={{textAlign: isBig ? 'end' : 'left'}}>
                                                            <Form.Label column lg={2}>
                                                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                                    <DeleteIcon onClick={() => {removePlanHandler(i)}} />
                                                                    <span>Name</span>
                                                                </div>
                                                            </Form.Label>
                                                            <Col lg={2}>
                                                                <Form.Control value={x.Name} onChange={(e) => {updatePlanProp(i, 'Name', e.target.value)}} />
                                                            </Col>
                                                            <Form.Label column lg={2}></Form.Label>
                                                            <Col lg={2} style={{display: 'flex', alignItems: 'center'}}>
                                                                <Form.Check id={`enabled${i}`} type="switch" label="Enabled" style={{display: 'flex'}}  checked={x.Enabled} onChange={(e) => {updatePlanProp(i, 'Enabled', e.target.checked)}}  />
                                                            </Col>
                                                            <Form.Label column lg={2}>ProductID</Form.Label>
                                                            <Col lg={2}>
                                                                <Form.Control value={x.ProductID} onChange={(e) => {updatePlanProp(i, 'ProductID', e.target.value)}} />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} style={{textAlign: isBig ? 'end' : 'left'}}>
                                                            <Col lg={8}>
                                                                <Form.Group as={Row} style={{textAlign: isBig ? 'end' : 'left'}}>
                                                                    <Form.Label column sm={6} lg>Role Name</Form.Label>
                                                                    <Col sm={6} lg>
                                                                        <Form.Control value={x.RoleName} disabled={true} />
                                                                    </Col>
                                                                    <Form.Label column sm={6} lg>RoleID</Form.Label>
                                                                    <Col sm={6} lg>
                                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                                            <Form.Control disabled={true} value={x.RoleID} />
                                                                            <SearchIcon onClick={() => {handleGetRoles(i)}} />
                                                                        </div>
                                                                    </Col>
                                                                </Form.Group>
                                                                <Form.Group as={Row} style={{textAlign: isBig ? 'end' : 'left'}}>
                                                                    <Form.Label column sm={6} lg>Top Color</Form.Label>
                                                                    <Col sm={6} lg>
                                                                        <Form.Control value={x.TopColor} onChange={(e) => {updatePlanProp(i, 'TopColor', e.target.value)}}  />
                                                                    </Col>
                                                                    <Form.Label column sm={6} lg>Bottom Color</Form.Label>
                                                                    <Col sm={6} lg>
                                                                        <Form.Control value={x.BottomColor} onChange={(e) => {updatePlanProp(i, 'BottomColor', e.target.value)}}  />
                                                                    </Col>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <Form.Group as={Row} style={{textAlign: isBig ? 'end' : 'left', height: '100%'}}>
                                                                    <Form.Label column sm={6} lg>Description</Form.Label>
                                                                    <Col sm={6} lg>
                                                                        <Form.Control as="textarea" value={x.Description} onChange={(e) => {updatePlanProp(i, 'Description', e.target.value)}} style={{height: '100%'}} />
                                                                    </Col>
                                                                </Form.Group>
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} style={{textAlign: isBig ? 'end' : 'left'}}>
                                                            <Form.Label column lg={2}></Form.Label>
                                                            <Col lg={2} style={{display: 'flex', alignItems: 'center'}}>
                                                                <Form.Check id={`hasTrial${i}`} type="switch" label="Has Trial" style={{display: 'flex'}} checked={x.HasTrial} onChange={(e) => {updatePlanProp(i, 'HasTrial', e.target.checked)}} />
                                                            </Col>
                                                            <Form.Label column lg={2}>Trial Days</Form.Label>
                                                            <Col lg={2}>
                                                                <Form.Control value={x.TrialDays} onChange={(e) => {updatePlanProp(i, 'TrialDays', e.target.value)}}  />
                                                            </Col>
                                                            <Col lg={4} style={{display: 'flex', alignItems: 'center'}}>
                                                                <Form.Check id={`reqPayment${i}`} type="switch" label="Trial Requires Payment Information" style={{display: 'flex'}} checked={x.TrialRequiresPayment} onChange={(e) => {updatePlanProp(i, 'TrialRequiresPayment', e.target.checked)}} />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} style={{textAlign: isBig ? 'end' : 'left'}}>
                                                            <Form.Label column lg={2}></Form.Label>
                                                            <Col lg={2} style={{display: 'flex', alignItems: 'center'}}>
                                                                <Form.Check id={`hasTrialPromo${i}`} type="switch" label="Has Trial Promo" style={{display: 'flex'}} checked={x.TrialPromo} onChange={(e) => {updatePlanProp(i, 'TrialPromo', e.target.checked)}} />
                                                            </Col>
                                                            <Form.Label column lg={2}>Promo Codes</Form.Label>
                                                            <Col lg={2} style={{textAlign: 'center'}}>
                                                                <Button variant="secondary" onClick={() => {setShowPromoModal(true); loadPromos(i)}}>Manage</Button>
                                                            </Col>
                                                            <Col lg={4} style={{display: 'flex', alignItems: 'center'}}>
                                                                <Form.Check id={`dontCanTrial${i}`} type="switch" label="Dont Auto Cancel Trial" style={{display: 'flex'}} checked={x.DontCancelTrial} onChange={(e) => {updatePlanProp(i, 'DontCancelTrial', e.target.checked)}} />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} style={{textAlign: isBig ? 'end' : 'left'}}>
                                                            <Form.Label column sm={6} lg>Trial Role Name</Form.Label>
                                                            <Col lg={2}>
                                                                <Form.Control value={x.TrialRoleName} disabled={true} />
                                                            </Col>
                                                            <Form.Label column lg={2}>Trial RoleID</Form.Label>
                                                            <Col lg={2}>
                                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                                    <Form.Control disabled={true} value={x.TrialRoleID} />
                                                                    <SearchIcon onClick={() => {handleGetRoles(i, true)}} />
                                                                </div>
                                                            </Col>
                                                            <Form.Label column lg={2}>Opening Split</Form.Label>
                                                            <Col lg={2}>
                                                                <Form.Control value={x.OpeningSplit} onChange={(e) => {updatePlanProp(i, 'OpeningSplit', Number(e.target.value))}}  />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} style={{textAlign: isBig ? 'end' : 'left'}}>
                                                            <Form.Label column sm={6} lg>Onboarding Channel</Form.Label>
                                                            <Col lg={2}>
                                                                <Form.Control value={x.OnboardChannelName} disabled={true} />
                                                            </Col>
                                                            <Form.Label column lg={2}>Onboarding ChannelID</Form.Label>
                                                            <Col lg={2}>
                                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                                    <Form.Control disabled={true} value={x.OnboardChannelID} />
                                                                    <SearchIcon onClick={() => {setSubChannelSelector(false);setNotifSelector(false);setTestimonialSelector(false);handleChannelFind(i, true)}} />
                                                                    <DeleteIcon onClick={() => {updatePlanProp(i, 'OnboardChannelID', '');updatePlanProp(i, 'OnboardChannelName', '')}} />
                                                                </div>
                                                            </Col>
                                                            <Form.Label column lg={2}>Closing Split</Form.Label>
                                                            <Col lg={2}>
                                                                <Form.Control value={x.ClosingSplit} onChange={(e) => {updatePlanProp(i, 'ClosingSplit', Number(e.target.value))}} />
                                                            </Col>
                                                        </Form.Group>
                                                    </Form>
                                                    <ListGroup>
                                                        <ListGroup.Item variant="info"style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                                            <b>Pricing</b>
                                                            <Button variant="secondary" onClick={() => {addPriceHandler(i)}}>Add Price</Button>
                                                        </ListGroup.Item>
                                                        {x.DiscordStripePlanFrequencies && x.DiscordStripePlanFrequencies.map((y, j) => {
                                                            return (
                                                                <ListGroup.Item variant="secondary">
                                                                    <Form>
                                                                        <Form.Group as={Row} style={{textAlign: isBig ? 'end' : 'left'}}>
                                                                            <Col lg={1} style={{display: 'flex', alignItems: 'center'}}>
                                                                                <Form.Check id={`enabled${i}|${j}`} type="switch" label="Enabled" style={{display: 'flex'}}
                                                                                     checked={y.Enabled} onChange={(e) => {updatePlanFreqProp(i, j, 'Enabled', e.target.checked)}} 
                                                                                />
                                                                            </Col>
                                                                            <Form.Label column lg={2}>PriceID</Form.Label>
                                                                            <Col lg={2}>
                                                                                <Form.Control value={y.PriceID} onChange={(e) => {updatePlanFreqProp(i, j, 'PriceID', e.target.value)}}  />
                                                                            </Col>
                                                                            <Form.Label column lg={1}>Cost</Form.Label>
                                                                            <Col lg={2}>
                                                                                <Form.Control value={y.Cost} onChange={(e) => {updatePlanFreqProp(i, j, 'Cost', e.target.value)}}  />
                                                                            </Col>
                                                                            <Form.Label column lg={2}>Frequency</Form.Label>
                                                                            <Col lg={2}>
                                                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                                                <Form.Control as="select"  value={y.Frequency.toString()} onChange={(e) => {updatePlanFreqProp(i, j, 'Frequency', e.target.value)}} >
                                                                                    <option value="2">Monthly</option>
                                                                                    <option value="3">Quarterly</option>
                                                                                    <option value="4">Semiannually</option>
                                                                                    <option value="5">Yearly</option>
                                                                                    <option value="0">Daily</option>
                                                                                    <option value="1">Weekly</option>
                                                                                    <option value="6">Lifetime</option>
                                                                                </Form.Control>
                                                                                {!y.PlanFreqID && (
                                                                                    <DeleteIcon style={{marginLeft: '5px'}} onClick={() => {removePriceHandler(i, j)}} />
                                                                                )}
                                                                                </div>
                                                                            </Col>
                                                                        </Form.Group>
                                                                        <Form.Group as={Row} style={{textAlign: isBig ? 'end' : 'left'}}>
                                                                            <Form.Label column lg={1}>New Affiliate</Form.Label>
                                                                            <Col lg={2}>
                                                                                <Form.Control value={y.NewAffiliateSaleComm} onChange={(e) => {updatePlanFreqProp(i, j, 'NewAffiliateSaleComm', e.target.value)}} onBlur={(e) => {updatePlanFreqProp(i, j, 'NewAffiliateSaleComm', Number(e.target.value))}} />
                                                                            </Col>
                                                                            <Form.Label column lg={1}>Recur Affiliate</Form.Label>
                                                                            <Col lg={2}>
                                                                                <Form.Control value={y.RecurringAffiliateSaleComm} onChange={(e) => {updatePlanFreqProp(i, j, 'RecurringAffiliateSaleComm', e.target.value)}} onBlur={(e) => {updatePlanFreqProp(i, j, 'RecurringAffiliateSaleComm', Number(e.target.value))}} />
                                                                            </Col>
                                                                            <Form.Label column lg={1}>New Manager</Form.Label>
                                                                            <Col lg={2}>
                                                                                <Form.Control value={y.NewSaleComm} onChange={(e) => {updatePlanFreqProp(i, j, 'NewSaleComm', e.target.value)}} onBlur={(e) => {updatePlanFreqProp(i, j, 'NewSaleComm', Number(e.target.value))}} />
                                                                            </Col>
                                                                            <Form.Label column lg={1}>Recur Manager</Form.Label>
                                                                            <Col lg={2}>
                                                                                <Form.Control value={y.RecurringSaleComm} onChange={(e) => {updatePlanFreqProp(i, j, 'RecurringSaleComm', e.target.value)}} onBlur={(e) => {updatePlanFreqProp(i, j, 'RecurringSaleComm', Number(e.target.value))}} />
                                                                            </Col>
                                                                        </Form.Group>
                                                                    </Form>
                                                                </ListGroup.Item>
                                                            )
                                                        })
                                                    }
                                                    </ListGroup>
                                                    <Row>
                                                    <Col lg={6} style={{marginTop: '10px'}}>
                                                        <ListGroup>
                                                            <ListGroup.Item variant="info"style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                                                <b>Content</b>
                                                                <Button variant="secondary" onClick={() => {setPlanSelecting(i);setShowContentModal(true)}}>Add Content</Button>
                                                            </ListGroup.Item>
                                                            {x.DiscordPlanContents && x.DiscordPlanContents.map((y, j) => {
                                                                const matchContent = dmState.DiscordContents.filter(z => z.RequestID === y.RequestID)[0]
                                                                if (!matchContent) return ''
                                                                return (
                                                                    <ListGroup.Item variant="secondary">
                                                                        <Form>
                                                                            <Form.Group as={Row} style={{textAlign: 'center'}}>
                                                                                <Form.Label column>{matchContent.Name}</Form.Label>
                                                                                <DeleteIcon style={{marginRight: '10px', cursor: 'pointer'}} onClick={() => removeContent(i, j)} />
                                                                            </Form.Group>
                                                                        </Form>
                                                                    </ListGroup.Item>
                                                                )
                                                            })}
                                                        </ListGroup>
                                                    </Col>
                                                    <Col lg={6} style={{marginTop: '10px'}}>
                                                        <ListGroup>
                                                            <ListGroup.Item variant="info"style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                                                <b>Channels</b>
                                                                <Button variant="secondary" onClick={() => {setSubChannelSelector(true);setNotifSelector(false);setTestimonialSelector(false);handleChannelFind(i)}}>Add Channel</Button>
                                                            </ListGroup.Item>
                                                            {x.DiscordAccountSubscribableChannels && x.DiscordAccountSubscribableChannels.map((y, j) => {
                                                                return (
                                                                    <ListGroup.Item variant="secondary">
                                                                        <Form>
                                                                            <Form.Group as={Row} style={{textAlign: isBig ? 'end' : 'left'}}>
                                                                                <Form.Label column lg={2}>Name</Form.Label>
                                                                                <Col lg={3}>
                                                                                    <Form.Control value={y.Name}  />
                                                                                </Col>
                                                                                <Form.Label column lg={2}>Channel ID</Form.Label>
                                                                                <Col lg={3}>
                                                                                    <Form.Control value={y.ChannelID} />
                                                                                </Col>
                                                                                <Col>
                                                                                    <DeleteIcon style={{marginRight: '10px', cursor: 'pointer'}} onClick={() => removeChannel(i, j)} />
                                                                                </Col>
                                                                            </Form.Group>
                                                                        </Form>
                                                                    </ListGroup.Item>
                                                                )
                                                            })}
                                                        </ListGroup>
                                                    </Col>
                                                    </Row>
                                            </ListGroup.Item>
                                        </ListGroup>
                                        )
                                    })}
                                </Card.Body>
                            </Card>
                        </Card.Body>
                    </Card>
                </Fragment>
            )}
            {page === USER_PAGE && (
                <DiscordManagerUsersLite users={planUsers} notify={notify} owner={true} refresh={getPlanUsers} />
            )}
            {page === CONTENT_PAGE && (
                <ManagerContent notify={notify} />
            )}
            {page === PAYMENTS_PAGE && (
                <ManagerPayments notify={notify} />
            )}
            {page === CSS_PAGE && (
                <OwnerCSS notify={notify} />
            )}
            {page === QBO_PAGE && (
                <QuickBooks notify={notify} />
            )}
            <div style={{position: 'fixed', top: '20px', right: '20px'}}>
                {toasts.map(x => {
                    return <Toasty title={x.Title} message={x.Message} />
                })}
            </div>
            <Modal show={showServerModal} onHide={() => {setShowServerModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Server Linking</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {servers.map(x => {
                            return (
                                <ListGroup.Item onClick={() => {handleGuildSelect(x.ID)}}>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        {x.Icon ? <img src={`https://cdn.discordapp.com/icons/${x.ID}/${x.Icon}.png?size=32`} /> : <span>&nbsp;</span>}
                                        <span>{x.Name}</span>
                                    </div>
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </Modal.Body>
            </Modal>
            <Modal show={showRoleModal} onHide={() => {setShowRoleModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Role Selection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {serverRoles.map(x => {
                            let color = x.Color
                            if (color === '0'){
                                color = '000'
                            }
                            return (
                                <ListGroup.Item onClick={() => {handleRoleSelect(x.ID, x.Name)}}>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <span style={{color: `#${color}`}}>{x.Name}</span>
                                    </div>
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </Modal.Body>
            </Modal>
            <Modal show={showChannelModal} onHide={() => {setShowChannelModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Channel Selection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {channels.map(x => {
                            return (
                                <ListGroup.Item onClick={() => {handleChannelSelect(x.ID, x.Name)}}>
                                    {x.Name}
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </Modal.Body>
            </Modal>
            <Modal show={showContentModal} onHide={() => {setShowContentModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Content Selection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {dmState.DiscordContents && dmState.DiscordContents.map(x => {
                            return (
                                <ListGroup.Item onClick={() => {handleContentSelect(x.RequestID)}}>
                                    {x.Name}
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </Modal.Body>
            </Modal>
            <Modal show={showPromoModal} onHide={() => setShowPromoModal(false)} backdrop="static" size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Promo Codes
                    </Modal.Title>
                </Modal.Header>
                {promoLoading 
                ? (
                    <Modal.Body style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <CircularProgress style={{width: '100px', height: '100px'}} />
                    </Modal.Body>
                )
                : (<Fragment>
                    <Modal.Body>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>Code</th>
                                    <th>Pixel ID</th>
                                    <th>Aquisitions</th>
                                    <th>Conversions</th>
                                    <th style={{textAlign: 'center'}}>Active</th>
                                    <th style={{textAlign: 'center', color: 'green'}}><AddIcon onClick={addPromo} /></th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {displayPromos.map((x, i) => {
                                    return (
                                        <tr>
                                            <td><Form.Control value={x.PromoCode} onChange={(e) => updatePromo(i, 'PromoCode', e.target.value)} /></td>
                                            <td><Form.Control value={x.PixelId} onChange={(e) => updatePromo(i, 'PixelId', e.target.value)} /></td>
                                            <td>{x.Aquisitions}</td>
                                            <td>{x.Conversions}</td>
                                            <td style={{textAlign: 'center'}}>
                                                <Form.Check type="checkbox" checked={x.Active} onChange={(e) => updatePromo(i, 'Active', e.target.checked)} />
                                            </td>
                                            <td style={{textAlign: 'center', color: 'darkred'}}>
                                                <DeleteIcon onClick={() => removePromo(i)} />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    {promoPlanID && 
                        <Modal.Footer>
                            <Button variant="success" onClick={savePromos}>Save</Button>
                        </Modal.Footer>
                    }
                </Fragment>
                )}
            </Modal>
        </Container>
    )
}
