import {baseURL} from '../base'

const controller = `${baseURL}/discordmanager/v1`

export const uploadEndpoint = `${controller}/addcontent/`

export async function getAllDMSettingsAPI(userID, idToken, access){
    const response = await fetch(`${controller}/settings/${userID}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        }
    })
    return response
}

export async function setAllDMSettingsAPI(userID, idToken, access, account){
    const response = await fetch(`${controller}/setsettings/${userID}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
        body: JSON.stringify(account)
    })
    return response
}

export async function getAllDMPlanUsersAPI(userID, idToken, access){
    const response = await fetch(`${controller}/lazyplanusers/${userID}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        }
    })
    return response
}

export async function getOwnerManagerPayments(userID, idToken, access, managerID){
    const response = await fetch(`${controller}/checkmanagerpayments/${userID}/${managerID}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        }
    })
    return response
}

export async function getOwnerManagers(userID, idToken, access){
    const response = await fetch(`${controller}/getmanagers/${userID}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        }
    })
    return response
}

export async function getOwnerManagersAffiliates(userID, idToken, access){
    const response = await fetch(`${controller}/getmanagerswithaffiliates/${userID}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        }
    })
    return response
}

export async function getAllDMPlanUsersLiteAPI(userID, idToken, access, info){
    const response = await fetch(`${controller}/planuserslite/${userID}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
        body: JSON.stringify(info)
    })
    return response
}

// export async function UpdateDMPlanUserAPI(userID, idToken, access, user){
//     const response = await fetch(`${controller}/planusers/${userID}`, {
//         method: 'POST',
//         mode: 'cors',
//         headers: {
//             'Content-Type': 'application/json; charset=utf-8',
//             'Authorization': `bearer ${idToken}`,
//             'Access': access
//         },
//         body: JSON.stringify(user)
//     })
//     return response
// }

export async function getDMGuildRolesAPI(userID, idToken, access, guildID){
    const response = await fetch(`${controller}/guildroles/${userID}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
        body: JSON.stringify(guildID)
    })
    return response
}

export async function saveFullUserAPI(userID, idToken, access, request){
    const response = await fetch(`${controller}/savefulluser/${userID}`, {
        method: 'PATCH',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function setUserCanManageAPI(userID, idToken, access, request){
    const response = await fetch(`${controller}/usercanmanage/${userID}`, {
        method: 'PATCH',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function getReferralCodeAPI(userID, idToken, access, request){
    const response = await fetch(`${controller}/referralcode/${userID}`, {
        method: 'PATCH',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function setUserManagerAPI(userID, idToken, access, request){
    const response = await fetch(`${controller}/setmanager/${userID}`, {
        method: 'PATCH',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function getDMChannelsAPI(userID, idToken, access){
    const response = await fetch(`${controller}/getchannels/${userID}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        }
    })
    return response
}

export async function getAllContent(userID, idToken, access){
    const response = await fetch(`${controller}/getcontent/${userID}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        }
    })
    return response
}

export async function getPlanPromosAPI(userID, idToken, access, planId){
    const response = await fetch(`${controller}/getpromos/${userID}/${planId}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        }
    })
    return response
}


export async function updatePlanPromosAPI(userID, idToken, access, planId, data){
    const response = await fetch(`${controller}/updatepromos/${userID}/${planId}`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
        body: JSON.stringify(data)
    })
    return response
}

export async function getCSSAPI(userID, idToken, access){
    const response = await fetch(`${controller}/getcss/${userID}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        }
    })
    return response
}

export async function updateCSSAPI(userID, idToken, access, data){
    const response = await fetch(`${controller}/updatecss/${userID}`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
        body: JSON.stringify(data)
    })
    return response
}

export async function getAuditAPI(userID, idToken, access, data){
    const response = await fetch(`${controller}/getaudits/${userID}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
        body: JSON.stringify(data)
    })
    return response
}