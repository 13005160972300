import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Navbar, Nav } from 'react-bootstrap'


export const GeneralNav = () => {
    
    const user = useSelector(state => state.user)
    const sameDayToken = new Date(user.tokenTime).getDate() === new Date().getDate()
    const loggedIn = user.IdToken && sameDayToken


    return (
        <Navbar bg="dark" variant="dark" expand="lg" style={{marginBottom: '20px'}}>
            <Navbar.Brand href="/">Varbis.NET</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    {!loggedIn ? (
                        <Fragment>
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/features">Features</Nav.Link>
                            <Nav.Link href="/pricing">Pricing</Nav.Link>
                        </Fragment>
                    ) : (
                        <Fragment>
                            {(user.Flags & 2) === 2 ? <Nav.Link href="/ally">Ally [BETA]</Nav.Link> : ''}
                            {(user.Flags & 8) === 8 && <Nav.Link href="/td">TD [BETA]</Nav.Link>}
                            {user.DiscordManager && (                                
                                <Nav.Link href="/discordmanager">Discord Manager</Nav.Link>
                            )}
                            {user.CanAccessOtherManagers && (
                                <Nav.Link href="/usermanager">User Manager</Nav.Link>
                            )}
                            {(user.Flags & 1024) === 1024 && (
                                <Nav.Link href="/sendsms">Send SMS</Nav.Link>
                            )}
                        </Fragment>
                    )}
                </Nav>
                <Nav>
                {!loggedIn ? (
                    <Fragment>
                        <Nav.Link href="/signup">Sign Up</Nav.Link>
                        <Nav.Link href="/signin">Sign In</Nav.Link>
                    </Fragment>
                ) : (
                    <Fragment>
                        <Nav.Link href="/user">User</Nav.Link>
                        <Nav.Link href="/signout">Sign Out</Nav.Link>
                    </Fragment>
                )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}