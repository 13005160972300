import {LOAD_SETTINGS} from '../actions/discordmanager'
import {SIGN_OUT} from '../actions/user'

export default function discordManager(state = {}, action) {
	switch (action.type) {
        case LOAD_SETTINGS :
            return {
                ...state,
                ...action.data
            }
        case SIGN_OUT :
            return {}
        default :
			return state
    }
}