import React, { useEffect } from 'react'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import Helmet from 'react-helmet'

import discordManagerIMG from '../../images/Features/discord_manager.png'
import welcomeIMG from '../../images/Features/product_welcome.png'
import ourServicesIMG from '../../images/Features/our_services.png'
import frontEndExpIMG from '../../images/Features/front_end_experience.png'
import frontEndMngExpIMG from '../../images/Features/front_end_manager_experience.png'
import backEndSystemIMG from '../../images/Features/back_end_system.png'
import backEndDiscordIMG from '../../images/Features/back_end_discord.png'
import contentSMSIMG from '../../images/Features/content_and_sms.png'
import additionalFeaturesIMG from '../../images/Features/additional_product_features.png'
import scheduleDemoIMG from '../../images/Features/schedule_demo.png'

export const FeaturesMobile = () => {

    useEffect(() => {
        setTimeout(() => {window.dispatchEvent(new Event('resize'))}, 500)
    })

    const isBig = useMediaQuery('(min-width:600px)');
    return (
        <div style={{width: '100vw', display: 'flex', justifyContent: 'center'}}>
            <Helmet>
                <meta name="description" content="Varbis Discord Subscription Manager Features" />
                <title>Features - Varbis.NET</title>
            </Helmet>
            {isBig 
            ? (
                <div style={{width: isBig ? '75vw' : '100vw'}}>
                    <iframe src="https://my.visme.co/_embed/010qj74r-varbis?responsive=1" className="visme" data-projectid="010qj74r-varbis" width="1440" height="809" allowFullScreen={true} webkitallowfullscreen="true" mozallowfullscreen="true" 
                    // style="border: none; width: 1440px; overflow: hidden;"
                        style={{border: 'none', width: '75vw', overflow: 'hidden', height: '90vh'}}
                    ></iframe>
                </div>
            )
            : (
                <div>
                    <img src={discordManagerIMG} alt="discord manager" style={{width: '100vw'}} />
                    <img src={welcomeIMG} alt="product welcome" style={{width: '100vw'}} />
                    <img src={ourServicesIMG} alt="our services" style={{width: '100vw'}} />
                    <img src={frontEndExpIMG} alt="front end experience" style={{width: '100vw'}} />
                    <img src={frontEndMngExpIMG} alt="front end manager experience" style={{width: '100vw'}} />
                    <img src={contentSMSIMG} alt="content and sms text experience" style={{width: '100vw'}} />
                    <img src={backEndSystemIMG} alt="back end experience" style={{width: '100vw'}} />
                    <img src={backEndDiscordIMG} alt="back end discord experience" style={{width: '100vw'}} />
                    <img src={additionalFeaturesIMG} alt="additional features" style={{width: '100vw'}} />
                    <img src={scheduleDemoIMG} alt="schedule demo" style={{width: '100vw'}} />
                </div>
            )}
        </div>
    )
}