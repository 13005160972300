import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Card, ListGroup, Table, Form, FormControl, Button, Modal, OverlayTrigger, PopoverTitle, PopoverContent, FormCheck } from 'react-bootstrap'
import { makeStyles } from '@material-ui/styles'
import { handleErrors } from '../../../api/base'
import { refreshAPI } from '../../../api/user/auth'
import { validateSignIn, signOutAction } from '../../../actions/user'
import { getAccountInfo, getOrders, getStrikes, getExpirations, getQuote, previewOrderAPI, placeOrderAPI } from '../../../api/trading/ally'
import { formatNegative, zeroIfNull } from '../../../utility/numberFormat'
import OrderOptions from '@material-ui/icons/MenuOpenRounded'
import { Popover, FormGroup } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'


import { placeTDOrderAPI, getTDQuote } from '../../../api/trading/td'
import { setTDSymbolAction, setTDStockToggleAction, setTDOptionSideAction } from '../../../actions/td'

const useStyles = makeStyles({
    spaced: {
        margin: '3px'
    }
})

export const TDTrade = ({toasts, setToasts, ...props}) => {
    const classes = useStyles()
    const {getExps, getStks, notify,
        expiration, setExpiration, strike, setStrike, 
         setExpirations, side, setSide, setDDLDefaults, getQte,
          quote, quantity, setQuantity, loadInfo} = props
    const [price, setPrice] = useState('0')
    const [stopPrice, setStopPrice] = useState('0')
    const [orderType, setOrderType] = useState('1')
    const [orderLength, setOrderLength] = useState('0')
    // const [optionSide, setOptionSide] = useState('0')
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const tdState = useSelector(state => state.td)

    const [fullQuote, setFullQuote] = useState({})
    
    const [message, setMessage] = useState('')
    const [orderPreview, setOrderPreview] = useState(null)
    const [showModal, setShowModal] = useState(false)

    // map store to vars
    const symbol = tdState.symbol
    const setSymbol = (x) => {dispatch(setTDSymbolAction(x))}
    const stockToggle = tdState.stockToggle
    const setStockToggle = (x) => {dispatch(setTDStockToggleAction(x))}
    const strikes = tdState.strikes
    const expirations = tdState.expirations

    let optionSide = tdState.optionSide
    const setOptionSide = (x) => {dispatch(setTDOptionSideAction(x))}
    const discordAllowed = (user.Flags & 512) === 512
    const discordState = useSelector(state => state.discord)
    const [toDiscord, setToDiscord] = useState(discordState.guilds && discordState.guilds.length && discordAllowed)
    let totalChannels = []
    discordState.guilds && discordState.guilds.length && discordState.guilds.forEach(x => {
        x.DiscordChannels.forEach(y => {
            totalChannels.push({
                name: y.Name,
                selected: y.DefaultOn,
                id: y.Id
            })
        })
    })
    const [discordChannels, setDiscordChannels] = useState(totalChannels)

    let priceColor = 'white'
    if (tdState.priceColor){
        priceColor = tdState.priceColor
    }
    if (!optionSide){
        optionSide = '0'
    }

    useEffect(() => {
        try{
            const interval = setInterval(() => {
                getQte()
            }, 20000);
            return () => clearInterval(interval)
        }
        catch (err){
            console.log(err)
        }
    }, [])





    const updateData = (force) => {
        let load = false
        if (force !== undefined){
            load = force
        }
        else{
            load = stockToggle
        }
        if (load){            
            getExps(symbol)
                .then(() => {
                getQte(symbol)
                    .then(() => {
                        const expir = document.getElementById('tdExpDDL').value
                        getStks(`${symbol}|${expir}`)
                    })
                })
        }
        else{
            getQte(symbol)
        }
    }

    const handleUpdateQuote = () => {
        try{
            if (!symbol || !expiration || !strike){
                return
            }
            let test = symbol
            test += '_'
            let date = new Date(expiration)
            test += (date.getMonth() + 1).toString().padStart(2, '0')
            test += date.getDate().toString().padStart(2, '0')
            test += date.getFullYear().toString().slice(-2)
            test += Number(optionSide) ? 'P' : 'C'
            test += Number(strike) //.toFixed(3).replace('.', '').padStart(8, '0')

            getTDQuote(test, user.UserID, user.IdToken, user.AccessToken, tdState.token.access_token)
                .then(handleErrors)
                .then(result => {
                    if (result == null){
                        return
                    }
                    setFullQuote(result)
                })
                .catch(error => error)
                .then(msg => {
                    if (msg) {
                        setMessage(msg)
                    }
                })
        }
        catch (err) { }
    }


    const generateTradeOrder = () => {
        let order = {
            SecurityType: stockToggle ? 'OPT' : 'CS',
            Quantity: zeroIfNull(quantity),
            Price: zeroIfNull(price),
            StopPrice: zeroIfNull(stopPrice),
            OrderAction: 0, // New
            TimeInForce: zeroIfNull(orderLength),
            Symbol: symbol,
            OrderType: zeroIfNull(orderType),

        }
        if (stockToggle){
            //option
            const sideParts = side.split('|')
            order.OrderSide = zeroIfNull(sideParts[0])
            order.PositionEffect = sideParts[1]
            order.Strike = zeroIfNull(strike)
            order.Expiration = new Date(expiration)
            order.OptionType = zeroIfNull(optionSide)
        }
        else{
            //stock
            order.OrderSide = zeroIfNull(side)
        }
        if (toDiscord){
            order.DiscordChannelIDs = discordChannels.filter(x => x.selected).map(x => x.id)
        }
        return order
    }

    
    const placeOrder = () => {
        if (!tdState.token || !tdState.token.access_token){
            return
        }
        let tsts = notify('Sending', 'Placing order...')
        const order = generateTradeOrder()
        placeTDOrderAPI(order, user.UserID, user.IdToken, user.AccessToken, tdState.token.access_token)
            .then(handleErrors)
            .then(result => {
                if (result.Error){
                    notify('Error', `${result.Error}`, tsts)
                }
                else if (result.Message){
                    notify('Order Confirmation', `${result.Message}`, tsts)
                }
                else{
                    notify('Order Confirmation', `${result.Response}`, tsts)
                }
                setTimeout(loadInfo, 500)
            })
            .catch(error => error)
            .then(msg => {
                if (msg) {
                    notify('Error', msg.toString(), tsts)
                }
            })
    }


    const [anchorEl, setAnchorEl] = useState(null)

    const settingsPopOpen = Boolean(anchorEl)
    const settingsPopid = settingsPopOpen ? 'orderOptions' : undefined

    const handleDiscordSettingsClick = (e) => {
        setAnchorEl(e.currentTarget)
    }

    const handleDiscordSelect = (id, checked) => {
        let newChannels = discordChannels.map(x => {
            if (x.id === id){
                x.selected = checked
            }
            return x
        })
        setDiscordChannels(newChannels)
    }

    return (
        <Card bg="dark">
                <Card.Header as={Row} style={{color: 'white', marginRight: '0px', marginLeft: '0px'}}>
                    <Col style={{display: 'flex'}} sm={6} lg={3}>
                        <b style={{marginRight: '20px'}}>Trade</b>
                        <Form>
                            <Form.Check checked={stockToggle} type="switch" id="tdStockToggle" label={stockToggle ? "Options" : "Stock"} onChange={(e) => {setStockToggle(e.target.checked); updateData(e.target.checked);}} />
                        </Form>
                    </Col>
                    <Col sm={6} lg={3} style={{display: 'flex', justifyContent: 'flex-end', color: (tdState.priceColor || 'white')}}>
                        {quote ? `$${quote.Last.toFixed(4)}` : ''}
                    </Col>
                    <Col sm={12} lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        {quote ? quote.Name : ''}
                    </Col>
                </Card.Header>
                <Card.Body>
                    <Form style={{color: 'white'}}>
                        <Form.Group as={Row} controlId="firstRow">
                            <Form.Label column sm={6} lg={3}>
                                Symbol
                            </Form.Label>
                            <Col sm={6} lg={3}>
                                <Form.Control placeholder="Symbol" id="tdSymbol" value={symbol} onChange={(e) => {setSymbol(e.target.value)}} onBlur={(e) => {updateData();}} />
                            </Col>
                            <Form.Label column sm={6} lg={3}>
                                Order Side
                            </Form.Label>
                            <Col sm={6} lg={3}>
                                <Form.Control value={side} as="select" id="sideDDL" onChange={(e) => {setSide(e.target.value)}}>
                                    {!stockToggle ? (
                                        <Fragment>
                                            <option value="1">Buy</option>
                                            <option value="2">Sell</option>
                                            <option value="10">Buy To Cover</option>
                                            <option value="5">Sell Short</option>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <option value="1|O">Buy To Open</option>
                                            <option value="2|O">Sell To Open</option>
                                            <option value="1|C">Buy To Close</option>
                                            <option value="2|C">Sell To Close</option>
                                        </Fragment>
                                    )}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="secondRow">
                            <Form.Label column sm={6} lg={3}>
                                Order Type
                            </Form.Label>
                            <Col sm={6} lg={3}>
                                <Form.Control id="allyOrderTypeDDL" as="select" onChange={(e) => {setOrderType(e.target.value)}}>
                                    <option value="1">Market</option>
                                    <option value="2">Limit</option>
                                    <option value="3">Stop</option>
                                    <option value="4">Stop Limit</option>
                                </Form.Control>
                            </Col>
                            {orderType === '1' || orderType === '3' ?
                            <Fragment>
                                <Col sm={6} lg={3}></Col>
                                <Col sm={6} lg={3}></Col>
                            </Fragment>
                            :
                            <Fragment>
                                <Form.Label column sm={6} lg={3}>
                                    Price
                                </Form.Label>
                                <Col sm={6} lg={3}>
                                    <Form.Control value={price} id="allyPrice" placeholder="0.00" onChange={(e) => {setPrice(e.target.value)}} />
                                </Col>
                            </Fragment>
                            }
                        </Form.Group>
                        <Form.Group as={Row} controlId="thirdRow">
                            <Form.Label column sm={6} lg={3}>
                                Order Length
                            </Form.Label>
                            <Col sm={6} lg={3}>
                                <Form.Control as="select" onChange={(e) => {setOrderLength(e.target.value)}}>
                                    <option value="0">Day</option>
                                    <option value="1">GTC</option>
                                </Form.Control>
                            </Col>
                            {orderType === '4' || orderType === '3' ?
                            <Fragment>
                                <Form.Label column sm={6} lg={3}>
                                    Stop Price
                                </Form.Label>
                                <Col sm={6} lg={3}>
                                    <FormControl value={stopPrice} id="allyStop" placeholder="0.00" onChange={(e) => {setStopPrice(e.target.value)}} />
                                </Col>
                            </Fragment>
                            :
                            <Fragment>
                                <Col sm={6} lg={3}></Col>
                                <Col sm={6} lg={3}></Col>
                            </Fragment>
                            }
                        </Form.Group>
                        <Form.Group as={Row} controlId="fifthRow">
                            <Form.Label column sm={6} lg={3}>
                                Quantity
                            </Form.Label>
                            <Col sm={6} lg={3}>
                                <Form.Control value={quantity} id="allyQty" placeholder="0" onChange={(e) => {setQuantity(e.target.value)}} />
                            </Col>
                            {stockToggle ? 
                            <Fragment>
                                <Form.Label column sm={6} lg={3}>
                                    Option Side
                                </Form.Label>
                                <Col sm={6} lg={3}>
                                    <Form.Control value={optionSide} id="allyOptSide" as="select" onChange={(e) => {setOptionSide(e.target.value)}}>
                                        <option value="0">Call</option>
                                        <option value="1">Put</option>
                                    </Form.Control>
                                </Col>
                            </Fragment>
                            : <Fragment>
                                <Col sm={6} lg={3}></Col>
                                <Col sm={6} lg={3}></Col>
                            </Fragment>
                            }
                        </Form.Group>
                        {stockToggle ?
                            <Fragment>
                            <Form.Group as={Row} controlId="fourthRow">
                                <Form.Label column sm={6} lg={3}>
                                    Strike
                                </Form.Label>
                                <Col sm={6} lg={3}>
                                    <Form.Control value={strike} id="tdStrikeDDL" as="select" onChange={(e) => {setStrike(e.target.value)}}>
                                        {strikes && strikes.map(x => 
                                            <option value={`${x.Price}`}>${x.Price}</option>                                        
                                        )}
                                    </Form.Control>
                                </Col>
                                <Form.Label column sm={6} lg={3}>
                                    Expiration
                                </Form.Label>
                                <Col sm={6} lg={3}>
                                    <Form.Control value={expiration} id="tdExpDDL" as="select" onChange={(e) => {setExpiration(e.target.value); getStks(undefined, false, e.target.value)}}>
                                        {expirations && expirations.map(x => (
                                            <option value={`${x}`}>{x}</option>
                                        ))}
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            </Fragment>
                            : ''
                        }                        
                        <Form.Group as={Row} controlId="sixthRow">
                            <Col sm={12} lg={6}>
                                <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                                    {/* <Button variant="secondary" className={classes.spaced}>Save Order</Button> */}
                                    <Button variant="secondary" onClick={placeOrder} className={classes.spaced}>Place Order</Button>
                                    {discordState.guilds && discordState.guilds.length && discordAllowed ? (
                                        <Fragment>
                                            <FormCheck type="checkbox" label="Post To Discord" checked={toDiscord} onChange={(e) => {setToDiscord(e.target.checked)}} />
                                            <SettingsIcon onClick={(e) => {handleDiscordSettingsClick(e)}} />
                                        </Fragment>
                                    ) : ''}
                                </div>
                            </Col>
                            <Col sm={12} lg={6}>
                                <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}} className={classes.spaced}>
                                    <span>Bid : <span onClick={(e) => {setPrice(e.target.innerText)}}>{stockToggle ? fullQuote.Bid : quote ? quote.Bid : ''}</span></span>
                                    <span>Ask : <span onClick={(e) => {setPrice(e.target.innerText)}}>{stockToggle ? fullQuote.Ask : quote ? quote.Ask : ''}</span></span>
                                    <span>Last : <span onClick={(e) => {setPrice(e.target.innerText)}}>{stockToggle ? fullQuote.Last : quote ? quote.Last : ''}</span></span>
                                    <Button variant="secondary" onClick={() => {handleUpdateQuote()}} className={classes.spaced}>Update Quote</Button>
                                </div>
                            </Col>
                        </Form.Group>
                    </Form>
                </Card.Body>
                <Modal show={showModal} onHide={() => {setShowModal(false)}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Order Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {orderPreview && orderPreview.Error && (<div>Error : {orderPreview.Error}</div>)}
                            {orderPreview && orderPreview.Warning && (<div>{orderPreview.Warning}</div>)}
                            {orderPreview && !orderPreview.Error && (
                                <Fragment>
                                    <div>Commission : {orderPreview ? orderPreview.Commission : '0'}</div>
                                    <div>Principal : {orderPreview ? orderPreview.Principal : '0'}</div>
                                    <div>Margin Requirement : {orderPreview ? orderPreview.MarginRequirement : '0'}</div>
                                </Fragment>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => {setShowModal(false)}}>Cancel</Button>
                        {orderPreview && !orderPreview.Error && (
                            <Button variant="secondary" onClick={() => {placeOrder(); setShowModal(false)}}>Place Order</Button>
                        )}
                    </Modal.Footer>
                </Modal>
                <Popover
                    id={settingsPopid}
                    open={settingsPopOpen}
                    anchorEl={anchorEl}
                    onClose={() => {setAnchorEl(null)}}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <PopoverTitle style={{backgroundColor: 'rgba(0,0,0,.03)', color: 'white'}}>Where To Post</PopoverTitle>
                    <PopoverContent>
                        <ListGroup>
                            {discordChannels.map(x => {
                                return(
                                    <ListGroup.Item variant="dark">
                                        <FormCheck type="checkbox" checked={x.selected} label={`${x.name}`} onChange={(e) => {handleDiscordSelect(x.id, e.target.checked)}} />
                                    </ListGroup.Item>
                                )
                            })}
                        </ListGroup>
                    </PopoverContent>
                </Popover>
        </Card>
    )
}