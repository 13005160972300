import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Jumbotron, Form, Button, Modal, Nav } from 'react-bootstrap'
import { makeStyles } from '@material-ui/styles'
import { handleErrors } from '../../api/base'
import { signInAPI, forgotPasswordAPI, confirmForgotAPI } from '../../api/user/auth'
import { validateSignIn } from '../../actions/user'
import { setTokenAction as setTDTokenAction } from '../../actions/td'
import { getNewTokenWithRefresh } from '../../api/trading/td'
import { getNewDiscordTokenWithRefresh, getChannels } from '../../api/user/discord'
import { loadTokenAction, savedChannelsAction } from '../../actions/discord'
import GoodIcon from '@material-ui/icons/Done'
import BadIcon from '@material-ui/icons/Error'
import Tooltip from '@material-ui/core/Tooltip'
import fullLogo from '../../images/FullLogo.png'
import Helmet from 'react-helmet'

const useStyles = makeStyles({
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    column: {
        flexDirection: 'column'
    },
    fullHeight: {
        height: '100vh'
    },
    big: {
        height: '40%',
        width: '80%'
    },
    outline: {
        border: '.2rem solid #ececec',
        borderRadius: '8px'
    },
    darkJumbo: {
        backgroundColor: '#d2d5d8'
    }
})

export const SignIn = () => {
    const classes = useStyles();
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [message, setMessage] = useState('')
    const [showForgotModal, setShowForgotModal] = useState(false)
    const [forgotEmail, setForgotEmail] = useState('')
    const [haveCode, setHaveCode] = useState(false)
    const [forgotCode, setForgotCode] = useState('')
    const [forgotPass1, setForgotPass1] = useState('')
    const [forgotPass2, setForgotPass2] = useState('')
    const [forgotMsg, setForgotMsg] = useState(null)
    const [pwGood, setPWGood] = useState(undefined)
    const [rptGood, setRptGood] = useState(null)

    const handleSignIn = (e) => {
        e.preventDefault()
        signInAPI(username, password)
            .then(handleErrors)
            .then(result => {
                dispatch(validateSignIn(result))

                // Get TD Token
                if (result.TDRefresh){
                    getNewTokenWithRefresh(result.UserID, result.IdToken, result.AccessToken, result.TDRefresh)
                        .then(handleErrors)
                        .then(tdResult => {
                            if (!tdResult || !tdResult.access_token){
                                //notify('Token Error', 'Error While Refreshing Token')
                                return
                            }
                            tdResult.refresh_token = result.TDRefresh
                            dispatch(setTDTokenAction(tdResult))
                        })
                        .catch(error => error)
                        .then(msg => {     
                            if (msg){
                                console.log(msg)
                                //notify('Error', msg.toString())
                            }
                        })
                }
                if (result.DiscordRefresh){
                    getNewDiscordTokenWithRefresh(result.UserID, result.IdToken, result.AccessToken, result.DiscordRefresh)
                        .then(handleErrors)
                        .then(discordResult => {
                            if (!discordResult || ! discordResult.access_token){
                                return
                            }
                            discordResult.refresh_token = result.DiscordRefresh
                            dispatch(loadTokenAction(discordResult))

                            getAllChannels(result, discordResult)
                        })
                        .catch(error => error)
                        .then(msg => {
                            if (msg){
                                console.log(msg)
                            }
                        })
                }
            })
            .catch(error => error)
            .then(msg => {
                if (msg) {
                    console.log(msg)
                    if (msg.message === 'Failed to fetch' || msg === '503'){
                        msg = 'Currently Under Maintenance'
                    }
                    setMessage(msg)
                }
            })
            
    }

    const getAllChannels = (user, token) => {
        return getChannels(user.UserID, user.IdToken, user.AccessToken, token.access_token)
            .then(handleErrors)
            .then(results => {
                if (!results){
                    return;
                }
                dispatch(savedChannelsAction(results))
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    // notify('Error', msg.toString())
                }
            })
    }

    const handleSendCode = (e) => {
        e.preventDefault()

        return forgotPasswordAPI(forgotEmail)
            .then(handleErrors)
            .then(results => {
                if (results && results.includes('@')){
                    setForgotMsg(`Email Sent!`)
                    setHaveCode(true)
                }
                else{
                    setForgotMsg('Email Not Found')
                }
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    setForgotMsg(msg)
                }
            })
    }

    const handleConfirm = (e) => {
        e.preventDefault()
        const data = {
            Username: forgotEmail,
            Code: forgotCode,
            NewPassword: forgotPass1
        }
        return confirmForgotAPI(data)
            .then(handleErrors)
            .then(results => {
                if (results === 'Success'){
                    setShowForgotModal(false)
                    setMessage('Your Password Has Been Reset')
                    setHaveCode(false)
                    setForgotEmail('')
                    setForgotCode('')
                    setForgotPass1('')
                    setForgotPass2('')
                }
                else{
                    setForgotMsg('Error')
                }
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    setForgotMsg('Error Setting Password')
                }
            })
    }

    const handlePWCheck = (pw) => {
        const valid = pw.length >= 8 && pw.match(/[A-Z]/) && pw.match(/[a-z]/) && pw.match(/\d/)
        if (pw === ''){
            setPWGood(undefined)
        }
        else{
            setPWGood(valid)
        }
    }

    const handleRptPWCheck = (pw1, pw2) => {
        if (pw2 === undefined){
            pw2 = forgotPass1
        }
        if (pw1 === undefined){
            pw1 = forgotPass2
        }
        const valid = pw1 === pw2
        if (pw1 === '' || pw2 === ''){
            setRptGood(null)
        }
        else{
            setRptGood(valid)
        }
    }

    return (
        <Container fluid>
            <Helmet>
                <meta name="description" content="Varbis Discord Subscription Manager Sign In" />
                <title>Sign In - Varbis.NET</title>
            </Helmet>
            <Row>
                <Col xs={0} md={2} lg={3}></Col>
                <Col xs>
                    <div className={` ${classes.fullHeight}`}>
                    <div style={{width: '100%', height: '55px', 
                        backgroundSize: 'contain', backgroundImage: `url(${fullLogo})`,
                        backgroundRepeat: 'no-repeat', backgroundPositionX: 'center',
                        marginBottom: '10vh'
                    }}>
                    </div>

                    <div>
                        <Jumbotron className={`${classes.center} ${classes.darkJumbo} ${classes.column}`} style={{width: '100%'}}>
                            <h2 style={{marginBottom: '20px'}}>Sign In</h2>
                            {message && <h5 style={{color: 'red'}}>{message.toString()}</h5>}
                            <Form onSubmit={handleSignIn}>
                                <Form.Group controlId="email">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control value={username} type="email" placeholder="Enter Email" onChange={(e) => {setUsername(e.target.value)}} />
                                </Form.Group>
                                <Form.Group controlId="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control value={password} type="password" placeholder="Password" onChange={(e) => {setPassword(e.target.value)}} />
                                </Form.Group>
                                <div className={`${classes.center}`}>
                                    <Button variant="primary" type="submit" disabled={!username || !password}>
                                        Sign In
                                    </Button>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                                    <span style={{textAlign: 'center', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setShowForgotModal(true)}>
                                        Forgot Password
                                    </span>
                                </div>    
                            </Form>
                        </Jumbotron>
                    </div>
                    </div>
                </Col>
                <Col xs={0} md={2} lg={3}></Col>
            </Row>

            <Modal show={showForgotModal} onHide={() => {setShowForgotModal(false)}} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Forgot Password</Modal.Title>
                </Modal.Header>
                {!haveCode ? (
                    <Form onSubmit={handleSendCode}>
                        <Modal.Body>        
                            {forgotMsg && (
                                <Row style={{justifyContent: 'center'}}>
                                    <h5>{forgotMsg}</h5>
                                </Row>                      
                            )}  
                            <Form.Group as={Row}>
                                <Form.Label column style={{textAlign: 'center'}}>
                                    Email Address
                                </Form.Label>
                                <Col>
                                    <Form.Control type="email" required value={forgotEmail} onChange={(e) => setForgotEmail(e.target.value)} />
                                </Col>
                            </Form.Group>                            
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" type="submit">Send Code</Button>
                            <Button onClick={() => {setHaveCode(true)}}>
                                I have the code
                            </Button>
                        </Modal.Footer>
                    </Form>
                ) : (
                    <Form onSubmit={handleConfirm}>
                        <Modal.Body>         
                            {forgotMsg && (
                                    <Row style={{justifyContent: 'center'}}>
                                        <h5>{forgotMsg}</h5>
                                    </Row>                      
                                )}  
                            <Form.Group as={Row}>
                                <Form.Label column style={{textAlign: 'center'}}>
                                    Email Address
                                </Form.Label>
                                <Col>
                                    <Form.Control type="email" required value={forgotEmail} onChange={(e) => setForgotEmail(e.target.value)} />
                                </Col>
                            </Form.Group>                    
                            <Form.Group as={Row}>
                                <Form.Label column style={{textAlign: 'center'}}>
                                    Code
                                </Form.Label>
                                <Col>
                                    <Form.Control required value={forgotCode} onChange={(e) => setForgotCode(e.target.value)} />
                                </Col>
                            </Form.Group>      
                            <Form.Group as={Row}>
                                <Form.Label column style={{textAlign: 'center'}}>
                                    New Password
                                </Form.Label>
                                <Col>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Form.Control autocomplete="new-password" required type="password" value={forgotPass1} onChange={(e) => {setForgotPass1(e.target.value);handlePWCheck(e.target.value);handleRptPWCheck(undefined, e.target.value)}} />
                                    {pwGood !== undefined && (pwGood 
                                        ? <Tooltip title="Good"><GoodIcon style={{color: 'green'}} /></Tooltip>
                                        : <Tooltip title="Must have 8 Characters, Upper, Lower, Number"><BadIcon style={{color: 'red'}} /></Tooltip>
                                    )}
                                    </div>
                                </Col>
                            </Form.Group> 
                            <Form.Group as={Row}>
                                <Form.Label column style={{textAlign: 'center'}}>
                                    Repeat New Password
                                </Form.Label>
                                <Col>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Form.Control autocomplete="new-password" required type="password" value={forgotPass2} onChange={(e) => {setForgotPass2(e.target.value);handleRptPWCheck(e.target.value)}} />
                                        {rptGood !== null && (rptGood 
                                            ? <Tooltip title="Good"><GoodIcon style={{color: 'green'}} /></Tooltip>
                                            : <Tooltip title="Must Match Password"><BadIcon style={{color: 'red'}} /></Tooltip>
                                        )}
                                    </div>
                                </Col>
                            </Form.Group>                       
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={() => setHaveCode(false)}>
                                Back
                            </Button>
                            <Button variant="success" type="submit" disabled={!forgotPass1 || !pwGood || !forgotPass2 || !rptGood}>
                                Reset Password
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Modal>
        </Container>
    )
}