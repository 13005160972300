import {baseURL} from '../base'

const controller = `${baseURL}/td/v1`

export async function getTokenFromCode(userID, idToken, access, code){
    const response = await fetch(`${controller}/oauthcode/${userID}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
        body: JSON.stringify(code)
    })
    return response
}

export async function getNewTokenWithRefresh(userID, idToken, access, refreshToken){
    const response = await fetch(`${controller}/refreshtoken/${userID}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
        body: JSON.stringify(refreshToken)
    })
    return response
}

export async function getTDAccountsAPI(userID, idToken, access, tdAccess){
    const response = await fetch(`${controller}/accounts/${userID}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access,
            'TDAccess': tdAccess
        }
    })
    return response
}

export async function setTDSettingsAPI(userID, idToken, access, accountID){
    const response = await fetch(`${controller}/settings/${userID}`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
        body: JSON.stringify({AccountID: accountID})
    })
    return response
}

export async function getTDAccountAPI(userID, idToken, access, tdAccess){
    const response = await fetch(`${controller}/account/${userID}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access,
            'TDAccess': tdAccess
        }
    })
    return response
}

export async function placeTDOrderAPI(order, userID, idToken, access, tdAccess){
    const response = await fetch(`${controller}/placeorder/${userID}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access,
            'TDAccess': tdAccess
        },
        body: JSON.stringify(order)
    })
    return response
}

export async function getTDQuote(symbol, userID, idToken, access, tdAccess){
    const response = await fetch(`${controller}/getquote/${userID}/${symbol}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access,
            'TDAccess': tdAccess
        },
    })
    return response
}


export async function getTDExpirations(symbol, userID, idToken, access, tdAccess){
    const response = await fetch(`${controller}/getexpirations/${userID}/${symbol}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access,
            'TDAccess': tdAccess
        },
    })
    return response
}


export async function getTDStrikes(symbol, userID, idToken, access, tdAccess){
    const response = await fetch(`${controller}/getstrikes/${userID}/${symbol}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access,
            'TDAccess': tdAccess
        },
    })
    return response
}


export async function resetTDSettingsAPI(userID, idToken, access){
    const response = await fetch(`${controller}/resetsettings/${userID}`, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
    })
    return response
}