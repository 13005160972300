
import { SIGN_IN, SIGN_OUT } from '../actions/user'

export default function user(state = {}, action) {
	switch (action.type) {
        //TODO DELETE PROPS THAT ARENT SENT
        case SIGN_IN :
            return {
                ...state,
                ...action.userInfo
            }
        case SIGN_OUT :
            return {}
        default :
			return state
    }
}