// export const baseURL = `http://localhost:61939`
// export const baseURL = `https://localhost:44337`
export const baseURL = `https://api.varbis.net`

// export const baseWS = `ws://localhost:61939`
export const baseWS = `wss://api.varbis.net`

export function handleErrors(response) {
    if (response.status === 401 || response.status === 404 || response.status === 503){
     throw Error(response.status)
    }
    if (!response.ok) {
      throw response.json()
    }
    if (response.status === 204){
      return {}
    }
    return response.json()
}