import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Card, ListGroup, Table, Form, FormControl, Button, Modal, Nav, Badge } from 'react-bootstrap'
import { handleErrors } from '../../api/base'
import qs from 'query-string'
import {getDiscordTokenFromCode} from '../../api/user/discord'
import { loadDUDataAction } from '../../actions/discorduser'
import { userLogin, setNewSubAPI, getDUUserAPI, refreshDUTokenAPI, getDUAccount,
     setUpdateSubAPI, setCancelSubAPI, setUpdateCustomerPaymentAPI, getCouponInfoAPI,
     setResumeSubAPI, updateSubChannelsAPI, setEndSubTrialAPI } from '../../api/user/discorduser'
import { Toasty } from '../trading/toast'
import { roundMoney } from '../../utility/numberFormat'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {loadStripe} from '@stripe/stripe-js'
import { signOutAction } from '../../actions/user'
import popupBlocked from '../../images/PopupBlocked.png'
import popupBlockedMenu from '../../images/PopupBlockedMenu.png'
import { getDUToken } from '../../api/user/discorduser'
import TierPanel from './discorduser/tierPanel'
import CheckOutWizard from './discorduser/checkoutWizard'
import CircularProgress from '@material-ui/core/CircularProgress'
import { LinkData } from './discorduser/linkData'
import Iframe from '../utility/iframe'
import { fileDownload } from '../../api/user/discorduser'


const FREQ = {
    Daily: 0,
    Weekly: 1,
    Monthly: 2,
    Quarterly: 3,
    SemiAnnualy: 4,
    Yearly: 5,
    Lifetime: 6,
    Trial: 7
}

export const DiscordUserAccount = ({match, ...props}) => {
    // Redux
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const dUser = useSelector(state => state.discordUser)
    // Local vars
    const serverURL = match.params.server
    const isBig = useMediaQuery('(min-width:900px)');
    const customLinks = ['testserver', 'slapstocks']
    // Local State
    const [toasts, setToasts] = useState([])
    const [showLinkingModal, setShowLinkingModal] = useState(false)
    const [accountData, setAccountData] = useState(null)
    const [hasSub, setHasSub] = useState(null)
    const [selectedPlan, setSelectedPlan] = useState(null)
    const [stripePromise, setStripePromise] = useState(null)
    const [paymentMethod, setPaymentMethod] = useState(null)
    const [priceID, setPriceID] = useState(null)
    const [trialOverride, setTrialOverride] = useState(false)
    const [coupon, setCoupon] = useState(null)
    const [referral, setReferral] = useState(null)
    const [showFreq, setShowFreq] = useState(false)
    const [activeFreq, setActiveFreq] = useState(null)
    const [changePayment, setChangePayment] = useState(false)
    const [refFromURL, setRefFromURL] = useState(false)
    const [promoURL, setPromoURL] = useState(null)
    const [promoPlan, setPromoPlan] = useState(null)
    const [promoDone, setPromoDone] = useState(false)
    const [showCancelModal, setShowCancelModal] = useState(false)
    const [continuePlan, setContinuePlan] = useState(false)
    const [isDone, setIsDone] = useState(false)
    const [couponInfo, setCouponInfo] = useState(null)
    const [changeNotifications, setChangeNotifications] = useState(false)
    const [viewContent, setViewContent] = useState(false)
    const [selectedSubscribes, setSelectedSubscribes] = useState([])
    const [showAuthorize, setShowAuthorize] = useState(false)
    const [authorizeFunction, setAuthorizeFunction] = useState(() => () =>{})
    const [downloadItems, setDownloadItems] = useState([])
    const [showPhoneLink, setShowPhoneLink] = useState(false)
    const [showEndTrialModal, setShowEndTrialModal] = useState(false)
    const [pixelId, setPixelId] = useState(null)
    const [buyPixelId, setBuyPixelId] = useState(null)

    const [loading, setLoading] = useState(true)

    // URL parsing
    const value = qs.parse(props.location.search);
    const ref = value.r
    const promo = value.p
    const code = value.code
    let prepopPlanID = value.t
    if (prepopPlanID){
        prepopPlanID = Number(prepopPlanID)
    }

    const linkOnly = value.l

    const resetAllState = () => {
        setAccountData(null)
        setHasSub(null)
        setSelectedPlan(null)
        setStripePromise(null)
        setPaymentMethod(null)
        setPriceID(null)
        setTrialOverride(false)
        setCoupon(null)
        setReferral(null)
        setShowFreq(false)
        setActiveFreq(null)
        setChangePayment(false)
        setRefFromURL(false)
        setPromoURL(null)
        setPromoPlan(null)
        setPromoDone(false)
        setShowCancelModal(false)
        setContinuePlan(false)
        setIsDone(false)
        setCouponInfo(null)
    }
    

    useEffect(() => {
        if (ref){
            setRefFromURL(true)
            setReferral(ref)
        }
        if (promo){
            setPromoURL(promo)
        }
        if (code){
            getDUToken(code)
                .then(handleErrors)
                .then((result) => {
                    if (!result || !result.Token || !result.Token.access_token){
                        notify('Error', 'Invalid Code!')
                        return;
                    }
                    dispatch(loadDUDataAction(result))
                    localStorage.setItem('DUToken', JSON.stringify(result))  
                    loadAccountData(result)                  
                })
                .catch(error => error)
                .then(msg => {
                    if (msg){
                        notify('Error', msg.toString())
                        setShowLinkingModal(true)
                    }
                })
        }
        else{
            let lsToken = localStorage.getItem('DUToken')
            if (lsToken){
                lsToken = JSON.parse(lsToken)
            }
            if (lsToken){
                dispatch(loadDUDataAction(lsToken))
                loadAccountData(lsToken)
            }
            else{
                if (!dUser.Token || !dUser.Token.access_token){
                    setShowLinkingModal(true)
                    checkToken()
                    window.open(userLogin(serverURL))
                }
                else{
                    setShowLinkingModal(false)
                    loadAccountData()
                }
            }
        }
    }, [])     

    const loadAccountData = async (dataToUse) => {
        if (!dataToUse){
            dataToUse = dUser
        }
        setLoading(true)
        let msg_1
        let accountResult
        try {
            const response = await getDUAccount(dataToUse.User.DiscordUserID, dataToUse.Token.access_token, serverURL)
            const results = await handleErrors(response)
            if (!results) {
                notify('Error', 'Error Getting Server Data')
                return
            }
            if (!promo && !prepopPlanID) {
                setAccountData(results)
            }
            accountResult = results
            const aID = results.DiscordAccountID

            getDUUserAPI(dataToUse.User.DiscordUserID, dataToUse.Token.access_token)
                .then(handleErrors)
                .then(uResults => {
                    if (!uResults) {
                        notify('Error', 'Error Loading User Data')
                        return
                    }
                    const formatted = {
                        User: uResults
                    }
                    dispatch(loadDUDataAction(formatted))

                    // Reset Local State
                    setChangePayment(false)
                    setContinuePlan(false)
                    setShowFreq(false)
                    setShowCancelModal(false)
                    setCoupon(null)

                    const matchingSub = uResults.DiscordUserAccounts.filter(x => {
                        return x.DiscordAccountID === aID
                    })[0]
                    setHasSub(matchingSub)
                    if (results.PKAPIKey) {
                        setStripePromise(loadStripe(results.PKAPIKey))
                    }

                    if (matchingSub) {
                        const channelLites = matchingSub.DiscordUserChannelSubscribes.map(x_1 => {
                            return {
                                ChannelID: x_1.ChannelID,
                                Name: x_1.Name
                            }
                        })
                        setSelectedSubscribes(channelLites)
                        if (matchingSub.PromoCode){
                            results.DiscordStripePlans.filter(x => x.Enabled).forEach(x => {
                                x.DiscordPromotions.filter(y => y.Active).forEach(y => {
                                    if (y === matchingSub.PromoCode){
                                        setBuyPixelId(y.PixelId)
                                    }
                                })
                            })
                        }
                    }

                    // eval scheduling
                    evalCanSchedule(matchingSub)

                    const doneState = matchingSub && matchingSub.CardHolder

                    setIsDone(doneState)
                    let selPlan = null
                    if ((doneState || !prepopPlanID) && matchingSub && matchingSub.SubscriptionID) {
                        const matchPlan = results.DiscordStripePlans.filter(x_2 => x_2.PlanID === matchingSub.PlanID)[0]
                        setSelectedPlan(matchPlan)
                        
                        const matchFreq = matchPlan.DiscordStripePlanFrequencies.filter(x_3 => x_3.PriceID == matchingSub.PriceID)[0]
                        setActiveFreq(matchFreq)
                        setPriceID(matchFreq.PriceID)
                    }                    
                    else if (prepopPlanID) {
                        const matchPlan_2 = results.DiscordStripePlans.filter(x_5 => x_5.PlanID === prepopPlanID)[0]
                        setSelectedPlan(matchPlan_2)
                        selPlan = matchPlan_2
                        if (matchPlan_2) {
                            const matchFreq_2 = getMonthlyOrLowestFreq(matchPlan_2.DiscordStripePlanFrequencies)
                            setActiveFreq(matchFreq_2)
                            setPriceID(matchFreq_2.PriceID)
                        }
                    }
                    else if (promo) {
                        const matchPlan_1 = results.DiscordStripePlans.filter(x_4 => x_4.HasTrial && x_4.DiscordPromotions.some(y => y.Active && y.PromoCode === promo))[0]
                        setPromoPlan(matchPlan_1)
                        setSelectedPlan(matchPlan_1)
                        selPlan = matchPlan_1
                        if (matchPlan_1) {
                            const matchFreq_1 = getMonthlyOrLowestFreq(matchPlan_1.DiscordStripePlanFrequencies)
                            setActiveFreq(matchFreq_1)
                            setPriceID(matchFreq_1.PriceID)
                        }
                    }

                    if (promo && selPlan){
                        const promoOnPlan = selPlan.DiscordPromotions.filter(x => x.PromoCode == promo)[0]
                        if (promoOnPlan && promoOnPlan.PixelId){
                            setPixelId(`id=${promoOnPlan.PixelId}&ev=ViewContent&cd[content_name]=${selPlan.Name}`)
                            setBuyPixelId(promoOnPlan.PixelId)
                        }
                    }
                })
                .catch(error => error)
                .then(msg => {
                    if (msg) {
                        notify('Error', msg.toString())
                    }
                    if (promo || prepopPlanID) {
                        setAccountData(results)
                    }
                    setLoading(false)
                })
            msg_1 = undefined
        }
        catch (error_1) {
            
            try{error_1 = await error_1}catch(err){}

            msg_1 = error_1
        }
        if (msg_1) {
            setLoading(false)
            if (msg_1 === 'Refresh DToken') {
                refreshDUToken()
                    .then(() => {
                        setTimeout(() => { window.location.reload() }, 2500)
                    })
            }
            else {
                notify('Error', msg_1.toString())
            }
        }
        return accountResult
    }

    const getMonthlyOrLowestFreq = (freqs) => {
        var mon = freqs.filter(x => x.Enabled && x.Frequency === FREQ.Monthly)[0];
        if (mon){
            return mon
        }
        const minFreq = Math.min.apply(Math, freqs.filter(x => x.Enabled && x.Frequency !== 6).map(o => o.Frequency))
        let bestPrice = freqs.filter(y => y.Frequency == minFreq)[0]
        if (!bestPrice){
            bestPrice = freqs[0]
        }
        return bestPrice
    }

    const refreshDUToken = () => {
        if (!(dUser && dUser.Token && dUser.Token.refresh_token)){
            dispatch(signOutAction)
            localStorage.removeItem('DUToken')
            return new Promise(resolve => setTimeout(() => resolve(), 2000))
        }
        return refreshDUTokenAPI(dUser.Token.refresh_token)
            .then(handleErrors)
            .then(results => {
                if (results){
                    const formatted = {
                        Token: results
                    }
                    dispatch(loadDUDataAction(formatted))
                    window.location.reload()
                }
                else{                    
                    dispatch(signOutAction())
                }
            })
            .catch(error => error)
            .then(msg => {
                dispatch(signOutAction())
            })
    }

    const loadUserData = () => {
        return getDUUserAPI(dUser.User.DiscordUserID, dUser.Token.access_token)
            .then(handleErrors)
            .then(results => {
                if (!results){
                    notify('Error', 'Error Loading User Data')
                    return;
                }
                const formatted = {
                    User: results
                }
                dispatch(loadDUDataAction(formatted))
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
            })
    }

    const notify = (title, msg, original) => {
        if (original === undefined){
            original = toasts
        }
        let tsts = original.map(x => x)
        tsts.push({
            Title: title,
            Message: msg
        })
        setToasts(tsts)
        return tsts
    }



    let tokenChecker = null
    const tokenCheckInterval = 1000 // every 1 second

    const checkToken = () => {
        clearTimeout(tokenChecker)

        let lsToken = localStorage.getItem('DUToken')

        if (lsToken){
            lsToken = JSON.parse(lsToken)
        }
        console.log(lsToken)
        if (lsToken && lsToken.Token && lsToken.Token.access_token){
            console.log('reloading')
            window.location = window.location.href.split("?")[0]
        }
        else{
            tokenChecker = setTimeout(checkToken, tokenCheckInterval)
        }
    }

    const startSub = async (isFreeTrial) => {
        const info = {
            PriceID: priceID,
            PaymentID: paymentMethod && paymentMethod.id,
            DiscordAccountID: accountData.DiscordAccountID,
            PlanID: selectedPlan.PlanID,
            Coupon: coupon,
            ReferralCode: referral,
            PromoCode: promo
        }
        if (buyPixelId){
            setPixelId(`id=${buyPixelId}&ev=Purchase&cd[content_name]=${selectedPlan.Name}&cd[currency]=USD&cd[value]=${getPaymentAmount(isFreeTrial)}`)
        }
        let newTsts = notify('Sending...', 'Trying to start subscription')
        setLoading(true)
        setShowAuthorize(false)
        let msg
        try {
            const response = await setNewSubAPI(dUser.User.DiscordUserID, dUser.Token.access_token, info)
            const results = await handleErrors(response)
            if (results !== 'Success') {
                notify('Error', 'Error starting subscription', newTsts)
                return
            }
            if (promo && isFreeTrial) {
                setPromoDone(true)
                notify('Done', 'Trial Started!', newTsts)
            }
            else {
                notify('Sent', 'Subscription Started', newTsts)
                const accountInfo = await loadAccountData()
                if (info.PaymentID && accountInfo && accountInfo.DiscordStripePlans){
                    const refreshedPlan = accountInfo.DiscordStripePlans.filter(x => x.PlanID === info.PlanID)[0]
                    const autoContent = refreshedPlan && refreshedPlan.DiscordContents && refreshedPlan.DiscordContents.filter(x => x.AutoDownload)
                    if (autoContent){
                        setDownloadItems(autoContent)
                    }
                }
            }
            msg = undefined
        }
        catch (error) {
            try{error = await error}catch(err){}
            msg = error
        }
        if (msg) {
            notify('Error', msg.toString(), newTsts)
            setPaymentMethod(null)
        }
        setLoading(false)
    }

    const handlePlanSelect = (fullData) => {
        setSelectedPlan(fullData)
        const bestFit = getMonthlyOrLowestFreq(fullData.DiscordStripePlanFrequencies)

        setPriceID(bestFit && bestFit.PriceID)
    }

    const handleChangePlan = () => {
        setSelectedPlan(null)
        setCouponInfo(null)
        setCoupon(null)
        setContinuePlan(false)
    }

    const updateSubscription = () => {
        let newTsts = notify('Sending...', 'Trying to update subscription')
        setLoading(true)
        setShowAuthorize(false)
        const info = {
            PriceID: priceID,
            DiscordAccountID: accountData.DiscordAccountID,
            PlanID: selectedPlan.PlanID,
            Coupon: coupon,
            ReferralCode: referral,
            PaymentID: paymentMethod && paymentMethod.id
        }
        return setUpdateSubAPI(dUser.User.DiscordUserID, dUser.Token.access_token, info)
            .then(handleErrors)
            .then(results => {
                if (results !== 'Success'){
                    notify('Error', 'Error updating subscription', newTsts)
                    return
                }
                notify('Sent', 'Subscription Updated', newTsts)
                if (buyPixelId){
                    setPixelId(`id=${buyPixelId}&ev=Purchase&cd[content_name]=${selectedPlan.Name}&cd[content_type]=update&cd[currency]=USD&cd[value]=${getPaymentAmount()}`)
                }
                loadAccountData()
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    setLoading(false)
                    setPaymentMethod(null)
                    notify('Error', msg.toString(), newTsts)
                }
            })
    }
    
    const cancelSubscription = () => {
        let newTsts = notify('Sending...', 'Trying to cancel subscription')
        const info = {
            DiscordAccountID: accountData.DiscordAccountID
        }
        setLoading(true)
        return setCancelSubAPI(dUser.User.DiscordUserID, dUser.Token.access_token, info)
            .then(handleErrors)
            .then(results => {
                if (results !== 'Success'){
                    notify('Error', 'Error canceling subscription', newTsts)
                    return
                }
                notify('Sent', 'Subscription Canceled', newTsts)
                loadAccountData()
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    setLoading(false)
                    notify('Error', msg.toString(), newTsts)
                }
            })
    }

    const endSubscriptionTrial = () => {
        let newTsts = notify('Sending...', 'Trying to end trial')
        const info = {
            DiscordAccountID: accountData.DiscordAccountID
        }
        setShowEndTrialModal(false)
        setLoading(true)
        return setEndSubTrialAPI(dUser.User.DiscordUserID, dUser.Token.access_token, info)
            .then(handleErrors)
            .then(results => {
                if (results !== 'Success'){
                    notify('Error', 'Error ending trial', newTsts)
                    return
                }
                notify('Sent', 'Trial Successfully Ended', newTsts)
                loadAccountData()
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    setLoading(false)
                    notify('Error', msg.toString(), newTsts)
                }
            })
    }

    const resumeSubscription = () => {
        let newTsts = notify('Sending...', 'Trying to resume subscription')
        const info = {
            DiscordAccountID: accountData.DiscordAccountID
        }
        setLoading(true)
        return setResumeSubAPI(dUser.User.DiscordUserID, dUser.Token.access_token, info)
            .then(handleErrors)
            .then(results => {
                if (results !== 'Success'){
                    notify('Error', 'Error resuming subscription', newTsts)
                    return
                }
                notify('Sent', 'Subscription Resumed', newTsts)
                loadAccountData()
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    setLoading(false)
                    notify('Error', msg.toString(), newTsts)
                }
            })
    }

    const updateCustomerPayment = () => {
        let newTsts = notify('Sending...', 'Trying to update payment information')
        const info = {
            DiscordAccountID: accountData.DiscordAccountID,
            PaymentID: paymentMethod.id
        }
        setLoading(true)
        return setUpdateCustomerPaymentAPI(dUser.User.DiscordUserID, dUser.Token.access_token, info)
            .then(handleErrors)
            .then(results => {
                if (results !== 'Success'){
                    notify('Error', 'Error updating payment information', newTsts)
                    return
                }
                notify('Sent', 'Payment Information Updated', newTsts)
                loadAccountData()
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    setLoading(false)
                    notify('Error', msg.toString(), newTsts)
                }
            })
    }

    const evalCanSchedule = (sub) => {
        if (!sub || !sub.PlanID || sub.SubscriptionStatus !== 'active'){
            return
        }

        const slapPlans = [9]
        const opXPlans = [6, 16]
        // Slapstocks
        if (slapPlans.includes(Number(sub.PlanID))){
            eval(`var widget = new SimplybookWidget({"widget_type":"button","url":"https://slapstocks.simplybook.me","theme":"default","theme_settings":{"timeline_show_end_time":"0","timeline_modern_display":"as_slots","sb_base_color":"#33bb60","display_item_mode":"list","booking_nav_bg_color":"#d1e9c6","body_bg_color":"#f7f7f7","dark_font_color":"#494949","light_font_color":"#ffffff","btn_color_1":"#5e7da7","sb_company_label_color":"#ffffff","hide_img_mode":"1","show_sidebar":"1","sb_busy":"#dad2ce","sb_available":"#d3e0f1"},"timeline":"modern","datepicker":"top_calendar","is_rtl":false,"app_config":{"predefined":[]},"button_title":"T3 Scheduling", "button_position":"right","button_position_offset":"90%"});`)
        }
        // Options X
        else if (opXPlans.includes(Number(sub.PlanID))){
            eval(`var widget = new SimplybookWidget({"widget_type":"button","url":"https://optionsx.simplybook.me","theme":"default","theme_settings":{"timeline_show_end_time":"0","timeline_modern_display":"as_slots","sb_base_color":"#33bb60","display_item_mode":"list","booking_nav_bg_color":"#d1e9c6","body_bg_color":"#f7f7f7","dark_font_color":"#494949","light_font_color":"#ffffff","btn_color_1":"#5e7da7","sb_company_label_color":"#ffffff","hide_img_mode":"1","show_sidebar":"1","sb_busy":"#dad2ce","sb_available":"#d3e0f1"},"timeline":"modern","datepicker":"top_calendar","is_rtl":false,"app_config":{"predefined":[]},"button_title":"1 on 1 Scheduling", "button_position":"right","button_position_offset":"90%"});`)
        }
    }

    const checkCoupon = () => {
        const request = {
            Coupon: coupon,
            DiscordAccountID: accountData.DiscordAccountID,
            ProductID: selectedPlan.ProductID
        }
        setLoading(true)
        return getCouponInfoAPI(dUser.User.DiscordUserID, dUser.Token.access_token, request)
            .then(handleErrors)
            .then(result => {
                if (result == null){
                    // Bad Coupon
                    setCouponInfo({
                        Valid: false
                    })
                }
                else{
                    // Good Coupon
                    setCouponInfo(result)
                }
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
                setLoading(false)
            })
    }

    const handleChangeSubscribeChannel = (id, name, add) => {
        let newChannels;
        if (add){
            newChannels = selectedSubscribes.map(x => x)
            newChannels.push({
                ChannelID: id,
                Name: name
            })
        }
        else{
            newChannels = selectedSubscribes.filter(x => x.ChannelID !== id)
        }
        setSelectedSubscribes(newChannels)
    }

    const saveChannelSubscribes = () => {
        if (!selectedSubscribes || !hasSub){
            return
        }
        const request = {
            Channels: selectedSubscribes,
            DiscordAccountID: accountData.DiscordAccountID
        }
        setLoading(true)
        updateSubChannelsAPI(dUser.User.DiscordUserID, dUser.Token.access_token, request)
            .then(handleErrors)
            .then(results => {
                if (results !== 'Success'){
                    notify('Error', 'Error Updating Channels')
                    return
                }
                notify('Saved', 'SMS Notification Channels Saved')
                loadAccountData()
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                    setLoading(false)
                }
            })
    }

    const getPaymentAmount = (isFreeTrial) => {
        if (isFreeTrial){
            return '0.00'
        }
        const selFreq = selectedPlan.DiscordStripePlanFrequencies.filter(x => x.PriceID === priceID)[0]
        let amt = Number(selFreq.Cost)
        if (couponInfo){
            if (couponInfo.PercentOff){
                const coupAmount = Math.floor(amt * (Number(couponInfo.PercentOff))) / 100
                amt = amt - coupAmount
            }
            else if (couponInfo.AmountOff){
                amt = amt - Number(couponInfo.AmountOff)
            }
        }
        return amt.toFixed(2)
    }

    const backFromPhone = () => {
        setShowPhoneLink(false)
        loadAccountData()
    }

    if (!serverURL){
        return 'Server URL required'
    }


    const noTrialObj = {
        HasTrial: false
    }
    return (
        <Container fluid>
            {pixelId && <img style={{display: 'none'}} src={`https://www.facebook.com/tr?${pixelId}`} />}
            {(linkOnly || showPhoneLink) && <LinkData props={{accountData}} notify={notify} back={!linkOnly && backFromPhone} />}
            {!linkOnly && !showPhoneLink && accountData && (
                <Card bg="dark" style={{color: 'white', position: 'relative'}}>
                    <Card.Header style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <b>{accountData.ManagerName}</b>
                        <Nav>
                            <Nav.Item style={{display: 'flex', marginRight: '20px', alignItems: 'center', color: 'white'}}>
                                {dUser.User && dUser.User.Name}
                            </Nav.Item>
                            <Nav.Link onClick={() => {
                                dispatch(signOutAction())
                                localStorage.removeItem('DUToken')
                                resetAllState()
                                setShowLinkingModal(true)
                            }
                            }>Sign Out</Nav.Link>
                        </Nav>
                    </Card.Header>
                    {loading && <Card.Body style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <CircularProgress style={{width: '100px', height: '100px'}} />
                        </Card.Body>
                    }
                    {!loading && <Card.Body>
                        {hasSub && hasSub.SubscriptionID
                        ? (
                            selectedPlan
                            ? (
                                <Row style={{color: 'black', paddingBottom: '30px'}}>
                                    <Col sm={6} lg={4} style={{marginBottom: '5px'}}>
                                        <TierPanel data={{...selectedPlan, ...noTrialObj}} />
                                    </Col>
                                    <Col style={{color: 'white'}}>
                                        <Row style={{height: '100%'}}>
                                            <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center'}}>
                                                {hasSub.PlanID === selectedPlan.PlanID && !continuePlan
                                                    ? (showFreq
                                                        ?  <CheckOutWizard stage="Has Sub Just Changing Freq"  props={{ hasSub, priceID, setPriceID, selectedPlan, updateSubscription, setShowFreq, coupon, setCoupon, checkCoupon, couponInfo, setAuthorizeFunction, setShowAuthorize }} />
                                                        : (changePayment
                                                            ? <CheckOutWizard stage="Has Sub Just Changing Payment" props={{ stripePromise, setPaymentMethod, updateCustomerPayment, setChangePayment, paymentMethod }} />
                                                            : (viewContent
                                                                ? <CheckOutWizard stage="Has Sub Just Viewing Content" props={{ hasSub, setViewContent, selectedPlan }} />
                                                                : (changeNotifications
                                                                    ? <CheckOutWizard stage="Has Sub Just Changing Notifications" props={{ hasSub, setChangeNotifications, selectedPlan, handleChangeSubscribeChannel, saveChannelSubscribes, selectedSubscribes }} />
                                                                    : <CheckOutWizard stage="Has Sub Show All Options" props={{ hasSub, setShowFreq, handleChangePlan, activeFreq, selectedPlan, setChangePayment, continuePlan, setContinuePlan, setShowCancelModal, prepopPlanID, isDone, serverURL, resumeSubscription, setChangeNotifications, setViewContent, dUser, setShowPhoneLink, setShowEndTrialModal }} />
                                                                )
                                                            )
                                                        )
                                                    )
                                                    : <CheckOutWizard stage="Has Sub Looking At New Plan" props={{ paymentMethod, priceID, setPriceID, selectedPlan, coupon, setCoupon, updateSubscription, handleChangePlan, hasSub, stripePromise, setPaymentMethod, checkCoupon, couponInfo, setAuthorizeFunction, setShowAuthorize }} />
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            ) 
                            : <CheckOutWizard stage="Has Sub but doesnt have selected plan" props={{accountData, handlePlanSelect, hasSub}} />
                        )
                        : (!selectedPlan 
                            ? <CheckOutWizard stage="No Sub and No Selected Plan" props={{accountData, handlePlanSelect, hasSub, refFromURL}} />
                            : ( !prepopPlanID && promoPlan && activeFreq && !hasSub 
                                ? (promoDone 
                                    ? <CheckOutWizard stage="No Sub, Has Promo, No Prepop Tier, Has Frequency, Finished Signing Up For Promo" props={{selectedPlan}} />
                                    : promoPlan.TrialRequiresPayment
                                        ? <CheckOutWizard stage="No Sub, Has Promo, No Prepop Tier, Has Frequency, Needs Payment" props={{selectedPlan, startSub, refFromURL, setPaymentMethod, stripePromise, paymentMethod}} /> 
                                        : <CheckOutWizard stage="No Sub, Has Promo, No Prepop Tier, Has Frequency, Just Needs To Hit Start" props={{selectedPlan, startSub, refFromURL}} /> 
                                ) 
                                : <CheckOutWizard stage="No Sub, No Promo, Selected A Plan" props={{selectedPlan, hasSub, priceID, setPriceID, coupon, setCoupon, referral, setReferral, trialOverride, setPaymentMethod, stripePromise, paymentMethod, setTrialOverride, startSub, setSelectedPlan, refFromURL, checkCoupon, couponInfo, setAuthorizeFunction, setShowAuthorize, ref }} />
                            )
                        )}
                    </Card.Body>}
                    <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '3px', marginRight: '3px', padding: '6px'}}>
                        <Badge pill variant="primary">
                            Powered By Varbis.NET
                        </Badge>
                    </div>
                </Card>
            )}


            <Modal show={showLinkingModal} onHide={() => {setShowLinkingModal(false)}} backdrop="static">
                <Modal.Header>
                    <Modal.Title style={{textAlign: 'center'}}>To Log Back In, Click The Login Button Below</Modal.Title>
                </Modal.Header>
                    <Modal.Body style={{textAlign: 'center'}}>
                        <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                            This page will refresh once the link to discord is established.<br />
                            Make sure your browser allows popups.<br />
                        </div>
                        <img src={popupBlocked} alt="blocked popup" />
                        <img src={popupBlockedMenu} alt="blocked popup menu" />
                    </Modal.Body>
                    <Modal.Footer style={{justifyContent: 'center'}}>
                        <Button variant="success" onClick={() => {checkToken(); window.open(userLogin(serverURL))}}>Login</Button>
                    </Modal.Footer>
            </Modal>

            <Modal show={showCancelModal} onHide={() => {setShowCancelModal(false)}}>
                <Modal.Header>
                    <Modal.Title>Cancel Subscription</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{textAlign: 'center'}}>
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                        Are you sure you want to cancel?
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {cancelSubscription()}}>Yes, Cancel</Button>
                    <Button variant="success" onClick={() => {setShowCancelModal(false)}}>No</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAuthorize} onHide={() => {setShowAuthorize(false)}}>
                <Modal.Header>
                    <Modal.Title>Authorize Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{textAlign: 'center'}}>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center'}}>
                        <div>                            
                            <span>Payment Amount : </span>
                            <b>
                                <span>${showAuthorize && getPaymentAmount()}</span>
                            </b>
                            <span>*</span>
                        </div>
                        <div style={{fontSize: '0.7rem', marginTop: '20px', textAlign: 'end'}}>
                            * Does not include any proration that may take place
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {setShowAuthorize(false)}}>Cancel</Button>
                    <Button variant="success" onClick={() => {authorizeFunction()}}>Authorize Payment</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEndTrialModal} onHide={() => {setShowEndTrialModal(false)}}>
                <Modal.Header>
                    <Modal.Title>End Trial</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{textAlign: 'center'}}>
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                        Are you sure you wamt to end your trial? This will charge your selected payment method.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => {endSubscriptionTrial()}}>Yes, End</Button>
                    <Button variant="danger" onClick={() => {setShowEndTrialModal(false)}}>No</Button>
                </Modal.Footer>
            </Modal>

            {downloadItems.map(x => {

                if (!dUser.User || !dUser.User.DiscordUserID){
                    return
                }
                const link = `${fileDownload}/${dUser.User.DiscordUserID}/${x.RequestID}`
                return <Iframe props={{src: link}} />
            })}
            
            <div style={{position: 'fixed', top: '20px', right: '20px', zIndex: '5'}}>
                {toasts.map(x => {
                    return <Toasty title={x.Title} message={x.Message} />
                })}
            </div>
        </Container>
    )
}




const GetFreqString = (freqNum) => {
    switch (Number(freqNum)){
        case FREQ.Daily:
            return 'Day'
        case FREQ.Weekly:
            return 'Week'
        case FREQ.Monthly:
            return 'Month'
        case FREQ.Quarterly:
            return '3 Months'
        case FREQ.SemiAnnualy:
            return '6 Months'
        case FREQ.Yearly:
            return 'Year'
        default:
            return ''
    }
}

const getMonthlyPriceGlobal = (maxPrice, maxFreq) => {    
    switch (maxFreq){
        case FREQ.Daily:
            return roundMoney(maxPrice * 30).toFixed(2)
        case FREQ.Weekly:
            return roundMoney(maxPrice * 4.285).toFixed(2)
        case FREQ.Monthly:
            return roundMoney(maxPrice).toFixed(2)
        case FREQ.Quarterly:
            return roundMoney(maxPrice / 3).toFixed(2)
        case FREQ.SemiAnnualy:
            return roundMoney(maxPrice / 6).toFixed(2)
        case FREQ.Yearly:
            return roundMoney(maxPrice / 12).toFixed(2)
        default:
            return Number(0).toFixed(2)
    }
}