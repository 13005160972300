
import { MuiThemeProvider } from '@material-ui/core/styles'
import DarkTheme from '../../../utility/muiTableDark'

import React, { useState, useEffect, useCallback, Fragment, useRef } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Card, ListGroup, Table, Form, FormControl, Button, Modal, OverlayTrigger, PopoverTitle, PopoverContent, FormCheck, FormLabel, InputGroup } from 'react-bootstrap'
import { makeStyles } from '@material-ui/styles'
import { handleErrors } from '../../../api/base'
import { formatNegative, zeroIfNull } from '../../../utility/numberFormat'
import { Popover, FormGroup } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import MUIDataTable from 'mui-datatables'
import CreateIcon from '@material-ui/icons/Create'
import HistoryIcon from '@material-ui/icons/History'
import { setUserCanManageAPI, getReferralCodeAPI, setUserManagerAPI, saveFullUserAPI, getAuditAPI } from '../../../api/user/discordmanager'
import { utcStringToLocal, utcStringToLocalDate } from '../../../utility/timeconvert'
// import DatePicker from '../datepicker/datepicker'
import  DatePicker  from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {copyTextToClipboard} from '../../../utility/commands'
import { loadDMSettingsAction } from '../../../actions/discordmanager'
import { getFullAccountUserAPI } from '../../../api/user/discordusermanager'
import CircularProgress from '@material-ui/core/CircularProgress'
import { AuditRecord } from './auditRecord'



const useStyles = makeStyles({
    fullCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    leftFlex: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center'
    }
})

const FREQ = {
    Daily: 0,
    Weekly: 1,
    Monthly: 2,
    Quarterly: 3,
    SemiAnnualy: 4,
    Yearly: 5,
    LifeTime: 6
}

const getFreqString = (freqNum) => {
    switch (Number(freqNum)){
        case FREQ.Daily:
            return 'Day'
        case FREQ.Weekly:
            return 'Week'
        case FREQ.Monthly:
            return 'Month'
        case FREQ.Quarterly:
            return '3 Months'
        case FREQ.SemiAnnualy:
            return '6 Months'
        case FREQ.Yearly:
            return 'Year'
        case FREQ.LifeTime:
            return 'Lifetime'
        default:
            return ''
    }
}

const getSubStatusFormatted = (status) => {
    switch (status){
        case 'incomplete':
            return 'Failed Payment - Will Recharge'
        case 'incomplete_expired':
            return 'Failed Multiple Payments - Will Not Recharge'
        case 'trialing':
            return 'Trial Account'
        case 'active':
            return 'Active Account'
        case 'past_due':
            return 'Payment Past Due'
        case 'canceled':
            return 'Canceled'
        case 'unpaid':
            return 'Unpaid'
        default:
            return ''
    }
}

export const DiscordManagerUsersLite = ({users, notify, owner, refresh, props}) => {
    const dmState = useSelector(state => state.discordManager)
    const user = useSelector(state => state.user)
    const classes = useStyles()

    const [userState, setUserState] = useState(users)
    const [filteredState, setFilteredState] = useState(users)

    
    let now = new Date()
    let end = new Date()
    end.setDate(end.getDate() - 1)
    const [dateFilter, setDateFilter] = useState('')
    const [dateEnd, setDateEnd] = useState(now)
    const [dateStart, setDateStart] = useState(end)
    const [generatedURL, setGeneratedURL] = useState('')
    const [urlTier, setUrlTier] = useState('')
    const [urlCode, setUrlCode] = useState('')
    const genFieldRef = useRef(null)
    const [showDetails, setShowDetails] = useState(false)
    const [userDetails, setUserDetails] = useState({})

    const [whichManager, setWhichManager] = useState(null)
    const [loading, setLoading] = useState(false)

    const [openManager, setOpenManager] = useState(null)
    const [closingManager, setClosingManager] = useState(null)
    const [affiliate, setAffiliate] = useState(false)
    const [canManage, setCanManage] = useState(false)
    const [auditHistory, setAuditHistory] = useState(null)
    const [fullUserId, setFullUserId] = useState(null)

    useEffect(() => {
        setUserState(users)
        handleFilters(users)
    }, [users])

    const columns = [
        { name: 'DiscordUserAccountID', options: {
            display: 'false',
            download: false,
            filter: false,
            viewColumns: false
        }},
        { name: 'Name', label: 'User', 
            options: {
                filter: false
            }
        },
        { name: 'Email', label: 'Email',
            options: {
                filter: false
            }
        },
        { name: 'PromoCode', label: 'Promo Code',
            options: {
                filterOptions: {
                    renderValue: (v) => v || 'No Promo'
                }
            }
        },
        { name: 'OpeningManager', label: 'Opening Manager' },
        { name: 'ReferredBy', label: 'Referred By' }
    ]

    const handleRowClick = (data, dIx, rIx) => {
        console.log(data)
        setShowDetails(true)
        let details = {
            Username: data[1],
            Email: data[2],
            DiscordUserAccountID: data[0]
        }
        setUserDetails(details)
        getFullUser(data[0], details)
    }

    const tableOpts = {
        selectableRows: 'none',
        rowsPerPage: 25,
        rowsPerPageOptions: [10, 25, 50, 100],
        filterType: 'multiselect',
        onRowClick: handleRowClick
    }


    const handleClick = (event, what, id) => {
        setAnchorEl(event.currentTarget)
        setPopWhat(what)
        setPopID(id)
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const [popWhat, setPopWhat] = useState(null)
    const [popID, setPopID] = useState(null)
    const open = Boolean(anchorEl)
    const id = open ? 'editUser' : undefined


    const replaceUser = (newUser) => {
        const newUsers = userState.map(x => {
            if (x.DiscordUser && x.DiscordUser.DiscordUserID === newUser.DiscordUser.DiscordUserID){
                x = newUser
            }
            return x
        })
        setUserState(newUsers)
        handleFilters(newUsers)
    }

    const generateCode = (ovPop) => {
        handleClose()
        const request = {
            DiscordAccountID: dmState.DiscordAccountID,
            DiscordUserID: ovPop || popID
        }
        return getReferralCodeAPI(user.UserID, user.IdToken, user.AccessToken, request)
            .then(handleErrors)
            .then(results => {
                if (!results){
                    notify && notify('Error', 'Error Generating Referral Code')
                    return
                }
                
                const baseDetails = {
                    Username: results.DiscordUser.Name,
                    Email: results.DiscordUser.Email,
                    DiscordUserAccountID: results.DiscordUserAccountID
                }
                getFullUser(results.DiscordUserAccountID, baseDetails)
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify && notify('Error', msg.toString())
                }
            })
    }

    const getFullUser = (targetID, details) => {
        setLoading(true)
        setFullUserId(targetID)
        const selectedOwnerID = owner ? user.UserID : sessionStorage.getItem('selectedOwner')
        return getFullAccountUserAPI(user.UserID, user.IdToken, user.AccessToken, selectedOwnerID, targetID)
            .then(handleErrors)
            .then(results => {
                if (!results){
                    notify && notify('Error', 'Error Loading User')
                    return
                }
                const plan = dmState.DiscordStripePlans.filter(x => x.PlanID === results.PlanID)[0]
                results.Plan = (plan && plan.Name) || 'unknown'
                const frequency = plan && plan.DiscordStripePlanFrequencies.filter(x => x.PriceID === results.PriceID)[0]
                results.Frequency = (frequency && getFreqString(frequency.Frequency)) || 'unknown'
                details = {...details, ...results}
                setUserDetails(details)
                setAffiliate(details.Affiliate)
                setCanManage(details.CanManageOthers)
                setOpenManager(details.ManagerID)
                setClosingManager(details.LastManagerID)
                console.log(details)
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify && notify('Error', msg.toString())
                }
                setLoading(false)
            })
    }

    const canManageChange = (canManage, dUserID) => {
        const request = {
            DiscordAccountID: dmState.DiscordAccountID,
            DiscordUserID: dUserID,
            CanManage: canManage
        }
        // Update Check right away to make it feel responsive
        const targetUser = userState.filter(x => x.DiscordUserID === dUserID)[0]
        targetUser.CanManageOthers = canManage;
        //replaceUser(targetUser)
        return setUserCanManageAPI(user.UserID, user.IdToken, user.AccessToken, request)
            .then(handleErrors)
            .then(results => {
                if (!results){
                    notify && notify('Error', 'Error Updating Can Manage')
                    return
                }
                refresh()
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify && notify('Error', msg.toString())
                }
            })
    }

    const updateManager = (id) => {
        handleClose()
        const request = {
            DiscordAccountID: dmState.DiscordAccountID,
            DiscordUserID: popID,
            ManagerID: id,
            WhichManager: whichManager
        }
        return setUserManagerAPI(user.UserID, user.IdToken, user.AccessToken, request)
            .then(handleErrors)
            .then(results => {
                if (!results){
                    notify && notify('Error', 'Error Generating Referral Code')
                    return
                }
                refresh()
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify && notify('Error', msg.toString())
                }
            })
    }

    const handleFilters = (fullData, start, end, filter) => {
        if (!fullData){
            fullData = userState
        }
        if (start === undefined){
            start = dateStart
        }
        if (end === undefined){
            end = dateEnd
        }
        if (filter === undefined){
            filter = dateFilter
        }

        if (fullData){
            const filteredData = fullData.filter(x => {
                let checkDate
                if (filter === '1'){
                    // Aquisitions
                    checkDate = x.Created
                }
                else if (filter === '2'){
                    // Conversion
                    checkDate = x.Conversion
                }
                else if (filter === '3'){
                    // Cancelation
                    checkDate = x.Canceled
                }
                else if (!filter){
                    return true
                }

                //CheckDate in UTC
                const localTime = utcStringToLocalDate(checkDate)

                return localTime >= start && localTime <= end
            })
            setFilteredState(filteredData)
        }
    }
    const u = undefined
    const datepickerProps = {
        start: dateStart,
        setStart: (x) => {setDateStart(x); handleFilters(u, x, u, u)},
        end: dateEnd,
        setEnd: (x) => {setDateEnd(x); handleFilters(u, u, x, u)},
        dateFilter,
        setDateFilter: (x) => {setDateFilter(x); handleFilters(u, u, u, x)},
        handleClick,
        refresh
    }

    const getURLBase = (accountID) => {
        switch (accountID){
            case 1:
                // Demo

                // Prod
                return 'https://discord.com/api/oauth2/authorize?client_id=749677856426033304&redirect_uri=https%3A%2F%2Fvarbis.net%2Fdiscorduser&response_type=code&scope=identify%20email%20guilds.join&prompt=none&state=a%40https%3A%2F%2Fslapstocks.com/pages/subscription-manager-demo%21'
                
                // Local
                //return 'https://discord.com/api/oauth2/authorize?client_id=749677856426033304&redirect_uri=https%3A%2F%2Fvarbis.net%2Fdiscorduser&response_type=code&scope=identify%20email%20guilds.join&prompt=none&state=a%40https%3A%2F%2Fslapstocks.com/pages/subscription-manager-demo%21'
            case 3:
                // Slap Stocks
                return `https://momentumtradegroup.com/subscription?z=1`
                // return `https://slapstocks.com/pages/sign-up?z=1`
                //return 'https://discord.com/api/oauth2/authorize?client_id=716365960390508597&redirect_uri=https%3A%2F%2Fvarbis.net%2Fdiscorduser&response_type=code&scope=identify%20email%20guilds.join&prompt=none&state=a%40https%3A%2F%2Fslapstocks.com/pages/subscription-manager%21'
            case 56:
                // Primetime
                return `https://primetimetradinggroup.com/signup?z=1`
            case 57:
                // OptionsX
                return `https://options-x.com/signup?z=1`
            case 59:
                // Rocket
                return `https://rocketstockalerts.net/redirecting?z=1`
            case 66:
                // Alphas
                return `https://tradingalphas.com/pages/signup?z=1`
            case 78:
                // Bull Trade Finder
                return `https://bulltradefinder.net/signup?z=1`
            case 88:
                // Elite
                return `https://myelitesignals.com/?page_id=255`
            case 92:
                // Momentum
                return `https://momentumtradegroup.com/subscription?z=1`
            default:
                return ''
        }
    }

    const generateURL = () => {
        const accountID = dmState.DiscordStripePlans && dmState.DiscordStripePlans[0] && dmState.DiscordStripePlans[0].DiscordAccountID
        let base = getURLBase(accountID);
        
        switch(accountID){
            case 56:
            case 57:
            case 59:
            case 66:
            case 78:
            case 88:
            case 92:
            case 3:
                {
                    if (urlTier){
                        base += '&t=' + urlTier
                    }
                    if (urlCode){
                        base += '&r=' + urlCode
                    }
                    setGeneratedURL(base)
                }
                break
            default:
                {
                    if (urlTier){
                        base += 't%40' + urlTier + '%21'
                    }
                    if (urlCode){
                        base += 'r%40' + urlCode + '%21'
                    }
                    setGeneratedURL(base)
                }
                break
        }
        
    }

    const copyURL = (e) => {        
        genFieldRef.current.select()
        try {
            document.execCommand('copy');
        }
        catch (err) {
            console.log(err);
        }
    }

    const stripeTime = (seconds) => {
        if (!seconds){
            return ''
        }
        let d = new Date(0)
        d.setUTCSeconds(seconds)
        return d.toLocaleString()
    }

    const saveDetail = () => {
        const request = {
            DiscordUserID: userDetails.DiscordUserID,
            OpeningManager: openManager,
            ClosingManager: closingManager,
            CanManageOthers: canManage,
            Affiliate: affiliate
        }
        setLoading(true)
        saveFullUserAPI(user.UserID, user.IdToken, user.AccessToken, request)
            .then(handleErrors)
            .then(results => {
                if (!results){
                    notify('Error', 'Error Saving User')
                    setLoading(false)
                    return
                }
                notify('Saved!', 'User Successfully Saved')
                setShowDetails(false)
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                    setLoading(false)
                }
            })
    }

    const loadUserAudit = () => {
        if (auditHistory){
            setAuditHistory(null)
            return;
        }
        const request = {
            TableName: 'DiscordUserAccounts',
            KeyValues: fullUserId && fullUserId.toString()
        }
        setLoading(true)
        getAuditAPI(user.UserID, user.IdToken, user.AccessToken, request)
            .then(handleErrors)
            .then(results => {
                if (!results){
                    notify('Error', 'Error Loading Audit Record')
                }
                setAuditHistory(results)
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
                setLoading(false)
            })
    }

    return (
        <Card bg="dark">
            <Card.Header style={{color: 'white'}}>
                <div>
                    <DateRangeFilter {...{...datepickerProps, ...props}} />
                </div>
            </Card.Header>
            <MuiThemeProvider theme={DarkTheme()}>
                <MUIDataTable
                    title=""
                    columns={columns}
                    data={filteredState}
                    options={tableOpts}
                />
            </MuiThemeProvider>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <PopoverTitle style={{backgroundColor: 'rgba(0,0,0,.03)', color: 'white'}}>
                    {popWhat && popWhat === 'RefCode' && (
                        'Referral Code'
                    )}
                    {popWhat && popWhat === 'Manager' && (
                        'Manager'
                    )}
                    {popWhat && popWhat === 'urls' && (
                        <Fragment>
                            <span>Referral Code Generator</span>
                        </Fragment>
                    )}
                </PopoverTitle>
                <PopoverContent style={{color: 'white'}}>
                    {popWhat && popWhat === 'RefCode' && (
                        <Fragment>
                            <Button onClick={generateCode}>Generate</Button>
                        </Fragment>
                    )}
                    {popWhat && popWhat === 'Manager' && (
                        <ListGroup>
                            <ListGroup.Item variant="danger" onClick={() => updateManager(null)}>
                                Remove Manager
                            </ListGroup.Item>
                            {users && userState.filter(x => x.CanManageOthers).sort((a,b) => a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1).map(x => {
                                return (
                                    <ListGroup.Item variant="dark" onClick={() => updateManager(x.DiscordID)}>
                                        {x.Name}
                                    </ListGroup.Item>
                                )
                            })}
                        </ListGroup>
                    )}
                    {popWhat && popWhat === 'urls' && (
                        <Fragment>
                            <Form.Group as={Row}>
                                <Form.Label column>Referral Code</Form.Label>
                                <Col>
                                    <Form.Control value={urlCode} onChange={(e) => setUrlCode(e.target.value)} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                            <Form.Label column>Plan</Form.Label>
                                <Col>
                                    <Form.Control as="select" value={urlTier} onChange={(e) => setUrlTier(e.target.value)}>
                                        <option value="">None</option>
                                        {dmState.DiscordStripePlans && dmState.DiscordStripePlans.filter(x => x.Enabled).map(x => {
                                            return <option value={x.PlanID}>{x.Name}</option>
                                        })}
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col style={{justifyContent: 'center', display: 'flex'}}>
                                    <Button onClick={generateURL}>
                                        Generate
                                    </Button>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <Button variant="secondary" onClick={copyURL}>
                                                Copy
                                            </Button>
                                        </InputGroup.Prepend>
                                        <Form.Control value={generatedURL} ref={genFieldRef} />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                        </Fragment>
                    )}
                </PopoverContent>
            </Popover>            
            <Modal show={showDetails} onHide={() => {setShowDetails(false); setAuditHistory(null) }} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title style={{display: 'flex', justifyContent: 'space-between', width: '90%'}}>
                        {userDetails.Username}
                        <HistoryIcon style={{color: 'black', cursor: 'pointer'}} onClick={loadUserAudit} />
                    </Modal.Title>
                </Modal.Header>
                {loading && 
                    <Modal.Body style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <CircularProgress style={{width: '100px', height: '100px'}} />
                    </Modal.Body>
                }
                {!loading && auditHistory && 
                    <Modal.Body>
                        <AuditRecord records={auditHistory} />
                    </Modal.Body>
                }
                {!loading && !auditHistory && <Fragment>
                <Modal.Body style={{textAlign: 'center', display: 'flex', wordBreak: 'break-word'}}>          
                    <Row style={{width: '100%'}}>
                    <Col sm={12} lg={6}>
                        <Form.Group as={Row}>
                            <Form.Label column sm={4}>Email:</Form.Label>
                            <Form.Label column sm={8}>{userDetails.Email}</Form.Label>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={4}>Plan:</Form.Label>
                            <Form.Label column>{userDetails.Plan}</Form.Label>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={4}>Status:</Form.Label>
                            <Form.Label column>{getSubStatusFormatted(userDetails.SubscriptionStatus)}</Form.Label>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={4}>Aquired:</Form.Label>
                            <Form.Label column>{utcStringToLocal(userDetails.Created, 'datetime')}</Form.Label>
                        </Form.Group>                        
                        <Form.Group as={Row}>
                            <Form.Label column sm={5}>Phone:</Form.Label>
                            <Form.Label column>{userDetails.Phone}</Form.Label>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={4}>Can Manage:</Form.Label>
                            <Col className={`${classes.fullCenter}`}>
                                <Form.Check id="user-can-manage" type="checkbox" checked={canManage} onChange={(e) => owner && setCanManage(e.target.checked)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={4}>Affiliate:</Form.Label>
                            <Col className={`${classes.fullCenter}`}>
                                <Form.Check id="user-affiliate" type="checkbox" checked={affiliate} onChange={(e) => owner && setAffiliate(e.target.checked)} />
                            </Col>
                        </Form.Group>                        
                        <Form.Group as={Row}>
                            <Form.Label column sm={4}>Will Cancel:</Form.Label>
                            <Form.Label column>{`${userDetails.WillCancel}`}</Form.Label>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={4}>Next Charge:</Form.Label>
                            <Form.Label column>{userDetails.subscriptionInfo && !userDetails.subscriptionInfo.cancel_at_period_end && userDetails.subscriptionInfo.current_period_end && stripeTime(userDetails.subscriptionInfo.current_period_end)}
                              </Form.Label>
                        </Form.Group>                        
                        <Form.Group as={Row}>
                            <Form.Label column sm={4}>Opening Manager:</Form.Label>
                            <Col>
                                <Form.Control as="select" value={openManager || ''} disabled={!owner} onChange={(e) => owner && setOpenManager(e.target.value)}>
                                    <option value="">No Manager</option>
                                    {userDetails.possibleManagers && userDetails.possibleManagers.map(x => {
                                        return <option value={`${x.ID}`}>{x.Name}</option>
                                    })}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={12} lg={6}>
                        <Form.Group as={Row}>
                            <Form.Label column sm={5}>Card Holder:</Form.Label>
                            <Form.Label column>{userDetails.CardHolder}</Form.Label>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={5}>Frequency:</Form.Label>
                            <Form.Label column>{userDetails.Frequency}</Form.Label>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={5}>Conversion:</Form.Label>
                            <Form.Label column>{utcStringToLocal(userDetails.Conversion, 'datetime')}</Form.Label>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={5}>Canceled:</Form.Label>
                            <Form.Label column>{utcStringToLocal(userDetails.Canceled, 'datetime')}</Form.Label>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={5}>Referred By:</Form.Label>
                            <Form.Label column>{userDetails.ReferredBy}</Form.Label>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={5}>Referral Code:</Form.Label>
                            <Form.Label column style={{fontFamily: 'Merriweather', fontSize: '1.1em', letterSpacing: '2px'}}>
                                {userDetails.ReferralCode 
                                    ? userDetails.ReferralCode
                                    : owner 
                                        ? <Button onClick={() => {generateCode(userDetails.DiscordUserID)}}>Generate</Button>
                                        : ''
                                }
                            </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={5}>Customer:</Form.Label>
                            <Col>
                                <a href={`https://dashboard.stripe.com/customers/${userDetails.CustomerID}`} target="_blank">{userDetails.CustomerID}</a>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={5}>Subscription:</Form.Label>
                            <Col>
                                <a href={`https://dashboard.stripe.com/subscriptions/${userDetails.SubscriptionID}`} target="_blank">{userDetails.SubscriptionID}</a>
                            </Col>
                        </Form.Group>
                        {userDetails.subscriptionInfo && userDetails.subscriptionInfo.latest_invoice &&                            
                            <Form.Group as={Row}>
                                <Form.Label column sm={5}>Latest Invoice:</Form.Label>
                                <Col>
                                    <a href={`${userDetails.subscriptionInfo.latest_invoice.hosted_invoice_url}`} target="_blank">{userDetails.subscriptionInfo.latest_invoice.number}</a>
                                </Col>
                            </Form.Group>
                        }
                        
                        <Form.Group as={Row}>
                            <Form.Label column sm={5}>Closing Manager:</Form.Label>
                            <Col>
                                <Form.Control as="select" value={closingManager || ''} disabled={!owner} onChange={(e) => owner && setClosingManager(e.target.value)}>
                                    <option value="">No Manager</option>
                                    {userDetails.possibleManagers && userDetails.possibleManagers.map(x => {
                                        return <option value={`${x.ID}`}>{x.Name}</option>
                                    })}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </Col>
                    </Row>
                </Modal.Body>
                {owner && 
                    <Modal.Footer>
                        <Button variant="success" onClick={saveDetail}>Save</Button>
                    </Modal.Footer>
                }
                </Fragment>
                }
            </Modal>
        </Card>
    )
}

const DateRangeFilter = ({...props}) => {
    const classes = useStyles()    
    const isBig = useMediaQuery('(min-width:600px)');

    
    const dispatch = useDispatch()
    const dmState = useSelector(state => state.discordManager)

    return(
        <Fragment>
            <Row>
                <Col sm={2} style={{display: 'flex', alignItems: 'center'}}><b>Users</b></Col>
                <Col sm={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Form>
                        <Form.Check type="switch" id="includeTrial" label="Include Expired Trials" checked={dmState.IncludeTrial} onChange={(e) => {
                            dispatch(loadDMSettingsAction({IncludeTrial: e.target.checked}))
                            props.refresh && props.refresh(e.target.checked)

                        }} />
                        <Form.Check type="switch" id="noTrials" label="Hide Trials" checked={dmState.HideTrial} onChange={(e) => {
                            dispatch(loadDMSettingsAction({HideTrial: e.target.checked}))
                            props.refresh && props.refresh(undefined, e.target.checked)
                        }} />
                    </Form>
                </Col>
                <Col sm={2} style={{display: 'flex', alignItems: 'center'}}>
                    <FormControl as="select" value={props.dateFilter} onChange={(e) => props.setDateFilter(e.target.value)} disabled={true}>
                        <option value="">No Range</option>
                        <option value="1">Aquisition</option>
                        <option value="2">Conversion</option>
                        <option value="3">Cancelation</option>
                    </FormControl>
                </Col>
                    {isBig ? (
                    <Fragment>                
                    <Col lg={4} className={classes.leftFlex}>    
                        <DatePicker
                            selected={props.start}
                            onChange={(date) => props.setStart(date)}
                            showTimeSelect
                            timeIntervals={30}
                            timeCaption="Time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                        />
                        <span style={{marginRight: '30px', marginLeft: '30px'}}>To</span>
                        <DatePicker
                            selected={props.end}
                            onChange={(date) => props.setEnd(date)}
                            showTimeSelect
                            timeIntervals={30}
                            timeCaption="Time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                        />
                        <Button style={{marginLeft: '10px'}} onClick={(e) => {props.handleClick(e, 'urls')}}>
                            Generate Links
                        </Button>
                        </Col>
                    </Fragment>
                    ) : (
                        <Fragment>
                            <Col sm={10} className={classes.fullCenter} style={{marginTop: '10px'}}>
                                <DatePicker
                                    selected={props.start}
                                    onChange={(date) => props.setStart(date)}
                                    showTimeSelect
                                    timeIntervals={30}
                                    timeCaption="Time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                />
                            </Col>
                            <Col sm={10} className={classes.fullCenter}>
                                <span style={{marginRight: '30px', marginLeft: '30px'}}>To</span>
                            </Col>
                            <Col sm={10} className={classes.fullCenter}>
                                <DatePicker
                                    selected={props.end}
                                    onChange={(date) => props.setEnd(date)}
                                    showTimeSelect
                                    timeIntervals={30}
                                    timeCaption="Time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                />
                            </Col>
                            <Col sm={10} className={classes.fullCenter}>                            
                                <Button onClick={(e) => {props.handleClick(e, 'urls')}}>
                                    Generate Links
                                </Button>
                            </Col>
                        </Fragment>
                    )}
            </Row>
        </Fragment>
    )
}