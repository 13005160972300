import React, { useState } from 'react'
import {  useSelector } from 'react-redux'
import { Row, Col, Form, Button, Card, Accordion } from 'react-bootstrap'
import { makeStyles } from '@material-ui/styles'
import { handleErrors } from '../../api/base'
import GoodIcon from '@material-ui/icons/Done'
import BadIcon from '@material-ui/icons/Error'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles({
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    column: {
        flexDirection: 'column'
    },
    fullHeight: {
        height: '100vh'
    },
    big: {
        height: '40%',
        width: '80%'
    },
    outline: {
        border: '.2rem solid #ececec',
        borderRadius: '8px'
    },
    darkJumbo: {
        backgroundColor: '#d2d5d8'
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    accordCH: {
        boxShadow: '0px -2px 4px 0px #212529'
    }
})

export const ChangeLog = ({notify}) => {
    const classes = useStyles();

    return (
        <Card bg="dark" style={{color: 'white'}}>
            <Card.Header><b>Change Log</b></Card.Header>
            <Card.Body>
                <Accordion defaultActiveKey="2020-12-29">
                    <Card bg="dark" style={{color: 'white'}}>
                        <Accordion.Toggle className={classes.accordCH} as={Card.Header} eventKey="2020-12-29">
                            12/29/2020 
                        </Accordion.Toggle>     
                        <Accordion.Collapse eventKey="2020-12-29">
                            <Card.Body>
                                <h4>New Features</h4>
                                <ul>
                                    <li>QuickBooks Online</li>
                                    <li>Enhanced Affiliate Support</li>
                                    <li>Referred By Column Added Back</li>
                                </ul>
                                <h4>Bug Fixes</h4>
                                <ul>
                                    <li>Referral Code changed to a serif font</li>
                                    <li>Generate Code Error</li>
                                    <li>Faster User Redirection Speed</li>
                                </ul>
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle className={classes.accordCH} as={Card.Header} eventKey="2020-11-8">
                            11/8/2020 
                        </Accordion.Toggle>     
                        <Accordion.Collapse eventKey="2020-11-8">
                            <Card.Body>
                                <h4>New Features</h4>
                                <ul>
                                    <li>Added Frequency of cheapest monthly plan</li>
                                </ul>
                                <h4>Bug Fixes</h4>
                                <ul>
                                    <li>Reduced Past Due Options</li>
                                    <li>Payment Update recharge when not attempted yet</li>
                                </ul>
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle className={classes.accordCH} as={Card.Header} eventKey="2020-11-2">
                            11/2/2020 
                        </Accordion.Toggle>     
                        <Accordion.Collapse eventKey="2020-11-2">
                            <Card.Body>
                                <h4>Bug Fixes</h4>
                                <ul>
                                    <li>Lifetime only price on plans won't show $0</li>
                                    <li>Admin Payments range to include full last day</li>
                                </ul>
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle className={classes.accordCH} as={Card.Header} eventKey="2020-10-21">
                            10/21/2020 
                        </Accordion.Toggle>     
                        <Accordion.Collapse eventKey="2020-10-21">
                            <Card.Body>
                                <h4>New Features</h4>
                                <ul>
                                    <li>Pixel ID Support</li>
                                    <li>Automatic Invoice Retry On Payment Method Update</li>
                                    <li>Change Log</li>
                                </ul>
                                <h4>Bug Fixes</h4>
                                <ul>
                                    <li>Trial Started on paid plans with promo parameter</li>
                                </ul>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Card.Body>
        </Card>
    )
}

export default ChangeLog