import {baseURL} from '../base'

const controller = `${baseURL}/discord/v1`

export const botURL = `https://discord.com/api/oauth2/authorize?client_id=749677856426033304&permissions=0&redirect_uri=https%3A%2F%2Fvarbis.net%2Fdiscord&scope=bot`

// PROD
export const userAuthURL = `https://discord.com/api/oauth2/authorize?client_id=749677856426033304&redirect_uri=https%3A%2F%2Fvarbis.net%2Fdiscord&response_type=code&scope=identify%20email%20connections%20guilds`

// LOCAL
// export const userAuthURL = `https://discord.com/api/oauth2/authorize?client_id=749677856426033304&redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fdiscord&response_type=code&scope=identify%20email%20connections%20guilds`



export async function getDiscordTokenFromCode(userID, idToken, access, code){
    const response = await fetch(`${controller}/gettoken/${userID}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
        body: JSON.stringify(code)
    })
    return response
}

export async function getNewDiscordTokenWithRefresh(userID, idToken, access, refreshToken){
    const response = await fetch(`${controller}/refreshtoken/${userID}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
        body: JSON.stringify(refreshToken)
    })
    return response
}

export async function getDiscordServers(userID, idToken, access, discordAccess){
    const response = await fetch(`${controller}/getservers/${userID}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access,
            'DiscordAccess': discordAccess
        }
    })
    return response
}

export async function getChannels(userID, idToken, access, discordAccess){
    const response = await fetch(`${controller}/getchannels/${userID}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access,
            'DiscordAccess': discordAccess
        }
    })
    return response
}

export async function getGuildChannels(userID, idToken, access, discordAccess, guildID){
    const response = await fetch(`${controller}/getchannels/${guildID}/${userID}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access,
            'DiscordAccess': discordAccess
        }
    })
    return response
}

export async function setChannelsAPI(userID, idToken, access, guilds){
    const response = await fetch(`${controller}/setchannels/${userID}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
        body: JSON.stringify(guilds)
    })
    return response
}

export async function updateguild(userID, idToken, access, guild){
    const response = await fetch(`${controller}/updateguild/${userID}`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `bearer ${idToken}`,
            'Access': access
        },
        body: JSON.stringify(guild)
    })
    return response
}