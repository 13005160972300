import {baseURL} from '../base'

const controller = `${baseURL}/discorduser/v1`

// PROD
export const userLogin = (url) => {
    url = url.toString().toLowerCase()
    switch (url){
        case 'slapstocks':
            return `https://discord.com/api/oauth2/authorize?client_id=716365960390508597&redirect_uri=https%3A%2F%2Fvarbis.net%2Fdiscorduser&response_type=code&scope=identify%20email%20guilds.join`
        case 'primetime':
            return `https://discord.com/api/oauth2/authorize?client_id=750938531983130744&redirect_uri=https%3A%2F%2Fvarbis.net%2Fdiscorduser&response_type=code&scope=identify%20email%20guilds.join`
        case 'optionsx':
            return `https://discord.com/api/oauth2/authorize?client_id=751294003143639083&redirect_uri=https%3A%2F%2Fvarbis.net%2Fdiscorduser&response_type=code&scope=identify%20email%20guilds.join`
        case 'rocket':
            return `https://discord.com/api/oauth2/authorize?client_id=753048834438987948&redirect_uri=https%3A%2F%2Fvarbis.net%2Fdiscorduser&response_type=code&scope=identify%20email%20guilds.join`
        case 'alphas':
            return `https://discord.com/api/oauth2/authorize?client_id=756308875526930644&redirect_uri=https%3A%2F%2Fvarbis.net%2Fdiscorduser&response_type=code&scope=identify%20email%20guilds.join`
        case 'bulltradefinder':
            return `https://discord.com/api/oauth2/authorize?client_id=760626172030746624&redirect_uri=https%3A%2F%2Fvarbis.net%2Fdiscorduser&response_type=code&scope=identify%20email%20guilds.join`
        case 'elite':
            return `https://discord.com/api/oauth2/authorize?client_id=771876603143520286&redirect_uri=https%3A%2F%2Fvarbis.net%2Fdiscorduser&response_type=code&scope=identify%20email%20guilds.join`
        case 'momentum':
            return `https://discord.com/api/oauth2/authorize?client_id=779779221953708042&redirect_uri=https%3A%2F%2Fvarbis.net%2Fdiscorduser&response_type=code&scope=identify%20email%20guilds.join`
        default:
            return `https://discord.com/api/oauth2/authorize?client_id=749677856426033304&redirect_uri=https%3A%2F%2Fvarbis.net%2Fdiscorduser&response_type=code&scope=identify%20email%20guilds.join`
    }
}

// LOCALHOST
// export const userLogin = () => `https://discord.com/api/oauth2/authorize?client_id=716365960390508597&redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fdiscorduser&response_type=code&scope=identify%20email%20guilds.join`
// export const userLogin = (url) => {
//     url = url.toString().toLowerCase()
//     switch (url){
//         case 'slapstocks':
//             return `https://discord.com/api/oauth2/authorize?client_id=716365960390508597&redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fdiscorduser&response_type=code&scope=identify%20email%20guilds.join`
//         case 'primetime':
//             return `https://discord.com/api/oauth2/authorize?client_id=750938531983130744&redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fdiscorduser&response_type=code&scope=identify%20email%20guilds.join`
//         case 'optionsx':
//             return `https://discord.com/api/oauth2/authorize?client_id=751294003143639083&redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fdiscorduser&response_type=code&scope=identify%20email%20guilds.join`
//         case 'rocket':
//             return `https://discord.com/api/oauth2/authorize?client_id=753048834438987948&redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fdiscorduser&response_type=code&scope=identify%20email%20guilds.join`
//         case 'alphas':
//             return `https://discord.com/api/oauth2/authorize?client_id=756308875526930644&redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fdiscorduser&response_type=code&scope=identify%20email%20guilds.join`
//         default:
//             return `https://discord.com/api/oauth2/authorize?client_id=749677856426033304&redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fdiscorduser&response_type=code&scope=identify%20email%20guilds.join`
//     }
// }

export const fileDownload = `${controller}/getcontent`

export async function getDUToken(code){
    const response = await fetch(`${controller}/gettoken`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(code)
    })
    return response
}

export async function getDUAccount(userID, dAccess, serverURL){
    const response = await fetch(`${controller}/getaccount/${serverURL}/${userID}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'DAccess': dAccess
        }
    })
    return response
}

export async function setNewSubAPI(userID, dAccess, request){
    const response = await fetch(`${controller}/newsub/${userID}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'DAccess': dAccess
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function setUpdateSubAPI(userID, dAccess, request){
    const response = await fetch(`${controller}/updatesub/${userID}`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'DAccess': dAccess
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function setUpdateCustomerPaymentAPI(userID, dAccess, request){
    const response = await fetch(`${controller}/customerpayment/${userID}`, {
        method: 'PATCH',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'DAccess': dAccess
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function setCancelSubAPI(userID, dAccess, request){
    const response = await fetch(`${controller}/cancelsub/${userID}`, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'DAccess': dAccess
        },
        body: JSON.stringify(request)
    })
    return response
}


export async function setResumeSubAPI(userID, dAccess, request){
    const response = await fetch(`${controller}/resumesub/${userID}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'DAccess': dAccess
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function getDUUserAPI(userID, dAccess){
    const response = await fetch(`${controller}/getuser/${userID}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'DAccess': dAccess
        }
    })
    return response
}

export async function getCouponInfoAPI(userID, dAccess, request){
    const response = await fetch(`${controller}/checkcoupon/${userID}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'DAccess': dAccess
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function refreshDUTokenAPI(token){
    const response = await fetch(`${controller}/refreshtoken`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(token)
    })
    return response
}

export async function getTestimonials(serverURL){
    const response = await fetch(`${controller}/testimonials/${serverURL}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        }
    })
    return response
}

export async function getCarriersAPI(){
    const response = await fetch(`${controller}/carriers`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        }
    })
    return response
}

export async function setUserInfoAPI(userID, dAccess, request){
    const response = await fetch(`${controller}/information/${userID}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'DAccess': dAccess
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function verifyCodeAPI(userID, dAccess, request){
    const response = await fetch(`${controller}/verifycode/${userID}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'DAccess': dAccess
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function resendCodeAPI(userID, dAccess, request){
    const response = await fetch(`${controller}/resendcode/${userID}/${request}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'DAccess': dAccess
        }
    })
    return response
}

export async function updateSubChannelsAPI(userID, dAccess, request){
    const response = await fetch(`${controller}/updatesubchannels/${userID}`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'DAccess': dAccess
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function setEndSubTrialAPI(userID, dAccess, request){
    const response = await fetch(`${controller}/endtrial/${userID}`, {
        method: 'PATCH',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'DAccess': dAccess
        },
        body: JSON.stringify(request)
    })
    return response
}