import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Card, ListGroup, Table, Form, FormCheck, FormControl, Button, Modal, OverlayTrigger, PopoverTitle, PopoverContent } from 'react-bootstrap'
import { makeStyles } from '@material-ui/styles'
import { handleErrors } from '../../api/base'
import { refreshAPI } from '../../api/user/auth'
import { validateSignIn, signOutAction } from '../../actions/user'
import { formatNegative, zeroIfNull } from '../../utility/numberFormat'
import OrderOptions from '@material-ui/icons/MenuOpenRounded'
import { Popover, FormGroup } from '@material-ui/core'

import {getNewDiscordTokenWithRefresh, getDiscordServers, getChannels,
     getGuildChannels, setChannelsAPI, botURL, userAuthURL, updateguild} from '../../api/user/discord'
import {loadTokenAction, savedChannelsAction} from '../../actions/discord'



export const Discord = ({notify}) => {

    
    const user = useSelector(state => state.user)
    const discordState = useSelector(state => state.discord)
    const dispatch = useDispatch()

    const [servers, setServers] = useState([])
    const [channels, setChannels] = useState([])
    const [showRefreshModal, setShowRefreshModal] = useState(false)
    const [showServerModal, setShowServerModal] = useState(false)
    const [showChannelModal, setShowChannelModal] = useState(false)

    let savedChannels = discordState.guilds
    if (!savedChannels){
        savedChannels = []
    }
    const setSavedChannels = (data) => {
        dispatch(savedChannelsAction(data))
    }

    const refreshToken = () => {
        return getNewDiscordTokenWithRefresh(user.UserID, user.IdToken, user.AccessToken, discordState.token.refresh_token)
            .then(handleErrors)
            .then(results => {
                if (!results || ! results.access_token){
                    return
                }
                dispatch(loadTokenAction(results))
                notify('Token Change', 'Discord Token Refreshed')
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
            })
    }

    const getServers = () => {
        return getDiscordServers(user.UserID, user.IdToken, user.AccessToken, discordState.token.access_token)
            .then(handleErrors)
            .then(results => {
                if (results){
                    setServers(results)
                    setShowServerModal(true)
                }
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
            })
    }

    const linkDiscordBot = () => {
        window.open(botURL)
    }

    const linkDiscordUser = () => {
        setShowRefreshModal(true)
        window.open(userAuthURL)
    }

    const updateServer = (x, linked, enabled) => {
        if (linked !== undefined){
            x.Linked = true
        }
        if (enabled !== undefined){
            x.Enabled = enabled
        }
        let newServers = servers.map(a => {
            if (x.ID === a.ID){
                a.Linked = x.Linked
                a.Enabled = x.Enabled
            }
            return a
        })
        setServers(newServers)

        return updateguild(user.UserID, user.IdToken, user.AccessToken, x)
            .then(handleErrors)
            .then(results => {
                if (results !== 'Success'){
                    notify('Error', results.toString())
                }
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
            })
    }

    const getAllChannels = () => {
        return getChannels(user.UserID, user.IdToken, user.AccessToken, discordState.token.access_token)
            .then(handleErrors)
            .then(results => {
                if (!results){
                    return;
                }
                setChannels(results)
                setSavedChannels(results)
                setShowChannelModal(true)
                console.log('All Channels', results)
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
            })
    }

    const getAllGuildChannels = (x) => {
        return getGuildChannels(user.UserID, user.IdToken, user.AccessToken, discordState.token.access_token, x.Id)
            .then(handleErrors)
            .then(results => {
                if (results && results.length){
                    let newChannels = channels.map(y => {
                        if (y.Id === x.Id){
                            return results[0]
                        }
                        return y
                    })
                    setChannels(newChannels)
                }
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
            })
    }

    const handleChannelLinked = (gid, id, add) => {
        let allSaved = savedChannels.map(x => {
            if (x.Id === gid){
                if (add){
                    let newChannel = channels.filter(y => y.Id === gid)[0].DiscordChannels.filter(y => y.Id == id)[0]
                    x.DiscordChannels.push(newChannel)
                }
                else{
                    x.DiscordChannels = x.DiscordChannels.filter(y => y.Id !== id)
                }
            }
            return x
        })
        setSavedChannels(allSaved)
        updateSavedChannelsOnServer(allSaved)
    }

    const handleDefaultOn = (gid, id, checked) => {
        let allSaved = savedChannels.map(x => {
            if (x.Id === gid){
                x.DiscordChannels = x.DiscordChannels.map(y => {
                    if (y.Id === id){
                        y.DefaultOn = checked
                    }
                    return y
                })
            }
            return x
        })
        setSavedChannels(allSaved)
        updateSavedChannelsOnServer(allSaved)
    }

    const updateSavedChannelsOnServer = (newGuilds) => {
        return setChannelsAPI(user.UserID, user.IdToken, user.AccessToken, newGuilds)
            .then(handleErrors)
            .then(results => {
                if (results !== 'Success'){
                    notify('Error', 'Error Linking Channels')
                }
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
            })
    }

    return (
        <Card bg="dark">
            <Card.Header style={{color: 'white', display: 'flex', justifyContent: 'center'}}><b>Discord</b></Card.Header>
            <Card.Body>
                <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                {discordState.token && discordState.token.access_token
                ? (
                    <Fragment>
                        <Button onClick={linkDiscordBot} variant="secondary">
                            Link Bot
                        </Button>
                        <Button onClick={refreshToken} variant="secondary">
                            Refresh Token
                        </Button>
                        <Button onClick={getServers} variant="secondary">
                            Link Servers
                        </Button>
                        <Button onClick={getAllChannels} variant="secondary">
                            Link Channels
                        </Button>
                    </Fragment>
                )
                : (
                    <Button onClick={linkDiscordUser} variant="secondary">
                        Link Discord
                    </Button>
                )}
                </div>
            </Card.Body>

            {/* Link User */}
            <Modal show={showRefreshModal} onHide={() => {setShowRefreshModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Linking</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>Click the button below once you have completed the link</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {setShowRefreshModal(false); window.location.reload()}}>
                        Complete Link
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Handle Guild Linking */}
            <Modal show={showServerModal} onHide={() => {setShowServerModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Server Linking</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {servers.map(x => {
                            return (
                                <ListGroup.Item>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        {x.Icon ? <img src={`https://cdn.discordapp.com/icons/${x.ID}/${x.Icon}.png?size=32`} /> : <span>&nbsp;</span>}
                                        <span>{x.Name}</span>
                                        <FormCheck type="checkbox" label="Linked" checked={x.Linked} disabled={x.Linked} onChange={(e) => {updateServer(x, e.target.checked)}} />
                                        <FormCheck type="checkbox" label="Enabled" checked={x.Enabled} onChange={(e) => {updateServer(x, undefined, e.target.checked)}} />
                                    </div>
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </Modal.Body>
            </Modal>

            {/* Handle Channel Settings */}
            <Modal show={showChannelModal} onHide={() => {setShowChannelModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Linking</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {channels.map(x => {
                            let savedMatch = savedChannels.filter(a => a.Id === x.Id)
                            return (
                                <Fragment>
                                    <ListGroup.Item>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <span>{x.Name}</span>
                                            <Button onClick={() => {getAllGuildChannels(x)}}>Load Channels</Button>
                                        </div>
                                        </ListGroup.Item>
                                    <ListGroup>
                                        {x.DiscordChannels && x.DiscordChannels.map(y => {
                                            let savedChannel = savedMatch.length ? savedMatch[0].DiscordChannels.filter(b => b.Id === y.Id) : []
                                            return (
                                                <ListGroup.Item>
                                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                        <span>{y.Name}</span>
                                                        <div style={{display: 'flex'}}>
                                                            <FormCheck onChange={(e) => {handleChannelLinked(x.Id, y.Id, e.target.checked)}} type="checkbox" label="Linked" checked={savedChannel.length} style={{marginRight: '15px'}} />
                                                            <FormCheck onChange={(e) => {handleDefaultOn(x.Id, y.Id, e.target.checked)}} type="checkbox" label="Default On" checked={savedChannel.length ? savedChannel[0].DefaultOn : false} disabled={!savedChannel.length} />
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                            )
                                        })}
                                    </ListGroup>
                                </Fragment>
                            )
                        })}
                    </ListGroup>
                </Modal.Body>
            </Modal>
        </Card>
    )
}
