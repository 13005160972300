import React, { Fragment } from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import {SignIn} from './components/login/signinnew'
import { useSelector } from 'react-redux'
import { GeneralNav } from './components/nav/general';
import { Ally } from './components/trading/ally/base';
import { TDSync } from './components/trading/td/tdsync';
import { SignOut } from './components/login/signout';
import { TD } from './components/trading/td/base'
import  UserSettings  from './components/login/user'
import SignUp from './components/login/signup'
import {DiscordSync} from './components/discord/discordsync'
import {DiscordManager} from './components/discord/discordManager'
import { DiscordUserAccount } from './components/discord/discordUserAccount'
import { DiscordUserSync } from './components/discord/discordUserSync'
import { LiteNav } from './components/nav/lite'
import { DiscordTestimonials } from './components/discord/discordTestimonials'
import { DiscordUserManager } from './components/discord/discordUserManager'
import { ManagerSMS } from './components/discord/discordmanager/managerSMS'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { FeaturesMobile } from './components/login/featuresmobile'
import { Pricing } from './components/login/pricing'
import { Landing } from './components/login/landing'
import Helmet from 'react-helmet'
import { EULA } from './components/legal/eula';
import { PrivacyPolicy } from './components/legal/privacy';
import { QBAuth } from './components/discord/discordowner/qbauth';

const App = () => {
  const user = useSelector(state => state.user)
  const sameDayToken = new Date(user.tokenTime).getDate() === new Date().getDate()
  const loggedIn = user.IdToken && sameDayToken
  const managerAllowed = user.DiscordManager
  const userManager = user.CanAccessOtherManagers

  const tdState = useSelector(state => state.td)
  const isBig = useMediaQuery('(min-width:600px)');


  const liteNav = window.location.pathname.includes('/a/') || window.location.pathname.includes('/discorduser')
  const noNav = window.location.pathname.includes('/t/') || liteNav

  const loadCustom = window.location.pathname.includes('/t/') || window.location.pathname.includes('/a/')
  const serverURL = loadCustom && window.location.pathname.replace('/a/', '').replace('/t/', '')
  const loadTradingView = window.location.pathname.includes('/td') || window.location.pathname.includes('/ally')
  return (
    <Fragment>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Varbis.NET - Varbis Discord Subscription Manager"
        />
        <meta name="keywords" content="discord, discord manager, discord subscription, discord subscription manager, discord stripe" />
        <meta name="robots" content="index,follow" />        
        <title>Varbis.NET</title>
        {loadTradingView && (
          <script type="text/javascript" src="https://s3.tradingview.com/tv.js"></script>
        )}
      </Helmet>
      <Router>
        {loadCustom && (
          <link rel="stylesheet" type="text/css" href={`${serverURL}.css`} />
        )}
        
        {noNav ? '' : liteNav ? <LiteNav /> : <GeneralNav />}
        <Route path='/' exact component={!loggedIn ? Landing : UserSettings} />
        <Route path='/ally' exact component={!loggedIn ? SignIn : Ally} />
        <Route path='/td' exact component={!loggedIn ? SignIn : tdState.token ? TD : UserSettings} />
        <Route path='/tdsync' exact component={TDSync} />
        <Route path='/signout' exact component={SignOut} />
        <Route path='/user' exact component={!loggedIn ? SignIn : UserSettings } />
        <Route path='/signup' exact component={SignUp} />
        <Route path='/discord' exact component={DiscordSync} />
        <Route path='/discorduser' exact component={DiscordUserSync} />
        <Route path='/discordmanager' exact component={!loggedIn ? SignIn : managerAllowed ? DiscordManager : UserSettings} />
        <Route path='/usermanager' exact component={!loggedIn ? SignIn : userManager ? DiscordUserManager : UserSettings} />
        <Route path='/sendsms' exact component={!loggedIn ? SignIn : (userManager || user.DiscordManager) ? ManagerSMS : UserSettings} />
        <Route path='/a/:server' component={DiscordUserAccount} />
        <Route path='/t/:server' component={DiscordTestimonials} />
        <Route path='/features' exact component={FeaturesMobile} />
        <Route path='/pricing' exact component={Pricing} />
        <Route path='/signin' exact component={!loggedIn ? SignIn : UserSettings} />
        <Route path='/eula' exact component={EULA} />
        <Route path='/privacypolicy' exact component={PrivacyPolicy} />
        <Route path='/qbauth' exact component={QBAuth} />
      </Router>
    </Fragment>
  );
}

export default App;
