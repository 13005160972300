
import { RepeatOneSharp } from '@material-ui/icons'
import { requestType } from './socket'

export const handleGetSettings = (response, props) => {
    props.setQBSettings(response)
    if (!response.StripeSecretKey){
        props.setMappingError('Stripe Key Required')
    }
    else if (props.mappingError === true){
        // load mapping data
        loadMappingData(props)
    }
}

export const handleSaveSettings = (response, props) => {
    
    if (response.IsError){
        props.notify('Error', 'Error Saving Settings')
    }
    else{
        if (props.mappingError && props.qbSettings.StripeSecretKey && props.qbAuthInfo.HasQBToken){
            props.setMappingError(true)
            // load mapping data
            loadMappingData(props)
        }
        else if (!props.mappingError && !props.qbSettings.StripeSecretKey){
            props.setMappingError('Stripe Key Required')
        }
    }
    props.setSavingSettings(false)
}

export const handleAuth = (response, props) => {
    if (props.GetTokens){
        // Token Authing
        const request = {
            RequestType: requestType.GetTokens,
            Data: {
                Code: props.code,
                RealmId: props.realmId,
                State: props.state
            }
        }
        props.thisSocket.send(JSON.stringify(request))
    }
    else{
        // Load Settings
        props.thisSocket.send(JSON.stringify({
            RequestType: requestType.GetSettings
        }))

        if (!response.HasQBToken){
            props.setMappingError('Authorization Needed')
        }
        else{
            loadQBAccounts(props)
            loadQBVendors(props)
        }
        props.qbAuthInfo = response
        props.setQBAuthInfo(response)
    }
}

export const handleGetTokens = (response, props) => {
    if (response.IsError){
        props.setMessage('Error Linking QuickBooks')
    }
    else{
        props.setMessage('Linked! You may now close this window')
    }
}

export const handleTokenUpdate = (response, props) => {
    const updatedInfo = Object.assign({}, props.qbAuthInfo)
    updatedInfo.HasQBToken = true
    props.setQBAuthInfo(updatedInfo)
    props.qbAuthInfo = updatedInfo
    if (props.mappingError && props.qbSettings.StripeSecretKey){
        props.setMappingError(false)
    }
}

export const handleProcessUpdate = (response, props) => {
    if (!response.IsError){
        const clonedUpdates = props.processUpdates.map(x => x)
        clonedUpdates.unshift(response.Message)
        props.processUpdates = clonedUpdates
        props.setProcessUpdates(clonedUpdates)
        
        loadQBAccounts(props)
        loadQBVendors(props)
    }
}

export const handleProcessDone = (response, props) => {
    if(response.IsError){
        props.notify('Error', `Error Code ${response.ErrorCode}`)
    }
    props.setIsProcessing(false)
}

export const loadMappingData = (props) => {
    const request = {
        RequestType: requestType.GetMappings
    }
    props.thisSocket.send(JSON.stringify(request))
}

export const loadQBAccounts = (props) => {
    const request = {
        RequestType: requestType.GetQBAccounts
    }
    props.thisSocket.send(JSON.stringify(request))
}

export const loadQBVendors = (props) => {
    const request = {
        RequestType: requestType.GetQBVendors
    }
    props.thisSocket.send(JSON.stringify(request))
}

export const handleGetQBVendors = (response, props) => {
    if (response.IsError){
        props.notify('Error Loading QB Vendors')
    }
    else{
        props.setQBVendors(response)
        props.loadedQBVendors = true
        if (props.loadedQBAccounts && props.loadedMappings){
            props.setMappingError(false)
        }
    }
}

export const handleGetQBAccounts = (response, props) => {
    if (response.IsError){
        props.notify('Error Loading QB Accounts')
    }
    else{
        props.setQBAccounts(response)
        props.loadedQBAccounts = true
        if (props.loadedQBAccounts && props.loadedMappings){
            props.setMappingError(false)
        }
    }
}

export const handleGetMappings = (response, props) => {
    if (response.IsError){
        props.notify('Error Loading Mappings')
    }
    else{
        props.setMappings(response)
        props.loadedMappings = true
        if (props.loadedQBAccounts && props.loadedQBVendors){
            props.setMappingError(false)
        }
    }
}