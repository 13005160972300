export const LOAD_TOKEN = 'LOAD_TOKEN'
export const SAVE_GUILDS = 'SAVE_GUILDS'

export function loadTokenAction(token){
    token.tokenTime = new Date();
    if (token.refresh_token === null){
        delete token.refresh_token
    }
    return {
        type: LOAD_TOKEN,
        data: token
    }
}

export function savedChannelsAction(guildsAndChannels){
    return {
        type: SAVE_GUILDS,
        data: guildsAndChannels
    }
}