import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Card, ListGroup, Table, Form, FormControl, Button, Modal, OverlayTrigger, PopoverTitle, PopoverContent } from 'react-bootstrap'
import { makeStyles } from '@material-ui/styles'
import { handleErrors } from '../../../api/base'
import { refreshAPI } from '../../../api/user/auth'
import { validateSignIn, signOutAction } from '../../../actions/user'
import { getAccountInfo, getOrders, getStrikes, getExpirations, getQuote, previewOrderAPI, placeOrderAPI } from '../../../api/trading/ally'
import { formatNegative, zeroIfNull } from '../../../utility/numberFormat'
import OrderOptions from '@material-ui/icons/MenuOpenRounded'
import { Popover, FormGroup } from '@material-ui/core'
import { setSymbolAction, setStockToggleAction } from '../../../actions/ally'

const useStyles = makeStyles({
    spaced: {
        margin: '3px'
    }
})

export const Trade = ({toasts, setToasts, ...props}) => {
    const classes = useStyles()
    const {getExps, getStks,
        expiration, setExpiration, strike, setStrike, 
         setExpirations, side, setSide, setDDLDefaults, getQte,
          quote, quantity, setQuantity, loadInfo} = props
    const [price, setPrice] = useState('0')
    const [stopPrice, setStopPrice] = useState('0')
    const [orderType, setOrderType] = useState('1')
    const [orderLength, setOrderLength] = useState('0')
    const [optionSide, setOptionSide] = useState('0')
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const allyState = useSelector(state => state.ally)

    const [fullQuote, setFullQuote] = useState({})
    
    const [message, setMessage] = useState('')
    const [orderPreview, setOrderPreview] = useState(null)
    const [showModal, setShowModal] = useState(false)

    // map store to vars
    const symbol = allyState.symbol
    const setSymbol = (x) => {dispatch(setSymbolAction(x))}
    const stockToggle = allyState.stockToggle
    const setStockToggle = (x) => {dispatch(setStockToggleAction(x))}
    const strikes = allyState.strikes
    const expirations = allyState.expirations

    useEffect(() => {
        try{
            const interval = setInterval(() => {
                getQte()
            }, 20000);
            return () => clearInterval(interval)
        }
        catch (err){
            console.log(err)
        }
    }, [])





    const updateData = (force) => {
        let load = false
        if (force !== undefined){
            load = force
        }
        else{
            load = stockToggle
        }
        if (load){            
            getExps(symbol)
            getQte(symbol)
                .then(() => {
                    getStks(symbol)
                })
        }
    }

    const handleUpdateQuote = () => {
        try{
            if (!symbol || !expiration || !strike){
                return
            }
            let test = symbol
            let date = new Date(expiration)
            test += date.getFullYear().toString().slice(-2)
            test += (date.getMonth() + 1).toString().padStart(2, '0')
            test += date.getDate().toString().padStart(2, '0')
            test += Number(optionSide) ? 'P' : 'C'
            test += Number(strike).toFixed(3).replace('.', '').padStart(8, '0')

            getQuote(test, user.UserID, user.IdToken, user.AccessToken)
                .then(handleErrors)
                .then(result => {
                    if (result == null){
                        return
                    }
                    setFullQuote(result)
                })
                .catch(error => error)
                .then(msg => {
                    if (msg) {
                        setMessage(msg)
                    }
                })
        }
        catch (err) { }
    }


    const generateTradeOrder = () => {
        let order = {
            SecurityType: stockToggle ? 'OPT' : 'CS',
            Quantity: zeroIfNull(quantity),
            Price: zeroIfNull(price),
            StopPrice: zeroIfNull(stopPrice),
            OrderAction: 0, // New
            TimeInForce: zeroIfNull(orderLength),
            Symbol: symbol,
            OrderType: zeroIfNull(orderType),

        }
        if (stockToggle){
            //option
            const sideParts = side.split('|')
            order.OrderSide = zeroIfNull(sideParts[0])
            order.PositionEffect = sideParts[1]
            order.Strike = zeroIfNull(strike)
            order.Expiration = new Date(expiration)
            order.OptionType = zeroIfNull(optionSide)
        }
        else{
            //stock
            order.OrderSide = zeroIfNull(side)
        }
        return order
    }

    const previewOrder = () => {
        const order = generateTradeOrder()
        previewOrderAPI(order, user.UserID, user.IdToken, user.AccessToken)
            .then(handleErrors)
            .then(result => {
                setOrderPreview(result)
                setShowModal(true)
            })
            .catch(error => error)
            .then(msg => {
                if (msg) {
                    setMessage(msg)
                }
            })
    }

    const placeOrder = () => {
        const order = generateTradeOrder()
        placeOrderAPI(order, user.UserID, user.IdToken, user.AccessToken)
            .then(handleErrors)
            .then(result => {
                let tsts = toasts.map(x => x)
                if (result.Error){
                    tsts.push(`Error ${result.Error}`)
                }
                else{
                    tsts.push(`Order ${result.OrderStatus}`)
                }
                setToasts(tsts)
                setTimeout(loadInfo, 500)
            })
            .catch(error => error)
            .then(msg => {
                if (msg) {
                    setMessage(msg)
                }
            })
    }

    const toastTest = () => {
        let tsts = toasts.map(x => x)
        tsts.push('New Test')
        setToasts(tsts)
    }

    return (
        <Card bg="dark">
                <Card.Header as={Row} style={{color: 'white', marginRight: '0px', marginLeft: '0px'}}>
                    <Col style={{display: 'flex'}} sm={6} lg={3}>
                        <b style={{marginRight: '20px'}}>Trade</b>
                        <Form>
                            <Form.Check checked={stockToggle} type="switch" id="allyStockToggle" label={stockToggle ? "Options" : "Stock"} onChange={(e) => {setStockToggle(e.target.checked); updateData(e.target.checked);}} />
                        </Form>
                    </Col>
                    <Col sm={6} lg={3} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        {quote ? `$${quote.Last.toFixed(4)}` : ''}
                    </Col>
                    <Col sm={12} lg={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        {quote ? quote.Name : ''}
                    </Col>
                </Card.Header>
                <Card.Body>
                    <Form style={{color: 'white'}}>
                        <Form.Group as={Row} controlId="firstRow">
                            <Form.Label column sm={6} lg={3}>
                                Symbol
                            </Form.Label>
                            <Col sm={6} lg={3}>
                                <Form.Control placeholder="Symbol" id="allySymbol" value={symbol} onChange={(e) => {setSymbol(e.target.value)}} onBlur={(e) => {updateData(e.target.value);}} />
                            </Col>
                            <Form.Label column sm={6} lg={3}>
                                Order Side
                            </Form.Label>
                            <Col sm={6} lg={3}>
                                <Form.Control value={side} as="select" id="sideDDL" onChange={(e) => {setSide(e.target.value)}}>
                                    {!stockToggle ? (
                                        <Fragment>
                                            <option value="1">Buy</option>
                                            <option value="2">Sell</option>
                                            <option value="10">Buy To Cover</option>
                                            <option value="5">Sell Short</option>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <option value="1|O">Buy To Open</option>
                                            <option value="2|O">Sell To Open</option>
                                            <option value="1|C">Buy To Close</option>
                                            <option value="2|C">Sell To Close</option>
                                        </Fragment>
                                    )}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="secondRow">
                            <Form.Label column sm={6} lg={3}>
                                Order Type
                            </Form.Label>
                            <Col sm={6} lg={3}>
                                <Form.Control id="allyOrderTypeDDL" as="select" onChange={(e) => {setOrderType(e.target.value)}}>
                                    <option value="1">Market</option>
                                    <option value="2">Limit</option>
                                    <option value="3">Stop</option>
                                    <option value="4">Stop Limit</option>
                                </Form.Control>
                            </Col>
                            {orderType === '1' || orderType === '3' ?
                            <Fragment>
                                <Col sm={6} lg={3}></Col>
                                <Col sm={6} lg={3}></Col>
                            </Fragment>
                            :
                            <Fragment>
                                <Form.Label column sm={6} lg={3}>
                                    Price
                                </Form.Label>
                                <Col sm={6} lg={3}>
                                    <Form.Control value={price} id="allyPrice" placeholder="0.00" onChange={(e) => {setPrice(e.target.value)}} />
                                </Col>
                            </Fragment>
                            }
                        </Form.Group>
                        <Form.Group as={Row} controlId="thirdRow">
                            <Form.Label column sm={6} lg={3}>
                                Order Length
                            </Form.Label>
                            <Col sm={6} lg={3}>
                                <Form.Control as="select" onChange={(e) => {setOrderLength(e.target.value)}}>
                                    <option value="0">Day</option>
                                    <option value="1">GTC</option>
                                </Form.Control>
                            </Col>
                            {orderType === '4' || orderType === '3' ?
                            <Fragment>
                                <Form.Label column sm={6} lg={3}>
                                    Stop Price
                                </Form.Label>
                                <Col sm={6} lg={3}>
                                    <FormControl value={stopPrice} id="allyStop" placeholder="0.00" onChange={(e) => {setStopPrice(e.target.value)}} />
                                </Col>
                            </Fragment>
                            :
                            <Fragment>
                                <Col sm={6} lg={3}></Col>
                                <Col sm={6} lg={3}></Col>
                            </Fragment>
                            }
                        </Form.Group>
                        <Form.Group as={Row} controlId="fifthRow">
                            <Form.Label column sm={6} lg={3}>
                                Quantity
                            </Form.Label>
                            <Col sm={6} lg={3}>
                                <Form.Control value={quantity} id="allyQty" placeholder="0" onChange={(e) => {setQuantity(e.target.value)}} />
                            </Col>
                            {stockToggle ? 
                            <Fragment>
                                <Form.Label column sm={6} lg={3}>
                                    Option Side
                                </Form.Label>
                                <Col sm={6} lg={3}>
                                    <Form.Control value={optionSide} id="allyOptSide" as="select" onChange={(e) => {setOptionSide(e.target.value)}}>
                                        <option value="0">Call</option>
                                        <option value="1">Put</option>
                                    </Form.Control>
                                </Col>
                            </Fragment>
                            : <Fragment>
                                <Col sm={6} lg={3}></Col>
                                <Col sm={6} lg={3}></Col>
                            </Fragment>
                            }
                        </Form.Group>
                        {stockToggle ?
                            <Fragment>
                            <Form.Group as={Row} controlId="fourthRow">
                                <Form.Label column sm={6} lg={3}>
                                    Strike
                                </Form.Label>
                                <Col sm={6} lg={3}>
                                    <Form.Control value={strike} id="strikeDDL" as="select" onChange={(e) => {setStrike(e.target.value)}}>
                                        {strikes && strikes.map(x => 
                                            <option value={`${x.Price}`}>${x.Price}</option>                                        
                                        )}
                                    </Form.Control>
                                </Col>
                                <Form.Label column sm={6} lg={3}>
                                    Expiration
                                </Form.Label>
                                <Col sm={6} lg={3}>
                                    <Form.Control value={expiration} id="expDDL" as="select" onChange={(e) => {setExpiration(e.target.value)}}>
                                        {expirations && expirations.map(x => (
                                            <option value={`${x}`}>{x}</option>
                                        ))}
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            </Fragment>
                            : ''
                        }                        
                        <Form.Group as={Row} controlId="sixthRow">
                            <Col sm={12} lg={6}>
                                <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                                    <Button variant="secondary" onClick={previewOrder} className={classes.spaced}>Preview Order</Button>
                                    <Button variant="secondary" onClick={placeOrder} className={classes.spaced}>Place Order</Button>
                                </div>
                            </Col>
                            <Col sm={12} lg={6}>
                                <div style={{display: 'flex', justifyContent: 'space-evenly'}} className={classes.spaced}>
                                    <span>Bid : <span onClick={(e) => {setPrice(e.target.innerText)}}>{stockToggle ? fullQuote.Bid : quote ? quote.Bid : ''}</span></span>
                                    <span>Ask : <span onClick={(e) => {setPrice(e.target.innerText)}}>{stockToggle ? fullQuote.Ask : quote ? quote.Ask : ''}</span></span>
                                    <span>Last : <span onClick={(e) => {setPrice(e.target.innerText)}}>{stockToggle ? fullQuote.Last : quote ? quote.Last : ''}</span></span>
                                    <Button variant="secondary" onClick={() => {handleUpdateQuote()}} className={classes.spaced}>Update Quote</Button>
                                </div>
                            </Col>
                        </Form.Group>
                    </Form>
                </Card.Body>
                <Modal show={showModal} onHide={() => {setShowModal(false)}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Order Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {orderPreview && orderPreview.Error && (<div>Error : {orderPreview.Error}</div>)}
                            {orderPreview && orderPreview.Warning && (<div>{orderPreview.Warning}</div>)}
                            {orderPreview && !orderPreview.Error && (
                                <Fragment>
                                    <div>Commission : {orderPreview ? orderPreview.Commission : '0'}</div>
                                    <div>Principal : {orderPreview ? orderPreview.Principal : '0'}</div>
                                    <div>Margin Requirement : {orderPreview ? orderPreview.MarginRequirement : '0'}</div>
                                </Fragment>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => {setShowModal(false)}}>Cancel</Button>
                        {orderPreview && !orderPreview.Error && (
                            <Button variant="secondary" onClick={() => {placeOrder(); setShowModal(false)}}>Place Order</Button>
                        )}
                    </Modal.Footer>
                </Modal>
        </Card>
    )
}