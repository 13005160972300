import React, { useEffect } from 'react'
import { Redirect } from "react-router-dom"
import { signOutAction } from '../../actions/user'
import { useDispatch } from 'react-redux'


export const SignOut = () => {    
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(signOutAction())
    }, [])

    return (
        <Redirect to="/signin" />
    )
}