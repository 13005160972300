import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Card, ListGroup, Table, Form, FormControl, Button, Modal, OverlayTrigger, PopoverTitle, PopoverContent } from 'react-bootstrap'
import { makeStyles } from '@material-ui/styles'
import { handleErrors } from '../../../api/base'
import { refreshAPI } from '../../../api/user/auth'
import { validateSignIn, signOutAction } from '../../../actions/user'
import { getAccountInfo, getOrders, getStrikes, getExpirations, getQuote, previewOrderAPI, placeOrderAPI } from '../../../api/trading/ally'
import { formatNegative, zeroIfNull } from '../../../utility/numberFormat'
import { Toasty } from '../toast'
import OrderOptions from '@material-ui/icons/MenuOpenRounded'
import { Popover, FormGroup } from '@material-ui/core'
import {BuyingPower} from './buyingpower'
import {Trade} from './trade'
import {Holdings} from './holdings'
import {Orders} from './newOrders'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { setSymbolAction, setStockToggleAction, clearAlly, setExpirationsAction, setStrikesAction } from '../../../actions/ally'
import { Redirect } from 'react-router-dom'
import { TradeViewGraph } from '../tradingview/graph2'

const useStyles = makeStyles({
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    column: {
        flexDirection: 'column'
    },
    fullHeight: {
        height: '100vh'
    },
    big: {
        height: '40%',
        width: '80%'
    },
    outline: {
        border: '.2rem solid #ececec',
        borderRadius: '8px'
    },
    darkJumbo: {
        backgroundColor: '#d2d5d8'
    },
    padded: {
        padding: '15px'
    },
    noPad: {
        padding: '0px'
    }
})

export const Ally = () => {
    const classes = useStyles()
    const [holdings, setHoldings] = useState(null)
    const [orders, setOrders] = useState(null)
    const [message, setMessage] = useState('')
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const [toasts, setToasts] = useState([])
    const [stockToggle, setStockToggle] = useState(false)
    const [expiration, setExpiration] = useState('')
    const [strike, setStrike] = useState('0')
    const [side, setSide] = useState('1')
    const [quote, setQuote] = useState(null)
    const [quantity, setQuantity] = useState('0')
    const [toSettings, setToSettings] = useState(false)

    const allyState = useSelector(state => state.ally)
    const symbol = allyState.symbol
    const setSymbol = (x) => {dispatch(setSymbolAction(x))}
    const setExpirations = (x) => {dispatch(setExpirationsAction(x))}
    const setStrikes = (x) => {dispatch(setStrikesAction(x))}

    const isBig = useMediaQuery('(min-width:600px)');

    useEffect(() => {
        try{
            // dispatch(clearAlly())
            if (holdings == null){
                loadInfo()
            }
            const interval = setInterval(() => {
                loadInfo()
            }, 20000);
            return () => clearInterval(interval)
        }
        catch (err){
            console.log(err)
        }
    }, [])

    const loadInfo = () => {
        getAccountInfo(user.UserID, user.IdToken, user.AccessToken)
        .then(handleErrors)
        .then(result => {
            if (result == null){
                return;
            }
            console.log(result)
            setHoldings(result)
        })
        .catch(error => error)
        .then(msg => {
            if (msg) {
                if (msg.message === 'Failed to fetch'){
                    dispatch(signOutAction())
                }
                setMessage(msg)
            }
        })

        getOrders(user.UserID, user.IdToken, user.AccessToken)
            .then(handleErrors)
            .then(result => {
                if (result == null){
                    return;
                }
                setOrders(result)
            })
            .catch(error => error)
            .then(msg => {
                if (msg) {
                    if (msg === 'Refresh'){
                        refreshAPI(user.RefreshToken)
                            .then(handleErrors)
                            .then(result => {
                                dispatch(validateSignIn(result))
                                window.location.reload()
                            })
                            .catch(error => error)
                            .then(msg => {
                                if (msg && msg.message === '404'){
                                    dispatch(signOutAction())
                                }
                            })
                    }
                    if (msg === 'Settings'){
                        setToSettings(true)
                    }
                    setMessage(msg)
                }
            })
    }

    const getExps = (sym) => {
        
        if (!sym) {
            sym = symbol
        }
        if (!sym){
            return;
        }
        
        return getExpirations(sym, user.UserID, user.IdToken, user.AccessToken)
            .then(handleErrors)
            .then(result => {
                setExpirations(result)
            })
            .catch(error => error)
            .then(msg => {
                if (msg) {
                    setMessage(msg)
                }
                setDDLDefaults()
            })

    }

    const getStks = (sym) => {
        
        if (!sym) {
            sym = symbol
        }
        if (!sym){
            return;
        }
        return getStrikes(sym, user.UserID, user.IdToken, user.AccessToken)
            .then(handleErrors)
            .then(result => {
                console.log(result)
                result = result.map(x => {
                    x = {Price: x}
                    return x
                })
                setStrikes(result)
                setTimeout(() => {updateStrikes(result)}, 500)
            })
            .catch(error => error)
            .then(msg => {
                if (msg) {
                    setMessage(msg)
                }
            })
    }

    const updateStrikes = (stks) => {
        let found = false
        const qt = JSON.parse(sessionStorage.getItem('quote'))
        const last = qt ? qt.Last : 0
        let targetStrike
        let newStrikes = stks.map(x => {            
            if (!found && x.Price >= last){
                targetStrike = x.Price
                found = true
            }
            return x
        })
        setStrikes(newStrikes)
        setDDLDefaults()
        setStrike(targetStrike)
    }
    
    const setDDLDefaults = () => {
        let sde = document.getElementById('sideDDL')
        if (!sde){
            return
        }
        sde = sde.value
        setSide(sde)
        let stk = document.getElementById('strikeDDL')
        let exp = document.getElementById('expDDL')
        if (!stk || !exp){
            return
        }
        stk = stk.value
        setStrike(stk)
        exp = exp.value
        setExpiration(exp)
    }

    const getQte = (sym) => {
        if (!sym) {
            sym = document.getElementById('allySymbol').value
        }
        if (!sym){
            return Promise.resolve()
        }
        return getQuote(sym, user.UserID, user.IdToken, user.AccessToken)
            .then(handleErrors)
            .then(result => {
                console.log(result)
                setQuote(result)
                sessionStorage.setItem('quote', JSON.stringify(result))
            })
            .catch(error => error)
            .then(msg => {
                if (msg) {
                    setMessage(msg)
                }
                setDDLDefaults()
            })
    }


    //Trading Props    
    const tradeProps = {symbol, setSymbol, stockToggle, setStockToggle, getExps, getStks,
        expiration, setExpiration, strike, setStrike, quantity, setQuantity,
         side, setSide, setDDLDefaults, getQte, quote, loadInfo}

    if (toSettings){
        return <Redirect to="/user" />
    }

    return (
        <Container fluid>
            <Card bg="dark">
                <Card.Header style={{color: 'white'}}><b>Ally &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {holdings ? `Account Value: $${holdings.Value}` : 'Loading Account...'}</b></Card.Header>
                <Card.Body className={isBig ? '' : classes.noPad}>
                    <Container fluid className={isBig ? '' : classes.noPad}>
                        <Row>
                            <Col sm={12} lg={6}>
                                <Row>
                                    <Col sm={12}>
                                        <BuyingPower holdings={holdings} />
                                    </Col>
                                </Row>
                                {symbol
                                ? <Row>
                                    <Col sm={12} className={classes.padded}>
                                        <TradeViewGraph symbol={symbol} />
                                    </Col>
                                </Row>
                                : ''}
                                <Row>
                                    <Col sm={12} className={classes.padded}>
                                        <Holdings holdings={holdings} {...tradeProps} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12} lg={6}>
                                <Row>
                                    <Col sm={12} className={classes.padded}>
                                        <Trade toasts={toasts} setToasts={setToasts} {...tradeProps} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} className={classes.padded}>
                                    <Orders orders={orders} toasts={toasts} setToasts={setToasts} loadInfo={loadInfo} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>            
            <div style={{position: 'fixed', top: '20px', right: '20px'}}>
                {toasts.map(x => {
                    return <Toasty title="Order Confirmation" message={x} />
                })}
            </div>
        </Container>
    )
}

