import logger from './logger'
import thunk from 'redux-thunk'
import { applyMiddleware, compose } from 'redux'


//Prod
export default compose(applyMiddleware(thunk))

//Local
//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
// export default composeEnhancers(applyMiddleware(thunk, logger))
