import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import { roundMoney } from '../../../utility/numberFormat'
import { connect, useSelector, useDispatch } from 'react-redux'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Container, Row, Col, Card, ListGroup, Table, Form, FormControl, Button, Modal, Nav, Badge, InputGroup } from 'react-bootstrap'
import { getCarriersAPI, setUserInfoAPI, verifyCodeAPI, resendCodeAPI } from '../../../api/user/discorduser'
import { handleErrors } from '../../../api/base'
import CircularProgress from '@material-ui/core/CircularProgress'
import BadIcon from '@material-ui/icons/Error'
import GoodIcon from '@material-ui/icons/Done'


export const LinkData = ({props, notify, back}) => {


    const dUser = useSelector(state => state.discordUser)

    const isBig = useMediaQuery('(min-width:900px)');

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [allowTxt, setAllowTxt] = useState(true)
    const [phone, setPhone] = useState('')
    const [carrier, setCarrier] = useState('')
    const [carriers, setCarriers] = useState([])
    const [loading, setLoading] = useState(false)
    const [verify, setVerify] = useState(false)
    const [verificationCode, setVerificationCode] = useState('')
    const [isDone, setIsDone] = useState(false)
    
    useEffect(() => {
        getInfo()
    }, [dUser])

    const getInfo = () => {
        const match = props.accountData && props.accountData.DiscordAccountID && dUser.User.DiscordUserAccounts.filter(x => {
            return x.DiscordAccountID === props.accountData.DiscordAccountID
        })[0]

        const tmpEmail = (match && match.DiscordUserInfo && match.DiscordUserInfo.BillingEmail)
        
        if (dUser && dUser.User && dUser.User.DiscordUserID){
            setEmail(tmpEmail || dUser.User.Email || '')
            setPhone(dUser.User.Phone || '')
            setCarrier(dUser.User.CarrierID || '')
            setFirstName((match && match.DiscordUserInfo && match.DiscordUserInfo.First) || '')
            setLastName((match && match.DiscordUserInfo && match.DiscordUserInfo.Last) || '')
            
            //Load Carriers
            if (!carriers.length){
                loadCarriers()
            }
        }
    }


    const loadCarriers = () => {
        return getCarriersAPI()
            .then(handleErrors)
            .then(results => {
                if (results){
                    setCarriers(results)
                }
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
            })
    }

    const handleCarrierChange = (val) => {
        setCarrier(val)
    }

    const save = (e) => {
        e.preventDefault()
        if (!dUser || !dUser.User || !dUser.Token || !dUser.User.DiscordUserID){
            return
        }
        setLoading(true)
        const data = {
            First: firstName,
            Last: lastName,
            Email: email,
            Phone: phone,
            Carrier: !carrier || carrier === 'UK' ? null : Number(carrier),
            AllowNotifications: allowTxt,
            DiscordAccountID: props.accountData.DiscordAccountID
        }
        setUserInfoAPI(dUser.User.DiscordUserID, dUser.Token.access_token, data)
            .then(handleErrors)
            .then(result => {
                if (result === 'Verify'){
                    setVerify(true)
                }
                else if (result === 'NoVerify')
                {
                    notify('Success', 'Information Updated')
                    setIsDone(true)
                }
                else if (result === 'Wait'){
                    notify('Error', 'You must wait to receive another verification code')
                }
                else{
                    notify('Error', 'Error Updating Information')
                }
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
                setLoading(false)
            })
    }

    const verifyCode = (e) => {
        e.preventDefault();
        if (!verificationCode){
            return
        }
        if (!dUser || !dUser.User || !dUser.Token || !dUser.User.DiscordUserID){
            return
        }
        setLoading(true)
        verifyCodeAPI(dUser.User.DiscordUserID, dUser.Token.access_token, verificationCode)
            .then(handleErrors)
            .then(results => {
                if (results){
                    notify('Success', 'Phone Number Verified!')
                    setIsDone(true)
                }
                else{
                    notify('Error', 'Incorrect Code')
                    setVerificationCode('')
                }
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
                setLoading(false)
            })
    }

    const resendCode = () => {
        if (!dUser || !dUser.User || !dUser.Token || !dUser.User.DiscordUserID || !props.accountData || !props.accountData.DiscordAccountID){
            return
        }
        setLoading(true)
        resendCodeAPI(dUser.User.DiscordUserID, dUser.Token.access_token, props.accountData.DiscordAccountID)
            .then(handleErrors)
            .then(results => {
                notify('Verification', results.toString())
            })
            .catch(error => error)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
                setLoading(false)
            })
    }

    return (
        <Card bg="dark">
        <Card.Header style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <b>{props.accountData && props.accountData.ManagerName}</b>
            <Nav>
                <Nav.Item style={{display: 'flex', marginRight: '20px', alignItems: 'center', color: 'white'}}>
                    {dUser.User && dUser.User.Name}
                </Nav.Item>
            </Nav>
        </Card.Header>
        {isDone && props.accountData && (props.accountData.DiscordAccountID === 3) && (
            <Card.Body style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <h2 style={{marginBottom: '30px'}}>You Are All Set!</h2>
                <h2>The Slap Stocks Family Looks Forward To Bringing You Lots of New Features In The Coming Weeks.</h2>
                <h2 style={{marginBottom: '30px'}}>Keep Your Eye On The Announcements Channel.</h2>
                <h3>Thanks,</h3>
                <h3>Cyrus</h3>
                <h3><i>Founder</i></h3>
                <h3>Slap Stocks</h3>
                {back && <Button onClick={() => back()}>Back</Button>}
            </Card.Body>
        )}
        {isDone && props.accountData && (props.accountData.DiscordAccountID !== 3) && (
            <Card.Body style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <h2 style={{marginBottom: '30px'}}>You Are All Set!</h2>
                {back && <Button onClick={() => back()}>Back</Button>}
            </Card.Body>
        )}
        {loading && (
            <Card.Body style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress style={{width: '100px', height: '100px'}} />
            </Card.Body>
        )}
        {!isDone && !loading && verify && (
            <Card.Body>
                <Form style={{textAlign: isBig ? 'end' : 'left'}} onSubmit={verifyCode}>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2} lg>
                            Verification Code
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} />
                        </Col>
                        <Col sm={0} lg={4}></Col>
                    </Form.Group>
                    <Form.Group as={Row} style={{justifyContent: 'center'}}>
                        <Button type="submit" variant="success" style={{marginRight: '10px'}} disabled={!verificationCode}>Verify</Button>
                        <Button onClick={resendCode} style={{marginRight: '10px'}}>Resend Code</Button>
                        <Button variant="danger" onClick={() => setVerify(false)} style={{marginRight: '10px'}}>Back</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        )}
        {!isDone && !loading && !verify && (
            <Card.Body>
                <Form style={{textAlign: isBig ? 'end' : 'left'}} onSubmit={save}>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2} lg>
                            First Name
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </Col>
                        <Col sm={0} lg={4}></Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2} lg>
                            Last Name
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </Col>
                        <Col sm={0} lg={4}></Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2} lg>
                            Billing Email
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Col>
                        <Col sm={0} lg={4}></Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2} lg>
                        </Form.Label>
                        <Col>
                            <Form.Check type="switch" id="linkAllowSMS" label="Allow Text Notification" checked={allowTxt} onChange={(e) => setAllowTxt(e.target.checked)} />
                        </Col>
                        <Col sm={0} lg={4}></Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2} lg>
                            Phone
                        </Form.Label>
                        <Col sm={3}>
                            <InputGroup>
                                <Form.Control value={phone} onChange={(e) => setPhone(e.target.value)} />
                                {
                                    dUser.User && dUser.User.Phone
                                    ? (
                                        dUser.User.PhoneVerified
                                        ? (
                                            <InputGroup.Append>
                                                <GoodIcon style={{color: 'green'}} />
                                            </InputGroup.Append>
                                        )
                                        : (
                                            <InputGroup.Append>
                                                <Button onClick={() => setVerify(true)}>Verify</Button>
                                            </InputGroup.Append>
                                        )
                                    )
                                    : ''
                                }
                            </InputGroup>
                        </Col>
                        <Col sm={0} lg={4}></Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2} lg>
                            Carrier
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control as="select" value={carrier} onChange={(e) => handleCarrierChange(e.target.value)}>
                                <option value="">Select Your Carrier</option>
                                {carriers.map(x => {
                                    return <option value={`${x.CarrierID}`}>{x.Name}</option>
                                })}
                                <option value="UK">Not In This List</option>
                            </Form.Control>
                            {carrier === 'UK' && (
                                <div style={{textAlign: 'center'}}>
                                    You will be unable to receive text notifications. Please reach out to an admin to let us know which carrier is missing from this list. Thank you!
                                </div>
                            )}
                        </Col>
                        <Col sm={0} lg={4}></Col>
                    </Form.Group>
                    <Form.Group as={Row} style={{justifyContent: 'center'}}>
                        <Button type="submit" variant="success">Submit</Button>
                        {back && <Button onClick={() => back()} style={{marginLeft: '10px'}}>Back</Button>}
                    </Form.Group>
                </Form>
            </Card.Body>
        )}
        </Card>
    )
}