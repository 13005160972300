import React, { Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Navbar, Nav } from 'react-bootstrap'
import { signOutAction } from '../../actions/user'


export const LiteNav = () => {
    
    const user = useSelector(state => state.discordUser)
    const loggedIn = user && user.Token && user.Token.access_token

    const dispatch = useDispatch()

    return (
        <Navbar bg="dark" variant="dark" expand="lg" style={{marginBottom: '20px'}}>
            {/* <Navbar.Brand onClick={() => {window.location.reload()}}>Varbis.NET</Navbar.Brand> */}
            {loggedIn && (
                <Fragment>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                        </Nav>
                        <Nav>
                            <Nav.Item style={{display: 'flex', marginRight: '20px', alignItems: 'center', color: 'white'}}>
                                {user.User.Name}
                            </Nav.Item>
                            <Nav.Link onClick={() => {
                                dispatch(signOutAction())
                                setTimeout(() => {window.location.reload()}, 500)
                            }
                            }>Sign Out</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Fragment>
            )}            
        </Navbar>
    )
}