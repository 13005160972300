import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Card, ListGroup, Table, Form, FormControl, Button, Modal, OverlayTrigger, PopoverTitle, PopoverContent } from 'react-bootstrap'
import { makeStyles } from '@material-ui/styles'
import { handleErrors } from '../../../api/base'
import { refreshAPI } from '../../../api/user/auth'
import { validateSignIn, signOutAction } from '../../../actions/user'
import { getAccountInfo, getOrders, getStrikes, getExpirations, getQuote, previewOrderAPI, placeOrderAPI } from '../../../api/trading/ally'
import { formatNegative, zeroIfNull } from '../../../utility/numberFormat'
import OrderOptions from '@material-ui/icons/MenuOpenRounded'
import { Popover, FormGroup } from '@material-ui/core'
import { setSymbolAction, setStockToggleAction } from '../../../actions/ally'

export const Holdings = ({holdings, ...props}) => {


    const dispatch = useDispatch()
    const setSymbol = (x) => {dispatch(setSymbolAction(x))}
    const setStockToggle = (x) => {dispatch(setStockToggleAction(x))}

    if (!holdings){
        return ''
    }

    const { getExps, getStks, quantity, setQuantity,
        expiration, setExpiration, strike, setStrike, 
          side, setSide, setDDLDefaults, getQte} = props
    

    const updateTrade = (order) => {
        if (!order){
            return;
        }
        console.log(order)
        
        setSymbol(order.Symbol)
        setStockToggle(order.SecurityType === 'OPT')
        setQuantity(order.Qty)
        if (order.SecurityType === 'OPT'){
            getExps(order.Symbol)
                .then(() => {
                    setExpiration(order.Expiration)
                })
            getQte(order.Symbol)
                .then(() => {
                    getStks(order.Symbol)
                        .then(() => {
                            setTimeout(() => {setStrike(order.Strike)}, 600)
                        })
                })
            setSide(`${order.OrderSide}|C`)
        }
        else{
            setSide(order.OrderSide)
        }
    }

    return (
        <Card bg="dark">
            <Card.Header style={{color: 'white'}}><b>Holdings</b></Card.Header>
            <Table responsive striped bordered hover size="sm" variant="dark">
                <thead>
                    <tr>
                        <th>Description</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Market</th>
                        <th>Basis</th>
                        <th>Purchase Price</th>
                        <th>G/L</th>
                        <th>G/L %</th>
                    </tr>
                </thead>
                <tbody>
                    {holdings.Holdings.map((x, i) => {
                        const color = x.GL > 0 ? '#0cff0c' : x.GL < 0 ? '#ff2222' : 'White';
                        return (
                        <tr style={{color: color}} onClick={() => {updateTrade(x.ClosingOrder)}}>
                            <td>{x.Desc}</td>
                            <td>${x.Price}</td>
                            <td>{x.Qty}</td>
                            <td>${x.Market}</td>
                            <td>${x.Basis}</td>
                            <td>${x.Purchase}</td>
                            <td>${formatNegative(x.GL)}</td>
                            <td>{formatNegative(x.GLP)}%</td>
                        </tr>
                        )}
                    )}
                </tbody>
            </Table>
        </Card>
    )
}
