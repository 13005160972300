import {baseURL} from '../base';

const controller = `${baseURL}/auth/v1`

export async function signInAPI(email, password){
    const response = await fetch(`${controller}/login`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
            Email: email,
            Password: password
        })
    });
    return response;
}

export async function refreshAPI(refreshToken){
    const response = await fetch(`${controller}/refresh`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Refresh': refreshToken
        }
    });
    return response;
}

export async function checkEmailAPI(email){
    const response = await fetch(`${controller}/emailexists`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(email)
    });
    return response;
}


export async function signUpAPI(email, password){
    const response = await fetch(`${controller}/register`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
            Email: email,
            Password: password
        })
    });
    return response;
}

export async function forgotPasswordAPI(email){
    const response = await fetch(`${controller}/forgotpassword`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(email)
    });
    return response;
}

export async function confirmForgotAPI(request){
    const response = await fetch(`${controller}/confirmforgot`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(request)
    });
    return response;
}